import React from 'react';
import {
	isArray,
	isObject,
	includes,
	size,
	dropRight
} from 'lodash';

import {
	DateRangeFilterMenu,
	KeyNameFilterMenu,
	SimpleFilterMenu
} from '../../core/@components/filter/FilterMenu';
import { CustomCell, CustomTooltip } from './Templates/cells';
import { getFilterName, isDropdown } from '../../core/@components/filter/utilities';
import MultiCheckMenu from '../../core/@components/filter/MultiCheckMenu';
import { GridColumnMenuWrapper } from './Templates/CustomFilterWrapper';
import { hasValue, valueOrOption } from '../../core/common/GeneralUtilities';
import { isEnabledElement } from '../../permissions/PermissionsGate';

export const initialPage = { skip: 0, take: 15 };
export const paginationInitials = (pageSizes) => ({ skip: 0, take: valueOrOption(pageSizes?.[0], 15) });
export const initialFilter = (definedFilters = {}) => { return { filters: { ...definedFilters } }; };

const inUse = (filters, field, prefix) => {
	const filterName = getFilterName(field, prefix);
	return hasValue(filters?.[filterName]);
};

export const isColumnActive = (field, filter, filterType, fieldPrefix) => {
	const used = filter?.filters ?? filter;
	const type = valueOrOption(filterType, "").toLowerCase();
	const colAux = valueOrOption(fieldPrefix, field);
	const filter_func = {
		"checkbox": () => (size(used?.[getFilterName(field, fieldPrefix)]) > 0),
		"keyname": () => (inUse(used, "key", fieldPrefix) || inUse(used, "name", fieldPrefix)),
		"keynamerfc": () => (inUse(used, "_key", colAux) || inUse(used, "_name", colAux) || inUse(used, "_rfc", colAux)),
		"daterange": () => (inUse(used, "_start", field) || inUse(used, "_end", field) || inUse(used, "_switch", field)),
	}[type] ?? (() => inUse(used, field, fieldPrefix));

	return filter_func();
};

/**
 * Este hook nos sirve para generar y manejar las propiedades de las columnas de las tablas del sistema con filtros custumizados
 *
 * @param {string} field - La columna a la que hace referencia el filtro
 * @param {object} filter - La propiedad donde se almacenan los filtros en el módulo
 * @param {string || array} filterType - El tipo de filtro que se quiere usar
 * @param {[lista,valor,label]?} dataProps - Si el filtro es un dorpdown se le deben pasar datos extras
 * * Importante se debe pasar en el formato [lista,valor,label]
 * * * Lista -> Es el conjunto de datos de que apareceran en el dropdown o los checks
 * * * valor -> Indica cual de los valores del objeto de la lista sera el valor de la etiqueta que se maneje en el filtro
 * * * label -> indica cual de los valores del objeto de la lista sera la que se vea en el elemento
 * @return {ColumnProps}
*/
export const columnProps = (field, filter, filterType, dataProps) => {

	if (filter === "none") {
		return {
			field: field,
			cell: CustomCell,
			headerCell: props => <CustomHeader {...props} filterable={false} />,
		};
	}

	let fieldPrefix = "";

	if (isArray(filterType)) {
		fieldPrefix = `${filterType[1]}|`;
		filterType = filterType[0];
	}
	const isArFilter = isDropdown(filterType) || filterType === "checkbox";
	const validity = dataProps ? dataProps[3] : {};

	const extraProps = {
		filterType: filterType,
		dataProps: isArFilter ? dataProps : [],
		inputProps: isArFilter ? validity : dataProps,
		fieldPrefix: fieldPrefix
	};

	return {
		field: field,
		cell: CustomCell,
		headerCell: props => <CustomHeader {...props} filterable={true} />,
		columnMenu: props => {
			const fType = valueOrOption(filterType, "").toLowerCase();
			switch (fType) {
				case "keyname":
				case "keynamerfc":
					return <KeyNameFilterMenu {...props} {...extraProps} rfcField={fType === "keynamerfc"} />;
				case "daterange":
					return <DateRangeFilterMenu {...props} {...extraProps} />;
				case "checkbox":
					return <MultiCheckMenu {...props} {...extraProps} />;
				default:
					return <SimpleFilterMenu {...props} {...extraProps} />;
			}
		},
		headerClassName: isColumnActive(field, filter, filterType, fieldPrefix) ? "activeFilter" : "customFilter",
	};
};

export const CustomHeader = ({
	title,
	columnMenuWrapperProps,
	filterable = true,
}) => {
	return (
		<span className="k-cell-inner" >
			<CustomTooltip title={title ?? ""} >
				<span className="k-link !k-cursor-default">
					<span className="k-column-title">{title}</span>
				</span>
			</CustomTooltip>
			{filterable && <GridColumnMenuWrapper {...columnMenuWrapperProps} />}
		</span>
	);
};

/**
 *
 * @param {number} total - Es el total de registros de la tabla
 * @returns {pageableProps}
 */
export const pageable = (total, buttonCount, customFirstCount, customPageSizes) => {

	const firstCount = customFirstCount ?? 15;
	const counter = valueOrOption(customPageSizes, [firstCount, 20, 25, 50]);

	if (total <= 0) {
		return undefined;
	}

	if (total <= firstCount) {
		return { pageSizes: undefined };
	}

	return {
		buttonCount,
		pageSizes: total >= 500 || hasValue(customPageSizes) ? counter : dropRight(counter, 1),
	};
};

export const selectedItem = (data, selected, matchBy) => {
	return (data ?? []).map((item, index) => ({
		...item,
		rowIndex: index,
		selected: isMatch(item, selected, valueOrOption(matchBy, "id"))
	}));
};

export const isMatch = (item, selected, field) => {
	const value = item?.[field];

	if (isArray(selected)) {
		return includes(selected, value) || includes(selected, value.toString());
	}

	if (isObject(selected)) {
		return selected[field] === item[field];
	}

	return getValue(value) === getValue(selected);
};

const getValue = (value) => {
	if (hasValue(value)) {
		return value.toString();
	}

	return "";
};

export const doubleClickForm = (dataItem, handleEdit, hasPerm = true) => {

	if (!isEnabledElement(['write']) || !hasPerm) {
		return;
	}
	handleEdit(dataItem);
};