import React from "react";
import { GENERIC_DOMAIN } from "../../../utils/utilities";
import { size } from "lodash";
import SwitchMui from "../../../../../../App/components/Booleans/SwitchMui";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
import { useDialogContext } from "../../../hooks/DialogController";
import { CustomTooltip } from "../../../../../../App/components/Templates/cells";

function Tenants() {

    const {
        setValue,
        tenants,
        current,
    } = useDialogContext();

    return (
        <div className="tenants-main-container">
            {
                size(tenants) ?
                    <div className="tenants-wrapper">
                        {
                            tenants.map((el) => (
                                <div key={`tenant-item-${el.slug}-${el.id}`}>
                                    <CustomItem
                                        item={el}
                                        setValue={setValue}
                                        current={current}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    :
                    <div className="no-tenants-message">
                        <span className="message">
                            {'Sin elementos'}
                        </span>
                    </div>
            }
        </div>
    );
}

export default Tenants;

export const GridWrapper = ({ children }) => {
    GridWrapper.propTypes = {
        children: PropTypes.any,
    };

    return (
        <div className="tenants-main-container">
            <div className="tenants-wrapper">
                {children}
            </div>
        </div>
    );
};

const CustomItem = ({
    item,
    current,
    setValue,
}) => {

    const {
        control,
        canWrite
    } = useDialogContext();

    const activeTenants = useWatch({ control, name: 'tenants' });

    const checked = !!activeTenants.find(el => el.id === item.id);
    const disabled = item.id === current?.id || !canWrite;

    const handleChange = (e) => {
        let newData = [...activeTenants];
        if (e.value) {
            newData.push({ id: item?.id, name: item?.name });
        } else {
            newData = newData.filter(el => el.id !== item.id);
        }
        setValue('tenants', newData);
    }

    return (
        <button
            className={`tenant-item ${disabled ? 'disabled' : ''}`}
            onClick={() => !disabled ? handleChange({ value: !checked }) : null}
        >
            <div className="switch-wrapper">
                <SwitchMui
                    checked={checked}
                    onChange={handleChange}
                    disabled={disabled}
                />
            </div>
            <div className="info-container">
                <CustomTooltip title={item.name}>
                    <span className="title">
                        {item.name}
                    </span>
                </CustomTooltip>
                <CustomTooltip title={`${item.country.iso2_code} - ${item.slug}${GENERIC_DOMAIN}`} >
                    <span className="description">
                        {item.country.iso2_code} - {`${item.slug}${GENERIC_DOMAIN}`}
                    </span>
                </CustomTooltip>
            </div>
        </button>
    );
};

CustomItem.propTypes = {
    item: PropTypes.object,
    current: PropTypes.object,
    setValue: PropTypes.func,
};