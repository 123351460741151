import PropTypes from "prop-types";
import React from 'react';
import { hasValue } from '../../../core/common/GeneralUtilities';

const SubProcessMessage = ({
	subprocess_message,
	subprocess_percentage,
}) => {


	if (!hasValue(subprocess_message) || !hasValue(subprocess_percentage)) {
		return null;
	}

	return (
		<div className='sub-process-body'>
			<span className='sub-process-label'>
				{subprocess_message}
			</span>
			<span className='sub-percentage-label'>
				{`${subprocess_percentage}%`}
			</span>
			<div className="sub-percentage">
				<div className='sub-percentage-bar' style={{ width: `${subprocess_percentage}%` }}></div>
			</div>
		</div>
	);
};

SubProcessMessage.propTypes = {
	subprocess_message: PropTypes.any,
	subprocess_percentage: PropTypes.any
};

export default SubProcessMessage;