import React, {
	useEffect,
	useState,
	useRef,
} from 'react';
import { size as _size, isEqual, isFunction, isString } from "lodash";
import { uid } from 'uid';
import { payrollPeriodProps } from "./propTypes";

/* components */
import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";

/* utilities */
import withValueField from "../../Templates/withValueField";
import { valueOrOption, hasValue } from '../../../../core/common/GeneralUtilities';
import { objectValue } from '../../../../core/@components/form/utilities';
import { kendoSizeClasses } from '../../sizesUtilities';
import { payrollPeriodDataConstructor } from './searchUtilities';

/* templates */
import { KendoLabelContainer } from '../../Templates/SideLabelContainer';
import { NoPeriodsDataRender, payrollPeriodItemRender, payrollPeriodItemvalueRender } from './renderTemplates';

const DropDownListWithValueField = withValueField(DropDownList);

/**
 * Hook que nos obtiene una lista de los periodos de nómina de acuerdo a los filtros usados
 * @param {payrollPeriodProps} props
 * @returns {JSX.Componet}
*/
const PayrollPeriod = ({
	id,
	value,
	label,
	current_year,
	current_month,
	payroll_type,
	computed_type,
	forceReload = false,
	onChange,
	setLoading,
	periodValue,
	dataPayrollType = [],
	isFinalPeriod = false,
	sideLabel = false,
	objValueHandler = {},
	cutomNoDataMsg = "",
	year_status,
	lockWindow = true,
	...others
}: payrollPeriodProps) => {

	const { size, className } = kendoSizeClasses(others);
	const fieldId = id || "period_" + uid();
	const filter = useRef(null);
	const periods = useRef([]);
	const noDataMsg = useRef(null);
	const [data, setData] = useState([]);
	const [load, setLoad] = useState(false);
	const force = useRef(false);
	const lastSearch = useRef({
		computed_type: null,
		payroll_type: null,
		current_year: null,
		current_month: null
	});

	const newSearchValues = { computed_type, payroll_type, current_year, current_month };

	const initialize = async () => {
		if (!payroll_type || !current_year) {
			return;
		}


		if (
			isEqual(newSearchValues, lastSearch.current)
			&& !forceReload
			&& !force.current
		) {
			force.current = false;
			return;
		}

		//Se agrega opcion 99 para indicar todos los tipos de nomina se pone en null para omitir el filtros¡
		performLoad(true);
		const {
			data: searchedData,
			current,
			message
		} = await payrollPeriodDataConstructor({
			...others,
			value,
			computed_type,
			payroll_type: payroll_type === 99 ? null : payroll_type,
			current_year,
			current_month,
			year_status: year_status ? year_status: null,
			lockWindow: lockWindow
		});

		noDataMsg.current = message;

		periods.current = searchedData;
		lastSearch.current = newSearchValues;
		setData(searchedData);
		filter.current = null;

		if (!_size(searchedData)) {
			handleChange({ value: null });
			performLoad(false);
			return;
		}
		//si es 99 se busca el predeterminado y se pone el periodo actual
		//const finded = find(searchedData, ["id", value]);
		//finded se omite, ya que no cambiaba el valor al seleccionar todos y despues el tipo de nomina por defecto
		if (payroll_type === 99) {
			const is_predetermined = dataPayrollType.find(f => f.is_predetermined);
			const new_value = searchedData.find(f =>
				f.id === (value?.id ?? value) || (f.list && f.payroll_type === is_predetermined?.id)
			)?.id || searchedData[0].id;
			handleChange({ value: new_value });
		} else if ((current) || forceReload) {
			const newValue = forceReload ? value : current;
			handleChange({ value: newValue });
		} else if (_size(searchedData) && searchedData[0]?.id !== value) {
			handleChange({ value: searchedData[0]?.id });
		}
		performLoad(false);
	};

	const performLoad = value => {
		setLoad(value);
		if (isFunction(setLoading)) {
			setLoading(value);
		}
	};

	useEffect(() => {
		initialize();
		//eslint-disable-next-line
	}, [current_year, payroll_type, computed_type, current_month, forceReload]);

	useEffect(() => {
		if (
			!isFunction(objValueHandler?.getter) ||
			!isFunction(objValueHandler?.setter) ||
			!isString(objValueHandler?.objName) ||
			!hasValue(value) ||
			!hasValue(data)
		) {
			return;
		}

		const { objName, getter, setter, } = objValueHandler;

		const currentObjValue = getter(objName);
		if (!hasValue(currentObjValue)) {
			const objValue = data.find(item => item.id === value) ?? null;
			if (objValue?.id !== currentObjValue?.id) {
				setter(objName, objValue);
			}
		}
	});

	const filterChange = (event) => {
		filter.current = event.filter?.value;
		setData(filterBy(
			periods.current,
			{ ...event.filter, field: "forSearch" }
		));
	};

	const handleChange = (e) => {
		if (typeof onChange === "function") {
			e = isFinalPeriod ? { value: periodValue } : e;
			let returned = objectValue(e, e.value);
			returned.selected = e.value ? periods.current.find(item => item.id === e.value) : null;
			onChange(returned);
		}
	};

	const onReload = () => {
		force.current = true;
		initialize();
	};

	const noDataRender = element => NoPeriodsDataRender(
		element,
		valueOrOption(noDataMsg.current, cutomNoDataMsg),
		newSearchValues,
		onReload
	);

	const valueRender = (li, item) => payrollPeriodItemvalueRender(li, item, onReload);

	return (
		<KendoLabelContainer
			label={label}
			editorId={fieldId}
			editorValue={!!value}
			className={"custom-floating-label"}
			sideLabel={sideLabel}
		>
			<DropDownListWithValueField
				popupSettings={{
					popupClass: "customPopup"
				}}
				{...others}
				id={fieldId}
				size={size}
				loading={load}
				valueField={"id"}
				textField={"forSearch"}
				className={`${className ?? ""} asMui`}
				data={data}
				value={value}
				onChange={handleChange}
				listNoDataRender={noDataRender}
				itemRender={payrollPeriodItemRender}
				valueRender={valueRender}
				filterable={_size(periods.current) > 5}
				filter={filter.current}
				onFilterChange={filterChange}
			/>
		</KendoLabelContainer>
	);
};

export default PayrollPeriod;