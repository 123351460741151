import React, {
	useState,
	useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
//--project imports
import { hideConfirmNotification } from '../../../store/actions';
import Dialog from '@mui/material/Dialog';
import { useTranslation } from "react-i18next";
import { StatusSkeleton, Transition } from "./DialogSkeleton";

const ConfirmNotification = () => {

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [hasDelay, setHasDelay] = useState(false);

	const getSeconds = (seconds) => seconds * 1000;
	const {
		show,
		title,
		message,
		description,
		type, /* warning, info */
		onCancel,
		onConfirm,
		maxWidth,
		hasTime = false,
		...others
	} = useSelector(state => state.notification.confirmNotification);

	useEffect(() => {
		if (show && hasTime) {
			setTimeout(() => {
				setHasDelay(true);
			}, getSeconds(5));
		}
	}, [show]);


	useEffect(() => {
		if (hasDelay) {
			close();
			setHasDelay(false);
		}
	}, [hasDelay]);

	const closeNotification = () => dispatch(hideConfirmNotification());

	const close = () => {
		closeNotification();
		if (typeof onCancel === "function") {
			onCancel();
		}
	};

	/**
	 *
	 * @param {Event} e
	 * @returns {function(): void}
	 */
	const confirm = (e) => {
		closeNotification();
		/* this is for multi confirm messages */
		const timer = setTimeout(() => {
			if (typeof onConfirm === "function") {
				onConfirm(e);
			}
			setHasDelay(false);
		}, getSeconds(.3));
		return () => clearTimeout(timer);
	};

	return (
		<Dialog
			open={show}
			TransitionComponent={Transition}
			className={`myDialog ${type === "info" ? "infoDialog" : "warningDialog"}`}
			maxWidth={maxWidth ?? "lg"}
		>
			<StatusSkeleton
				lottieIcon={type === "info" ? "info-icon" : "warning-icon"}
				message={message || message === "" || t("label-draft-question")}
				description={description || ""}
				closeNotification={closeNotification}
				close={close}
				confirm={confirm}
				hasConfirm={true}
				title={title}
				{...others}
			/>
		</Dialog>
	);
};

export default ConfirmNotification;