import React, { useEffect, useState } from "react";
import {
    basePayroll,
    payrollCalculateChoices,
    payrollChoices,
} from "../../../utils/utilities";
import { useWatch } from "react-hook-form";
import KendoCheckbox from "../../../../../../App/components/Booleans/KendoCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleInfo,
} from "@fortawesome/pro-light-svg-icons";
import { CustomTooltip } from "../../../../../../App/components/Templates/cells";
import FormError from "../../../../../@components/form/Error";
import PropTypes from "prop-types";
import { size } from "lodash";
import { uid } from "uid";
import { useDialogContext } from "../../../hooks/DialogController";

function Payroll() {

    const {
        control,
        setValue,
    } = useDialogContext();

    const payrollChoice = useWatch({ control, name: `payroll_permissions` });
    const [choices, setChoices] = useState(payrollChoice ?? []);

    useEffect(() => {
        setValue('payroll_permissions', choices, { shouldValidate: true });
    }, [choices])

    const handleChangeConfig = (e, item, element) => {
        let data = [...choices];
        const foundItem = data.find(el => el.payroll_access === item.id);
        if (e.value && !foundItem) {
            data.push(basePayroll(item.id, [element.value]));
        } else {
            data = data.map(el => {
                if (el.payroll_access === item.id) {
                    let calculate_permissions = el.calculate_permissions;
                    if (calculate_permissions.includes(element.value)) {
                        calculate_permissions = calculate_permissions.filter(li => li !== element.value);
                    } else {
                        calculate_permissions.push(element.value);
                    }
                    el.calculate_permissions = calculate_permissions;
                }
                return size(el.calculate_permissions) > 0 ? el : null;
            }).filter(el => el !== null);
        }
        setChoices(data);
        return e;
    }

    return (
        <div className="payroll-items-container">
            <div className="sub-container">
                {payrollChoices.map((el, key) => (
                    <CustomItem
                        key={el.id}
                        item={el}
                        itemIndex={key}
                        handleChangeConfig={handleChangeConfig}
                        choices={choices}
                    />
                ))}
            </div>
            <FormError control={control} name="payroll_permissions" />
        </div>
    );
}

export default Payroll;

const CustomItem = ({
    item,
    handleChangeConfig,
    itemIndex,
    choices,
}) => {

    const {
        control,
        canWrite,
    } = useDialogContext();

    function validateConfigCheck(item, element) {
        const foundItem = choices.find(el => el.payroll_access === item.id);
        return foundItem?.calculate_permissions.includes(element.value);
    }

    const handleConfigCheck = (e, item, element) => {
        handleChangeConfig(e, item, element);
    }

    const validateExistingItem = (item) => {
        return choices.find(el => el.payroll_access === item.id);
    }

    return (
        <div className="payroll-item-container">
            <div
                className={`payroll-item ${validateExistingItem(item) ? 'existing' : 'not-existing'}`}
            >
                <div className="icon">
                    <FontAwesomeIcon icon={item.icon} size='xl' />
                </div>
                <div className="info-container">
                    <span className="name">
                        {item.name}
                    </span>
                    <span>
                        {item.description}
                    </span>
                </div>
            </div>
            <div
                className="calc-type"
            >
                <CustomTooltip
                    title={'Tipos de cálculo de nómina'}
                    placement={'top-end'}
                >
                    <div className="info-icon">
                        <FontAwesomeIcon icon={faCircleInfo} size='lg' />
                    </div>
                </CustomTooltip>
                {
                    payrollCalculateChoices.map((el) => (
                        <div key={uid()} className="calc-item">
                            <div>
                                <KendoCheckbox
                                    formAlign={false}
                                    checked={validateConfigCheck(item, el)}
                                    onChange={(e) => handleConfigCheck(e, item, el)}
                                    disabled={!canWrite}
                                />
                            </div>
                            <span>{el.name}</span>
                        </div>
                    ))
                }
                <div className="error-label">
                    <FormError name={`payroll_permissions[${itemIndex}].calculate_permissions`} control={control} />
                </div>
            </div>
        </div>
    )
};

CustomItem.propTypes = {
    item: PropTypes.object,
    handleChangeConfig: PropTypes.func,
    itemIndex: PropTypes.number,
    choices: PropTypes.array,
};