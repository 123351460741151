import React, { useEffect, useState } from "react";
import Card from "../Card";
import { useRequestLoad } from "../../../../../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";
import { mopersHistoryAPI } from "../../../../../../../services/mopers";
import Grid from "@mui/material/Grid2";
import { Loader } from "../../../../Shared";
import { first } from "lodash";
import "../Vacations/_content.scss";

const contentClass = "grid-horizontal-center primary-blue-color";
const commonParentProps = { container: true, direction: "column", spacing: 1 };

function Absenteeisms({ request }) {
    const worker = request?.exactMoper?.worker?.id ?? request?.exactMoper?.worker;
    const createdAt = first(request?.exactMoper?.created_at?.split('T'));

    const [fetch, loading] = useRequestLoad(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        getAbsensHistory();
    }, [])

    const getAbsensHistory = () => {
        fetch({
            api: mopersHistoryAPI.absenteeisms({
                worker,
                initial_date: createdAt
            }),
            callback: setData
        });
    }

    return (
        <Card style={{ padding: '14px' }}>
            <Grid
                container
                direction="row"
                spacing={2}
                style={{
                    minWidth: '373px',
                    minHeight: '79px'
                }}
            >
                {!loading ?
                    <>
                        <Grid {...commonParentProps}>
                            <Grid style={{ flex: 1 }} />
                            <Grid>
                                {'Ultimo año'}
                            </Grid>
                            <Grid>
                                {'Ultimo mes'}
                            </Grid>
                        </Grid>
                        <Grid {...commonParentProps}>
                            <Grid className="grid-horizontal-center">
                                {'Con goce'}
                            </Grid>
                            <Grid className={contentClass}>
                                {data?.pcs_year}
                            </Grid>
                            <Grid className={contentClass}>
                                {data?.pcs_month}
                            </Grid>
                        </Grid>
                        <Grid {...commonParentProps}>
                            <Grid className="grid-horizontal-center">
                                {'Sin goce'}
                            </Grid>
                            <Grid className={contentClass}>
                                {data?.pgs_year}
                            </Grid>
                            <Grid className={contentClass}>
                                {data?.pgs_month}
                            </Grid>
                        </Grid>
                        <Grid {...commonParentProps}>
                            <Grid className="grid-horizontal-center">
                                {'Faltas'}
                            </Grid>
                            <Grid className={contentClass}>
                                {data?.f_year}
                            </Grid>
                            <Grid className={contentClass}>
                                {data?.f_month}
                            </Grid>
                        </Grid>
                        <Grid {...commonParentProps}>
                            <Grid className="grid-horizontal-center">
                                {'Suspensiones'}
                            </Grid>
                            <Grid className={contentClass}>
                                {data?.s_year}
                            </Grid>
                            <Grid className={contentClass}>
                                {data?.s_month}
                            </Grid>
                        </Grid>
                    </>
                    :
                    <Loader />}
            </Grid>
        </Card>
    );
};

export default Absenteeisms;