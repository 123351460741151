import React, { useEffect, useState } from "react";
import {
    validateShowItem,
    validateShowMessage,
    baseRole,
    transition,
} from "../../../../utils/utilities";
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Button from "../../../../../../styled/Button";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { size } from "lodash";
import { useWatch } from "react-hook-form";
import RoleItem from "./Item";
import Permission from "../../../../../../@components/form/Permission/Permission";
import {
    faCheck,
} from "@fortawesome/pro-light-svg-icons";
import {
    faLayerGroup as faLayerGroupAlt,
    faCube,
} from "@fortawesome/pro-solid-svg-icons";
import BranchesPerms from "./Branches";
import TransitionComponent from "../Transition";
import PropTypes from "prop-types";
import { scaleOpacityAnimation } from "../../../../../../@components/form/Permission/Utils";
import { useDialogContext } from "../../../../hooks/DialogController";

function RolesView() {

    const {
        isEdit,
        setRoleIndex,
        setCurrentInfo,
        setCurrentGroup,
        setHeightIncreased,
    } = useDialogContext();

    const [element, setElement] = useState(null);

    useEffect(() => {
        setHeightIncreased(true);
        return () => {
            setRoleIndex(null);
            setCurrentGroup(null);
            setCurrentInfo(null);
            setHeightIncreased(false)
        }
    }, [])

    const handleBack = () => {
        setElement(null);
        setCurrentInfo(null);
        setCurrentGroup(null);
    }

    return (
        <AnimatePresence mode='wait'>
            {
                !element ?
                    <Grid
                        container
                        spacing={0}
                        component={motion.div}
                        key="main-container"
                        style={{
                            position: 'relative',
                            minHeight: '390px',
                            height: '100%',
                        }}
                        {...scaleOpacityAnimation}
                    >
                        <GroupList setElement={setElement} />
                        <ModulePermissions />
                    </Grid>
                    :
                    <motion.div
                        key={`item-${element.name}`}
                        {...scaleOpacityAnimation}
                        className={`items-edit-container ${isEdit ? 'custom' : ''}`}
                    >
                        <TransitionComponent
                            onReturn={handleBack}
                            CustomItem={element.el}
                            customViewName={element.name}
                        />
                    </motion.div>
            }
        </AnimatePresence>
    );
}

export default RolesView;

const GroupList = ({ setElement }) => {

    GroupList.propTypes = {
        setElement: PropTypes.func,
    };

    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editing, setEditing] = useState(false);
    const [showGroups, setShowGroups] = useState(false);

    const {
        control,
        setValue,
        rolesData,
        setCurrentInfo,
        setCurrentGroup,
        validateRoleAssign,
        roleIndex,
        setRoleIndex,
        errors,
    } = useDialogContext();

    const groups = useWatch({ control, name: `groups_perms` });

    useEffect(() => {
        if (!groups) return;
        const unsaved = groups.some(el => !el.saved);
        const selectedRole = groups[roleIndex];
        const index = groups.findIndex(el => el?.group?.id === selectedRole?.group?.id);
        setRoleIndex(index >= 0 ? index : null);
        setEditing(unsaved);
        setShowGroups(size(groups) > 0);
    }, [groups])

    useEffect(() => {
        if (!rolesData) return;
        const filteredData = rolesData.filter(el => {
            return !groups.some(li => li?.group?.id === el.id);
        });
        setDataSource(filteredData);
    }, [rolesData, groups])

    const handleRolesClick = () => {
        if (!validateRoleAssign()) return;
        setValue(`role_select`, null);
        setRoleIndex(null);
        setCurrentGroup(null);
        setEditing(true);
        setValue('groups_perms', [...groups, baseRole]);
    }

    const handleConfigAccess = (item, itemIndex) => {
        const onlyBranch = item?.group?.config?.only_branch;
        const info = onlyBranch ? t('branch') : `${t('branch')} - R. Social / Reg. Patronal`;
        const data = {
            info: `${info}`,
            class: 'structure-access',
            icon: faLayerGroupAlt,
        }
        const component = <BranchesPerms roleIndex={itemIndex} item={item} />;
        setRoleIndex(null);
        setCurrentInfo(data);
        setCurrentGroup(groups[itemIndex]);
        setElement({ name: `Accesos ${item?.group?.name}`, el: component });
    }

    return (
        <Grid
            item
            component={motion.div}
            className="roles-selected-container"
            key="roles-selected-container"
            initial={false}
            animate={{
                width: showGroups ? '40%' : '100%',
                maxWidth: showGroups ? '40%' : '100%'
            }}
        >
            <div className="roles-container">
                <div className="button-container">
                    <Button
                        design="contained"
                        onClick={handleRolesClick}
                        disabled={editing}
                        className={`${errors?.groups_perms && !editing && !size(groups) ? 'error' : ''}`}
                    >
                        {t('add-roles')}
                    </Button>
                </div>
                <AnimatePresence mode='wait' initial={false}>
                    {
                        size(groups) ?
                            <motion.div
                                className="role-items-container"
                                key="role-items-container"
                                {...scaleOpacityAnimation}
                            >
                                {
                                    groups.map((el, index) =>
                                        <RoleItem
                                            key={`role-perm-${index}`}/*NOSONAR*/
                                            item={el}
                                            keyItem={index}
                                            errors={errors}
                                            dataInput={dataSource}
                                            setEditing={setEditing}
                                            roleIndex={roleIndex}
                                            setRoleIndex={setRoleIndex}
                                            handleConfigAccess={handleConfigAccess}
                                            setCurrentInfo={setCurrentInfo}
                                            setCurrentGroup={setCurrentGroup}
                                        />)
                                }
                            </motion.div>
                            :
                            <motion.div
                                className="no-roles-message-container"
                                key="no-selected-roles-message"
                                {...scaleOpacityAnimation}
                            >
                                {'Sin roles asignados'}
                            </motion.div>
                    }
                </AnimatePresence>
            </div>
        </Grid>
    );
};

const ModulePermissions = () => {
    const [showGroups, setShowGroups] = useState(false);

    const {
        open,
        control,
        setValue,
        getValues,
        roleIndex,
        canAssignRoles,
    } = useDialogContext();

    const groups = useWatch({ control, name: `groups_perms` });

    useEffect(() => {
        setShowGroups(size(groups) > 0);
    }, [groups])

    return (
        <AnimatePresence mode="wait">
            {showGroups ?
                <Grid
                    item
                    component={motion.div}
                    className="permissions-config-container"
                    key="permissions-config-container"
                    initial={{
                        width: '0%',
                    }}
                    animate={{
                        width: '60%',
                    }}
                    exit={{
                        width: '0%',
                    }}
                    transition={transition}
                >
                    <AnimatePresence mode='wait'>
                        {
                            validateShowItem(roleIndex, groups) ?
                                <motion.div
                                    className='tree-permissions-config'
                                    key={`permissions-tree-${roleIndex}`}
                                    {...scaleOpacityAnimation}
                                >
                                    <Permission
                                        open={open}
                                        control={control}
                                        setValue={setValue}
                                        getValues={getValues}
                                        animate={false}
                                        disabled={!canAssignRoles}
                                        name={`groups_perms[${roleIndex}].rolePerms`}
                                        dashboardName={`groups_perms[${roleIndex}].user_group_configs`}
                                        dashboardSettings
                                        maxHeight='100%'
                                    />
                                </motion.div>
                                :
                                <motion.div
                                    className="no-roles-message-container"
                                    key="role-messages-container"
                                    {...scaleOpacityAnimation}
                                >
                                    <AnimatePresence mode='wait'>
                                        {
                                            validateShowMessage(groups) ?
                                                <motion.div
                                                    className='message-container'
                                                    key="select-rol-message"
                                                    {...scaleOpacityAnimation}
                                                >
                                                    <div className="action-button">
                                                        <FontAwesomeIcon icon={faCube} size='2xl' />
                                                    </div>
                                                    {'Seleccione un rol a configurar'}
                                                </motion.div>
                                                :
                                                <motion.div
                                                    className='message-container'
                                                    key="add-rol-message"
                                                    {...scaleOpacityAnimation}
                                                >
                                                    <div className="action-button">
                                                        <FontAwesomeIcon icon={faCheck} size='2xl' />
                                                    </div>
                                                    {'Agregue un rol'}
                                                </motion.div>
                                        }
                                    </AnimatePresence>
                                </motion.div>
                        }
                    </AnimatePresence>
                </Grid>
                :
                null}
        </AnimatePresence>
    );
}