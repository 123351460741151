
import React, {
	Suspense,
	useMemo,
} from 'react';
import useAbrhilWindow from '../../contextsAndControllers/useWindow';
import PageSkeleton from '../PageSkeleton';
import { hasValue } from '../../../../common/GeneralUtilities';

const Window = ({ id }) => {
	const { open, isFocused } = useAbrhilWindow();
	const focusClass = isFocused ? "isFocused" : "";

	return (
		<>
			{open &&
				<div
					id={id}
					key={id}
					className={`module-window ${focusClass}`}
				>
					<div className='module-window-container'>
						<WindowContent />
					</div>
				</div>}
		</>
	);
};

export default Window;

export const WindowContent = () => {

	const {
		open,
		item: { element, path, extraProps },
	} = useAbrhilWindow();

	const WindowPage = useMemo(() => element);

	if (!open || !hasValue(WindowPage)) { return null; };

	return (
		<Suspense fallback={<PageSkeleton />}>
			<WindowPage {...extraProps} path={path} inWindow />
		</Suspense>
	);
};