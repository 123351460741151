import React, {lazy} from "react";
import i18next from 'i18next';
import {
	size,
	filter,
	isArray,
	each,
	isString,
	isEqual
} from 'lodash';
import { isAbrhil } from "../../common/auth";
import menuElements from "./tree";
import { iconsDictionary } from "./tree/icons";
import { store } from "../../../store";
import { hasValue, valueOrOption } from '../../common/GeneralUtilities';
import icons from '../../../assets/scss/custom-icons/icons';

const { t } = i18next;

const defaultWindow = {
	NoPermision: lazy(async () => import('../../../App/components/Pages/NoPermision')),
	UnderConstruction: lazy(async () => import('../../../App/components/Pages/UnderConstruction')),
	PageNotFound: lazy(async () => import('../../../App/components/Pages/PageNotFound')),
};

export const concidence = (title, search) => {
	search = replaceLetter(search);
	title = replaceLetter(title);

	return title.indexOf(search) !== -1;
};

/* funcion usada para la busqueda de elementos en el menu lateral de la aplicación */
export const filterChildrens = (children, search, title) => {

	if (!hasValue(search) || concidence(title, search)) {
		return children;
	}

	if (!isArray(children)) {
		return null;
	}

	return children.map(child => {
		child.filtereds = child.children;

		if (size(child.filtereds) > 0) {
			child.filtereds = filterChildrens(child.filtereds, search, child?.title);
		}
		const validation = (concidence(child.title, search) && !!child.path) || size(child.filtereds) > 0;

		return validation && child.showInMenu ? child : null;
	}).filter(item => item !== null);
};

export const replaceLetter = (word) => {
	return valueOrOption(word, "")
		.toString()
		.toLowerCase()
		// .replace(/ñ|Ñ/g,"n")
		.replace(/[áäªàâãå]/g, "a")
		.replace(/[éëèêęėē]/g, "e")
		.replace(/[íïìîįī]/g, "i")
		.replace(/[óöºòôõō]/g, "o")
		.replace(/[úüùûū]/g, "u");
};

export const getTabIcon = (item, tab) => {

	const { icon, icon_name, key, } = item ?? {};

	if (hasValue(icon)) {
		return icon;
	}

	let category = replaceLetter(tab);
	if (size(category.split(" ")) <= 1) {
		category = getItemCategory(category);
	}

	const item_icon = valueOrOption(icon, icon_name);
	const predefined = getPredefinedIcon(item_icon, key);

	return valueOrOption(predefined, {
		tools: 'config-icon',
		catalogs: 'catalog',
		settings: 'config-icon',
		reports: 'report',
		home: 'home',
	}[category]) ?? null;
};


const getItemCategory = (tab) => {
	if (!hasValue(tab)) {
		return "home";
	}

	if (tab.includes("catalog")) {
		return "catalogs";
	} else if (tab.includes("report")) {
		return "reports";
	} else if (tab.includes("config") || tab.includes("setti")) {
		return "settings";
	}

	return "tools";
};

export const getPredefinedIcon = (icon, key) => {
	if (!isString(icon)) {
		return valueOrOption(icon, null);
	}

	const lowIcon = icon.toLowerCase();
	const lowKey = key.toLowerCase();
	if (hasValue(icons[lowIcon])) {
		return icon;
	}
	if (hasValue(icons[lowKey])) {
		return key;
	}
	const abrStr = iconsDictionary;
	const predefined = valueOrOption(abrStr[lowIcon], abrStr[lowKey]);
	if (hasValue(predefined)) {
		return predefined;
	}
	return valueOrOption(icon, null);
};

/*
	Funcion que recorre cada una de las rutas dadas de alta en el sistema,
	compara los permisos dados y tambien las confiuraciones por paquete
	si alguno de estos no coincide regresa el elemento a visualizar sin permisos
*/
export const itemsPermisions = (allItems, configuration) => {

	const buildItem = (element) => {
		let item = { ...element };
		if (item?.sub_menu) {
			item.children = size(item.children) ? item.children : item.sub_menu;
			delete item.sub_menu;
		}

		item.title = valueOrOption(item.name, item.title);
		if (item?.path && item?.path !== 'no-path') {
			item.path = item.path.split("__").join("/");
			let pathSplit = item.path.split('/');
			item.key = `${pathSplit[0]}@${item.key}`;
			item.type = item.type ? item.type : 'item';
		} else if (item?.children) {
			item.id = item.key;
			item.icon = !icons[item?.icon ?? item.key]
				? iconsDictionary[item.key] : item?.icon ?? item.key;
			item.type = "collapse";
			if (item?.path === 'no-path' || !size(item?.perms)) {
				delete item.perms;
			}
		}

		const nativeItem = menuElements.find(menuItem => menuItem.id === item.key);

		if (nativeItem) {
			item = { ...item, ...nativeItem };
		}

		if (!hasValue(item?.id) && item.type === 'item') {
			item.id = valueOrOption(item?.key, item?.path);
		}

		if (!item?.path && item?.index && item?.id) {
			item.path = item.id;
		}

		if (item?.children) {
			item.children = item.children.map(el => buildItem(el));
			if (!size(item.children)) { delete item.children; };
		}
		return item;
	};


	const verifyPackage = (package_permissions) => {
		let item = configuration;
		if (!package_permissions.includes(".")) {
			return item?.[package_permissions]?.value ?? false;
		}

		each(package_permissions.split("."), sub_permission => {
			item = item?.[sub_permission];
		});

		return item?.value ?? false;
	};

	const packageValidation = ({ showInMenu, package_permissions }) => {
		if (!showInMenu || !package_permissions) { return true; }
		return !size(filter(package_permissions, permissions => !verifyPackage(permissions)));
	};

	const findPerm = (itemPerms, perm) => itemPerms?.find(element => element?.action?.toLowerCase()?.includes(perm));

	const getItemProps = (element) => {
		const item = { ...element };
		const itemPermissions = item?.perms;
		const readPermissions = findPerm(itemPermissions, 'read');

		if (size(itemPermissions)) {
			if (item.type === 'item' && item?.children) {
				item.children = item.children.map((child) => {
					if (child?.id) {
						child.key = child.id;
					}
					if (!size(child?.perms)) {
						child.perms = [...itemPermissions];
					}
					if (child?.children) {
						child.children = getItemProps(child);
					}
					return child;
				});
			}
			if (item?.sub_routes) {
				item.sub_routes = item.sub_routes.map((route) => {
					if (route?.id) {
						route.key = route.id;
						route.parentPath = item?.path;
					}
					if (!size(route?.perms)) {
						route.perms = [...itemPermissions];
					}
					if (route?.sub_routes) {
						route.sub_routes = getItemProps(route);
					}
					return route;
				});
			}
		}

		if (item?.package_permissions) {
			item.with_package_perms = packageValidation(item);
			item.showInMenu = item.with_package_perms || item.is_accesible === false;

			item.element = item.with_package_perms ? item.element : defaultWindow.NoPermision;
		}

		if (item?.inConstruction) {
			item.showInMenu = item?.showInMenu ?? true;
			item.element = item?.showInMenu ? defaultWindow.UnderConstruction : defaultWindow.PageNotFound;
		}

		const hide = (!readPermissions?.has_perm && size(itemPermissions) && !itemPermissions?.some(el => el.has_perm)) || (!!item.validateItem && !isAbrhil()) || item.show_in_menu === false;

		if (hide) {
			item.element = defaultWindow.NoPermision;
			item.showInMenu = false;
		}

		if (!hasValue(item.element) && item.type === 'item') {
			item.element = defaultWindow.UnderConstruction;
		}

		return item;
	};

	const permised = (items, parent) => {
		return items.map(item => {
			item = buildItem(item);
			item.title = t(item.title);
			item.exact = item.exact ?? true;
			item.showInMenu = item.showInMenu ?? item.show_in_menu ?? item?.index ?? item?.type !== 'sub-route';
			item.element = item.inConstruction ? defaultWindow.UnderConstruction : item.componentElm;

			item = getItemProps(item);

			if (item.type !== "group") {
				item.bread = [...(parent.bread || []), item.title];
			}

			if (size(item?.children)) {
				item.children = permised(item.children, item);
				item.showInMenu = item?.type !== 'item' ? !!size(item.children.filter(child => child.showInMenu)) : item?.showInMenu;
			}

			if (size(item?.sub_routes)) {
				item.sub_routes = permised(item?.sub_routes, item);
				item.showInMenu = !!size(item?.sub_routes || item?.children);
			}

			return item;
		});
	};

	return permised(allItems, { bread: [] });
};

export const getNavItem = (key, replace = true, fullItem = false) => {
	if (!hasValue(key)) {
		return null;
	}
	const routes = store.getState().navigate?.routes;
	let item = null;
	if (routes) {
		for (const route of routes) {
			const foundItem = findItemByKey(route, key);
			if (foundItem) {
				item = foundItem;
				break;
			}
		}
	}

	if (fullItem) {
		return item;
	}

	const { path } = item ?? {};

	if (!hasValue(path)) {
		return null;
	}

	return replace ? `../${path}` : path;
};

export const getModulePerm = (moduleId, permName) => {
	const modulePerms = getNavItem(moduleId, false, true)?.perms;
	if (!permName) {
		return modulePerms;
	}
	return modulePerms?.find(el => el?.action === permName)?.has_perm;
};

export const permsGate = (perms) => {
	if (isArray(perms)) {
		return perms.map(perm => permsGate(perm)).some(el => el);
	}
	if (isString(perms)) {
		const sPerm = perms.split('.');
		const foundPerms = getModulePerm(sPerm[0], sPerm[1]);
		if (!sPerm[1]) return foundPerms?.some(el => el?.has_perm);
		return foundPerms;
	}
};

function findItemByKey(item, key) {
	if (item?.key === key || item?.id === key) {
		return item;
	}
	if (item?.children) {
		for (const child of item.children) {
			const response = findItemByKey(child, key);
			if (response) {
				return response;
			}
		}
	}
	return null;
}

export const buildMenu = (items) => {
	let structuredMenu = [];
	each(items, function (item) { structuredMenu.push(item); });
	return structuredMenu;
};

export function findItemByPath(path, items) {
	if (!items) return;
	let item = null;
	for (const child of items) {
		const foundItem = itemByPath(child, path);
		if (foundItem) {
			item = foundItem;
			break;
		}
	}
	return item;
}

function itemByPath(item, path) {
	let itemPath = item?.path;
	if (item?.parentPath) {
		itemPath = `${item?.parentPath}/${item?.path}`;
	}
	if (itemPath === path) {
		return item;
	}
	if (item?.sub_routes) {
		for (const child of item.sub_routes) {
			const response = itemByPath(child, path);
			if (response) {
				return response;
			}
		}
	}
	if (item?.children) {
		for (const child of item.children) {
			const response = itemByPath(child, path);
			if (response) {
				return response;
			}
		}
	}
	return null;
}

export function validatePerms(tree) {
	function traverse(node) {
		if (node && node?.perms) {
			const has_perm = node.perms.find(el => el?.has_perm === true);
			if (has_perm) {
				return true;
			}
		}
		if (node && node?.children && size(node?.children) > 0) {
			for (let i = 0; i < node.children.length; i++) {
				if (traverse(node.children[i])) {
					return true;
				}
			}
		}
		return false;
	}
	return traverse(tree);
}

export const fromAttendance = (returnObject = true) => {
	const currentPath = getCurrentPath();
	const attendancePath = 'attendance-mopers';
	const value = currentPath.includes(attendancePath);
	const object = value ? { from_list: true } : {};
	return returnObject ? object : value;
};

export const fromMopers = (returnObject = true) => {
	const mopersPath = getNavItem('modules@mopers', false);
	const currentPath = getCurrentPath();
	const value = currentPath.includes(mopersPath);
	const object = value ? { from_mopers: true } : {};
	return returnObject ? object : value;
};

export const getCurrentPath = () => window.location.pathname.replace('/app/', '');

export const markText = (title, search) => {

	if (!hasValue(title) || !hasValue(search)) {
		return (
			<p className='first-letter'>
				{title}
			</p>
		);
	}


	const original = title;
	const searchSize = size(search);

	title = replaceLetter(title);
	search = replaceLetter(search);

	const start = title.indexOf(search);

	if (start !== -1) {
		return (
			<span className='first-letter'>
				{original.substring(0, start)}
				<pre className={"markText"}>
					{original.substring(start, start + searchSize)}
				</pre>
				{original.substring(start + searchSize)}
			</span>
		);
	}

	return original;
};

export const isSelected = (itemBreads, currentBreads) => {
	if (!hasValue(itemBreads) || !hasValue(currentBreads)) {
		return "";
	}
	const breads = currentBreads.slice(0, size(itemBreads));
	return isEqual(breads, itemBreads) ? "selected" : "";
};