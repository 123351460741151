import PropTypes from "prop-types"
import React from "react";
//-- Tools
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const InputAdorn = ({
	icon,
	position,
	className,
	onClick
}) => {

	const isFa = !!icon?.prefix;
	return (
		<InputAdornment position={position || "end"}  className="kendoAdornment">
			<IconButton
				className={`${className} k-button-icon asKendoButton`}
				onClick={onClick ?? undefined}
				aria-label="input icon adornment"
				disabled={!onClick}
			>
				{isFa ? <FontAwesomeIcon icon={icon} size="xs" /> : icon}
			</IconButton>
		</InputAdornment>
	);
};
InputAdorn.propTypes = {
	className: PropTypes.any,
	icon: PropTypes.any,
	onClick: PropTypes.func,
	position: PropTypes.string
}
