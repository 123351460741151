import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { lockedWindow, showConfirmNotification, unlockedWindow } from "../../../../../../../../../../store/actions";
import { isFunction } from "lodash";
import { resolveError } from "../../../../../../../../../common/resolve-error";
import { applyDirectAbsen } from "../../../../hooks/useGrid";
import { yupResolver } from "@hookform/resolvers/yup";
import { applyRestValidation } from "../utilities/validations";
import { useTranslation } from "react-i18next";

export const APPLY_REST_KEY = 4;

function useApplyRest({
    modalValues,
    handleClose,
    handleRefresh,
    selected,
    worker,
}) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { value: open } = modalValues[APPLY_REST_KEY];

    const {
        control,
        reset,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(applyRestValidation),
        defaultValues: { option: 1 },
        mode: "onChange",
    });

    const onClose = () => handleClose(APPLY_REST_KEY, reset);

    async function onSubmit(data, confirm, confirmFuture) {
        dispatch(lockedWindow());
        try {
            const request = {
                ...data,
                movement_date: selected?.origin_day,
                absenteeism_reason: 16,
                worker: worker.id,
                validate_change: true,
                worker_info: { name: worker?.name, first_surname: worker?.first_surname, second_surname: worker?.second_surname },
            }
            const resp = await applyDirectAbsen(request);
            dispatch(unlockedWindow());
            if (resp) {
                onClose();
                if (isFunction(handleRefresh)) {
                    handleRefresh(false, selected);
                }
            }
        } catch (error) {
            dispatch(unlockedWindow());
            const errorData = error.response?.data ?? {};
            const { key, need_confirm } = errorData;
            if (need_confirm || key) {
                dispatch(showConfirmNotification({
                    maxWidth: 'sm',
                    message: errorData?.detail,
                    description: t("label-draft-question"),
                    onConfirm: () => onSubmit(
                        data,
                        !confirm ? !!key : confirm,
                        !confirmFuture ? !!need_confirm : confirmFuture
                    ),
                }));
                return;
            }
            resolveError(error);
        }
    }

    return {
        open,
        worker,
        control,
        selected,
        onClose,
        onSubmit,
        handleSubmit,
    };
};

export default useApplyRest;