import * as yup from "yup";
import { stringValidation } from "../../../common/validations/stringValidation";
import i18next from "i18next";
import fileValidation from "../../../common/validations/fileValidation";
import { monthLabel } from '../../../../App/components/Dates/utilities';
const { t } = i18next;

export const bimesters = [
    { label: `${monthLabel(1)} - ${monthLabel(2)}`, value: "1,2", send: 1 },
    { label: `${monthLabel(3)} - ${monthLabel(4)}`, value: "3,4", send: 2 },
    { label: `${monthLabel(5)} - ${monthLabel(6)}`, value: "5,6", send: 3 },
    { label: `${monthLabel(7)} - ${monthLabel(8)}`, value: "7,8", send: 4 },
    { label: `${monthLabel(9)} - ${monthLabel(10)}`, value: "9,10", send: 5 },
    { label: `${monthLabel(11)} - ${monthLabel(12)}`, value: "11,12", send: 6 },
];

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

export const bimonthly = bimesters.find(
    (f) =>
        f.value
            .split(",")
            .map((m) => parseInt(m))
            .indexOf(parseInt(currentMonth)) !== -1
);

export const ValidationSchema = yup.object().shape({
    year: stringValidation({ required: true, min: 4 }),
    month: stringValidation({ required: true, min: 1, max: 2 }),
    bimester: stringValidation({ required: true }),
    file: yup.mixed().when(["buttom_action"], {
        is: (buttom_action) => buttom_action === "process",
        then: () => {
            return fileValidation({ required: true, allowedExtensions: "sheet" })
        },
        otherwise: () => fileValidation({ required: false, allowedExtensions: "sheet" })
    }),
    employer_registration: stringValidation({ required: true, min: 1 }),
});

export const actions = [
    { id: 0, name: t("EMA") },
    { id: 1, name: t("EBA") },
];

export const initValues = {
    view: 0,
    year:  currentYear?.toString(),
    month: currentMonth?.toString(),
    bimester: bimonthly?.value,
    employer_registration: '',
    buttom_action: "process"
};
