
export const periodStatus = {
	open: 0,
	inCompute: 1,
	computedFail: 2,
	computed: 3,
	close: 4,
};

export const dispersedStatus = {
	notDispersed: 0,
	dispersed: 1,
	stamped: 2,
};

export const computedType = {
	normal: 1,
	settlement: 2,
	payrollTips: 3,
	bonusDays: 4,
	ptu: 5,
	savingFund: 6,
	special: 7,
	settlementAccumulated: 8,
	settlementTest: 9,
};

export const startEndStatus = {
	start: 1,
	end: 2,
	notApply: 0,
};

export const sttlementCategories = {
	acumulative: 1,
	test: 2,
	real: 3,
};

export const stampStatus = {
	notStamped: 0,
	incomplete: 1,
	completed: 2
};

export const fieldsLabels = {
	key: "key",
	payroll_type: "payroll-type",
	computed_type: "calculation-type",
	is_accumulated: "accumulate-in-month",
	use_ISR: "use-ISR",
	payroll_locked: "block-calculation",
	payroll: "active-for-payroll",
	list: "active-for-list",
	start_date: "period-pay-start",
	end_date: "period-pay-end",
	start_admin_court_date: "period-admin-start",
	end_admin_court_date: "period-admin-end",
	deposit_date: "period-deposit-date",
	locked_at: "lock-attendance-list",
	signature_attendance_date: "signature-attendance-date",
	current_year: "year-accumulates",
	current_month: "month-accumulates",
	month_status: "month",
	bimester_status: "bimester",
	year_status: "year",
};