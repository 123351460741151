import { lazy } from "react";

const generalsItems = [
    {
        id: 'modules@pay-vacations',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/PayVacations')),
    },
    {
        id: 'modules@vacations-initial-provisions',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/InitialVacationsProvisions')),
    },
];

export default generalsItems;
