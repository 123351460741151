import { t } from "i18next";
import { format } from "date-fns";

export const PayrollCalculateDictionary = [
  { id: 0, name: "payroll-report" },
  { id: 1, name: "payroll-report-employer-registration" },
  { id: 2, name: "payroll-report-incidences" },
  { id: 3, name: "payroll-report-excel-general" },
  { id: 4, name: "payroll-report-concept" },
];

export const formatTitleReport = (key) => {
  return `${t(key)}_${format(
    new Date(),
    "yyyy-MM-dd HH-mm-ss"
  )}`

}

export const tagsReports = [
  { id: 1, key: "main" },
  { id: 2, key: "culture" },
  { id: 3, key: "vacations" },
  { id: 4, key: "idse" },
  { id: 5, key: "payroll" },
  { id: 6, key: "auditing" },
  { id: 7, key: "audit" }
];

/** FORM **/
export const formDefault = {
  open: false,
  item: {},
  type: ""
};

export const initialPageReports = { skip: 0, take: 9 };
export const initialFilterReports = (tag) => ({ is_favorite: tag === "reports" });

export const favoriteOptions = [{
  label: `${t("favorites")}`,
  value: true,
  key: 1,
},
{
  label: `${t("no")} ${t("favorites")}`,
  value: false,
  key: 2,
}];

export const SIZE_MODAL = {
  //payroll
  "payroll-report-concept": "lg",
  "payroll-report-incidences": "xs",
  "payment-relationship": "xs",
  "payroll-report-payment-transfer": "xs",
  'payroll-report-difference-linear': "md",
  "payroll-report-bonus-excel": "sm",
  "payroll-report-loans": "md",
  //idse
  'idse-missing-movements': "xs",
  'report-personal-rotation':"xs",
  //IMSS
  //mopers
  "moper-reports":"lg",
  "moper-reports-pending":"xs",
  //Vacations
  'report-vacation-enjoyed':"sm",
  //culture
  "report-birthday": "xs",
  "report-antiquity": "xs",
  "report-validity": "md",
  "report-contract": "xs",
  "report-document-expiration": "md",
  "report-worker-family-excel": "lg",
  "report-worker-information": "lg",
  "report-worker-current-active": "xs",
  // binnacle
  "worker-master-detail": "md",
  "vacation-audit": "xs",
  "report-permission-role": "xs",
}