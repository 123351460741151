import React, {
	lazy,
	Suspense,
	useEffect,
	useRef,
	forwardRef
} from "react";
import {
	isUndefined,
	isFunction,
	isString,
	isArray,
} from 'lodash';
import { useController, useWatch } from "react-hook-form";
import { Skeleton } from "@progress/kendo-react-indicators";
import { Collapse } from "@mui/material";
import { useSelector } from "react-redux";

/* Validations and utilities */
import { formInputProps, auxiliarContainerProps } from "./ComponentsPropTypes.jsx";
import { trim } from "../../common/validations/validationFilter";
import { hasValue, valueOrOption } from '../../common/GeneralUtilities.jsx';
import {
	getProps,
	numericFormat,
	objectValue,
	selector,
	focusInput,
	AutoFocus,
	evaluateUppercase,
} from "./utilities";
import { ErrorValidation } from "./Error";
import { conditional } from "../grid/CommandCell.jsx";

const defaultModule = (route) => import(`./../../../App/components/search/${route}`).then(module => ({ default: module.InputAndModal }));
/* Inputs */
const WorkerSearch = lazy(() => defaultModule("Worker"));
const StructureSearch = lazy(() => defaultModule("Structure"));
const ConceptSearch = lazy(() => defaultModule("Concepts"));
const CoursesSearch = lazy(() => defaultModule("Courses"));
const InstructorsSearch = lazy(() => defaultModule("Instructors"));

const MaterialUpload = lazy(() => import("../upload/MaterialUpload"));
const FormatSelect = lazy(() => import("../../../App/components/Select/FormatSelect"));
const KendoDatePicker = lazy(() => import("../../../App/components/Dates/KendoDatePicker"));
const KendoDropdown = lazy(() => import("../../../App/components/Select/KendoDropDown"));
const ServerDropdown = lazy(() => import("../../../App/components/Select/kendoServerDropdown"));
const SearchInput = lazy(() => import("../../../App/components/search/Input"));
const TextArea = lazy(() => import("../../../App/components/textInputs/TextArea"));
const CustomSelect = lazy(() => import('../../../App/components/Select/CustomSelect'));
const Textbox = lazy(() => import("../../../App/components/textInputs/Textbox"));
const KendoNumeric = lazy(() => import("../../../App/components/Numeric/KendoNumeric"));
const NumberInput = lazy(() => import("../../../App/components/Numeric/NumberInput"));
const NumberInputIcon = lazy(() => import("../../../App/components/Numeric/NumberInputIcon"));
const NumberFormatInput = lazy(() => import("../../../App/components/Numeric/NumberFormatInput"));
const KendoMultiselect = lazy(() => import("../../../App/components/MultiSelect/KendoMultiselect"));
const KendoTreeMultiselect = lazy(() => import("../../../App/components/MultiSelect/KendoTreeMultiselect"));
const SwitchMui = lazy(() => import("../../../App/components/Booleans/SwitchMui"));
const TextField = lazy(() => import("../../../App/components/textInputs/TextField"));
const KendoDateMonthOrYear = lazy(() => import("../../../App/components/Dates/KendoDateMonthOrYear"));
const PayrollPeriod = lazy(() => import("../../../App/components/Select/PayrollPeriod"));
const PayrollType = lazy(() => import("../../../App/components/Select/PayrollType"));
const TextFieldIcon = lazy(() => import("../../../App/components/textInputs/TextFieldIcon"));
const KendoDateTime = lazy(() => import("../../../App/components/Dates/KendoDateTime"));
const KendoTimePicker = lazy(() => import("../../../App/components/Time/KendoTimePicker"));
const Editor = lazy(() => import("../../../App/components/textInputs/Editor"));
const Color = lazy(() => import("../../../App/components/Select/Color"));
const Checkbox = lazy(() => import("../../../App/components/Booleans/KendoCheckbox"));
const RadioGroup = lazy(() => import("../radio/RadioGroup"));
const GroupingSelect = lazy(() => import("../../../App/components/Select/GroupingSelect"));
const TextFormatInput = lazy(() => import("../../../App/components/textInputs/TextFormatInput"));

/**
 * Auxiliar container for fields
 * @param {auxiliarContainerProps} params
 * @returns {JSX.Element}
 */
const DivContainer = ({ children, ...others }: auxiliarContainerProps) => (
	<div style={{ width: "100%", height: "auto" }} {...others}>
		{children}
	</div>
);


/**
 * Hook que nos sirve de controlador para los formularios controlados y validados por hook-form
 * !NOTA : Este componente es unmediador entre los componenes que se renderizan y el controlador del hook form,
 * Por lo que se le pueden pasar todas las propiedades del componente original que se este renderizando
 * @param {formInputProps} props
 * @returns {React.JSX.Element}
 * @example
 * <FormInput
 *		control={control}
 *		fieldInput="TextField"
 *		name="name"
 *		isRequired={true}
 *		onChange={e => {
 *			// codigo....está parte es opcional, se puede NO declarar onChange
 *			return e;
 *		}}
 * />
*/

const FormInput = forwardRef(function InputFunction({
	name,
	control,
	value,
	onChange,
	className,
	isRequired,
	groupError,
	errorByGroup,
	triggerChange = () => null,
	disabled = false,
	disabledBy,
	disabledByInverse,
	disabledByValue,
	Container,
	containerProps,
	fieldInput,
	autoFocus,
	focusPointer,
	focusChange,
	onKeyDown,
	onEnter,
	uppercase = true,
	lowercase = false,
	customValue = false,
	disableSaveWithEnter = false,
	...others
}: formInputProps, ref) {

	fieldInput = valueOrOption(fieldInput, "").toLowerCase();

	const {
		field,
		fieldState: { invalid, error },
	} = useController({ name, control });

	const handleChange = e => {
		let type = conditional(isUndefined(e?.value) && isUndefined(e?.target) && isUndefined(e?.type), "change", e?.type); /* para elementos que solo devuelven el valor como el customselect */
		if (isFunction(onChange) && type === "change") { /* solo se ejecuta cuando declaramos un onChange custom */
			e = onChange(e, field);
		}

		e = evaluateUppercase(field.name, fieldInput, e, uppercase, lowercase);

		field.onChange(e);
		triggerChange();
		if (hasValue(focusChange) && isString(focusChange)) {
			focusInput(focusChange, true);
		}
	};

	const extraError = conditional(groupError, ErrorValidation(groupError, control), {});
	const message = error?.message ?? extraError?.message ?? "";
	const isValid = (!invalid && (extraError?.valid ?? true)) || message === "";
	let auxDisabled = false;
	let inverseDisabled = false;
	const disabledByWatchValue = useWatch({ control, name: valueOrOption(disabledBy,"fakeInput") });
	const disabledByInverseWatchValue = useWatch({ control, name: valueOrOption(disabledByInverse,"fakeInput") });

	if (disabledBy && !disabled) {
		auxDisabled = disabledByWatchValue;
		if (disabledByValue) {
			disabledByValue = conditional(isArray(disabledByValue), disabledByValue, [disabledByValue]);
			auxDisabled = disabledByValue.includes(auxDisabled);
		}
	}

	if (disabledByInverse && !disabled) {
		inverseDisabled = !disabledByInverseWatchValue;
	}

	disabled = disabled || auxDisabled || inverseDisabled;

	if (disabled) {
		autoFocus = false;
	} else if (name === 'key') {
		autoFocus = valueOrOption(autoFocus, true);
	}

	const finalValue = conditional(!customValue, valueOrOption(field?.value, value), value);
	const isRequiredClass = conditional(isRequired, "requiredInputField", "");
	const onEnterClass = conditional(isString(focusPointer), 'on-enter-custom', '');
	const onHasValueClass = conditional(hasValue(finalValue), 'field-has-value', '');

	const commonProps = {
		...others, /* Se heredan todos los atributos */
		innerRef: ref,
		fieldInput,
		autoComplete: "off",
		name: name,
		value: finalValue,
		valid: isValid,
		error: !isValid,
		onChange: handleChange,
		className: `${valueOrOption(className, "")} ${isRequiredClass} ${onEnterClass} ${onHasValueClass}`,
		disabled: disabled,
		autoFocus,
		onKeyDown: (e) => onKeyDownFunction(e, onEnter, onKeyDown, focusPointer, disableSaveWithEnter),
	};

	Container = valueOrOption(Container, DivContainer);
	return (
		/* style and other props applied to container must be in container props object */
		<Container data-cy={valueOrOption(others["data-cy"], name)} {...(valueOrOption(containerProps, {}))}>
			<Suspense fallback={<LoadInput />}>
				<Ui {...commonProps} />
			</Suspense>
			<Collapse in={!isValid && !errorByGroup} className='form-input-formerror'>
				<p className="form-error">{message}</p>
			</Collapse>
		</Container>
	);
});

export default FormInput;

const onKeyDownFunction = (e, onEnter, onKeyDown, focusPointer, disabledEnter) => {
	const isEnter = e.keyCode === 13;
	if (disabledEnter && isEnter) { e.preventDefault(); }
	if (isEnter && isFunction(onEnter)) { onEnter(e); }
	if (isFunction(onKeyDown)) { onKeyDown(e); }
	if (hasValue(focusPointer) && isString(focusPointer) && isEnter) {
		focusInput(focusPointer, true);
	}
	return e;
};

const LoadInput = () => <Skeleton
	shape="rectangle"
	animation={{ type: "wave", }}
	style={{ width: "100%", height: 30, }}
/>;

/**
**	Solo es un intermediario que nos mostrara el elemento que deseamos
*	todos los elementos heredan todas la propiedades que se declararon en el FormInput al momento de utilizarlo
**/
const Ui = ({
	fieldInput,
	asString,
	innerRef,
	...props
}) => {
	const alreadyFocused = useRef(false);

	const loading = useSelector((state) => state.locked?.lockedWindow);

	const {
		onChange,
		onBlur,
		onInputChange,
		allowLeadingZeros,
	} = props;

	useEffect(() => {
		if (!props.autoFocus || loading || alreadyFocused.current || props.disabled) return;
		alreadyFocused.current = AutoFocus(props?.name, props?.id, props.selectOnFocus ?? true);
	}, [loading, props.disabled, props.autoFocus]);

	useEffect(() => {
		const inputRef = selector(!props?.id ? `[data-cy="${props?.name}"]` : `#${props?.id}`);
		if (!inputRef) return;
		const inputButton = inputRef.querySelector('.k-input-button:not([tabindex = "-1"])');
		if (!inputButton) return;
		inputButton.setAttribute('tabindex', -1);
	}, []);

	const trimOnBlur = (e) => {
		e.value = trim(e.value);
		e.target.value = trim(e.target.value);
		onChange(e);
		if (isFunction(onBlur)) {
			onBlur(e);
		}
	};

	const textAreaOnblur = (e) => {
		const value = e.value ?? e.target?.value ?? e.nativeEvent?.target?.value ?? e.target?.element?.current?.value;
		e.value = trim(value);
		onChange(e);
		if (isFunction(onBlur)) {
			onBlur(e);
		}
	};

	const handleOnInputChange = (e, _) => {
		const value = e?.value ?? e?.target?.value ?? _ ?? "";
		if (isFunction(onInputChange)) {
			onInputChange(objectValue({}, value), _, onChange);
		}
	};

	const handleSelected = selected => {
		onChange(objectValue({}, selected));
	};

	const isStringNumber = allowLeadingZeros || asString;
	const numberChange = e => numericFormat(e, /,|\s|_/g, onChange, isStringNumber);
	const phoneFormatChange = e => numericFormat(e, /\(|\)|\s|_|-/, onChange, isStringNumber ?? true);

	const item_func = {
		"checkbox": () => <Checkbox {...getProps(props)} checked={props.value} />,
		"color": () => <Color {...getProps(props)} />,
		"conceptsearch": () => <ConceptSearch {...getProps(props, true)} />,
		"coursessearch": () => <CoursesSearch {...getProps(props, true)} />,
		"instructorssearch": () => <InstructorsSearch {...getProps(props, true)} />,
		"customselect": () => <CustomSelect {...getProps(props, true)} onInputChange={handleOnInputChange} />,
		"date": () => <KendoDatePicker {...getProps(props)} />,
		"datemonth": () => <KendoDateMonthOrYear {...getProps(props)} format={props.format ?? "MM - MMMM"} />,
		"datetime": () => <KendoDateTime {...getProps(props)} />,
		"dateyear": () => <KendoDateMonthOrYear {...getProps(props)} format={props.format ?? 'yyyy'} />,
		"dropdown": () => <KendoDropdown {...getProps(props)} />,
		"editor": () => <Editor {...getProps(props)} />,
		"file": () => <MaterialUpload {...getProps(props, true)} />,
		"formatselect": () => <FormatSelect {...getProps(props, true)} />,
		"multiselect": () => <KendoMultiselect {...getProps(props)} onChange={handleSelected} />,
		"multiselecttree": () => <KendoTreeMultiselect {...getProps(props)} onChange={handleSelected} />,
		"numberformatinput": () => <NumberFormatInput {...getProps(props, true)} onChange={phoneFormatChange} />,
		"numbericon": () => <NumberInputIcon {...getProps(props, true)} onChange={numberChange} />,
		"numberinput": () => <NumberInput {...getProps(props, true)} onChange={numberChange} />,
		"numeric": () => <KendoNumeric {...getProps(props)} />,
		"payrollperiod": () => <PayrollPeriod {...getProps(props)} />,
		"payrolltype": () => <PayrollType {...getProps(props)} />,
		"phone": () => <NumberFormatInput {...getProps(props, true)} onChange={phoneFormatChange} />,
		"radiogroup": () => <RadioGroup {...getProps(props, true)} />,
		"searchinput": () => <SearchInput {...getProps(props, true)} />,
		"serverdropdown": () => <ServerDropdown {...getProps(props)} />,
		"structuresearch": () => <StructureSearch {...getProps(props, true)} />,
		"switch": () => <SwitchMui {...getProps(props, true)} checked={props.value} />,
		"textarea": () => <TextArea {...getProps(props)} onBlur={textAreaOnblur} />,
		"textbox": () => <Textbox {...getProps(props)} onBlur={trimOnBlur} />,
		"textfield": () => <TextField {...getProps(props, true)} onBlur={trimOnBlur} />,
		"textfieldicon": () => <TextFieldIcon {...getProps(props, true)} onBlur={trimOnBlur} />,
		"time": () => <KendoTimePicker {...getProps(props)} />,
		"workersearch": () => <WorkerSearch {...getProps(props, true)} />,
		"textformatinput": () => <TextFormatInput {...getProps(props, true)} ref={innerRef} onChange={onChange} />,
		"groupingselect": () => <GroupingSelect {...getProps(props)} />
	}[(fieldInput ?? "").toLowerCase()] ?? (() => <input {...props} />);

	return item_func();
};