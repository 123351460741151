import PropTypes from "prop-types";
import React from "react";
import { FlowAndMessage, MainInfo, MoperIcon, MoperInfo } from "../../../../../../../../SignaturesByWorker/components/Details/components/DynamicMoper";
import { hasBuiltMoper, moperConstructor } from "../../../../../../../../SignaturesByWorker/components/Details/components/models";
import { customMoperModels } from "../../../../models";
import { hasMinimal } from "../utils/utils";
import Grid from "@mui/material/Grid2";
import { gridSize } from "../../../../../../../../AbrhilApp/Company/Components/AddEdit";
import { GenericLabel } from "../../../../../../../../SignaturesByWorker/components/Shared";
import { parseDatetime } from "../../../../../../../../../common/GeneralUtilities";
import { SolidDivider } from "../../../Modals/TimePayment";

function DetailMoperItem({
    moper,
    onSign,
    onEdit,
    onlyRead,
    item,
    data,
    refresh,
    withPopupFlow = false,
    fromList = false,
    ...others
}) {

    const req = hasBuiltMoper(item) ? item : moperConstructor(moper, customMoperModels, true);
    const hasMin = hasMinimal(req);
    const moperData = data ?? req?.data;
    const hasData = moperData?.filter(el => el?.value !== '-').length > 0;

    return (
        <div className="mopers-detail-item-flow">
            <DetailMoper
                request={req}
                hasData={hasData}
                fields={moperData}
                moper={req?.exactMoper}
                withPopupFlow={withPopupFlow}
                fromList={fromList}
                onlyRead={onlyRead}
                refresh={refresh ?? onSign}
                {...others}
            />
            {(hasData && !hasMin) &&
                <FlowAndMessage
                    fromList={fromList}
                    exactMoper={req?.exactMoper}
                    payloadFields={req?.payloadFields}
                    refresh={onSign ?? refresh}
                    onEdit={onEdit}
                    onlyRead={onlyRead}
                    showMessage
                    showCancel
                    showLevel
                    {...others}
                />}
        </div>
    );
};

export default DetailMoperItem;

const DetailMoper = ({ request, ...others }) => {

    const hasMin = hasMinimal(request);

    return (
        <div className="popup-moper-info-data">
            {!hasMin ?
                <CompleteDetail {...others} request={request} />
                :
                <MinimalDetail {...others} request={request} />}
        </div>
    );
};

const CompleteDetail = ({
    request,
    moper,
    fromList,
    hasData,
    fields,
    ...others
}) => {

    return (
        <>
            <MainInfo
                {...request}
                moper={moper}
                request={request}
                fromList={fromList}
                statusResponsive
                showDetails={false}
                {...others}
            />
            {!hasData ?
                <div className="no-moper-info-wrapper">
                    <strong>{'No hay información disponible para este moper'}</strong>
                    <span>{'Este moper fue creado a partir de una importación'}</span>
                </div>
                :
                <MoperInfo
                    data={fields}
                    moper={moper}
                    moperValue={request}
                    showFlow={!fromList}
                    {...others}
                />}
        </>
    );
}

const MinimalDetail = ({ request, moper, ...others }) => {

    return (
        <Grid
            container
            spacing={2}
            direction="row"
            {...gridSize(12)}
            wrap="nowrap"
            className="vertical-center"
        >
            <Grid>
                <MoperIcon
                    {...request}
                    request={request}
                    moper={moper}
                    {...others}
                />
            </Grid>
            <Grid container direction="column" spacing={0}>
                <Grid {...gridSize(12)} sx={{ color: 'var(--egyptianBlue)' }}>
                    {request?.name}
                </Grid>
                <Grid {...gridSize(12)}>
                    <GenericLabel
                        label={'Aplicado por'}
                        fontSize='12px'
                        value={moper?.user?.username ?? moper?.requested_by}
                    />
                </Grid>
                <Grid {...gridSize(12)}>
                    <GenericLabel
                        label={'Fecha y hora de aplicación'}
                        fontSize='12px'
                        value={parseDatetime(moper?.created_at, 'dd/MM/yyyy HH:mm')}
                        labelStyle={{ color: 'var(--midGray)' }}
                        style={{ color: 'var(--midGray)' }}
                    />
                </Grid>
            </Grid>
            {moper?.request_comment &&
                <>
                    <Grid sx={{ height: '100%' }}>
                        <SolidDivider vertical />
                    </Grid>
                    <Grid>
                        <GenericLabel
                            label={'Comentario'}
                            fontSize='12px'
                            align="vertical"
                            value={moper?.request_comment}
                            style={{ maxWidth: '23rem' }}
                            wrapValue
                        />
                    </Grid>
                </>}
        </Grid>
    );
};

DetailMoper.propTypes = {
    moper: PropTypes.object,
    request: PropTypes.object,
    fields: PropTypes.array,
    hasData: PropTypes.bool,
};

DetailMoperItem.propTypes = {
    moper: PropTypes.object,
    onSign: PropTypes.func,
    onEdit: PropTypes.func,
    onlyRead: PropTypes.bool,
    item: PropTypes.object,
    data: PropTypes.array,
    withPopupFlow: PropTypes.bool,
};