
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
/* components */
import {
	faSun
} from "@fortawesome/pro-regular-svg-icons";

import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { systemTheme } from "../../../../../store/actions";
import { valueOrOption } from '../../../../common/GeneralUtilities';
import IconUse from "../../../../common/IconUse";

const ThemeOption = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const currentTheme = localStorage.getItem('theme') === "dark" ? "dark" : "light";
	const [darkMode, setDarkMode] = useState(currentTheme === "dark");

	useEffect(() => {
		setDarkMode(currentTheme === "dark");
		//eslint-disabled-next-line
	}, [currentTheme]);

	const changeTheme = (theme) => {
		theme = valueOrOption(theme, "light");
		const isDark = theme === "dark";
		window.localStorage.setItem('theme', theme);
		document.querySelector("body").className = theme;
		setDarkMode(isDark);
		dispatch(systemTheme(theme));
	};

	return (
		<MenuItem onClick={() => changeTheme(darkMode ? "light" : "dark")}>
			<ListItemIcon className='user-menu-icon'>
				{darkMode ?<FontAwesomeIcon icon={faSun} /> 
					: <IconUse icon={'darkmode'} style={{'width':'19px', 'height':'19px' }}/>}
			</ListItemIcon>
			{t(`themes.${darkMode ? "light" : "dark"}`)}
		</MenuItem>
	);
};

export default ThemeOption;