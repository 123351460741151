import React, { useCallback, useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from "react-i18next";
import { buildCheck, buildHour, buildHours, isAutoMoper } from "../../utils/utilities";
import { ReactComponent as EnterIcon } from "../../../../../icons/icono_entrada.svg";
import { ReactComponent as ExitIcon } from "../../../../../icons/icono-salida.svg";
import { isDate, isFunction, isNil, isString, size } from "lodash";
import { uid } from "uid";
import { useDraggable, useDroppable } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { useDragContext } from "../../hooks/useDragCheck";
import { format, isToday } from "date-fns";
import { ListItemIcon, Menu, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import Flag from "../Flags";
import { resolveError } from "../../../../../../../common/resolve-error";
import { mopersESApi } from "../../../../../../../services/mopers";
import { t } from "i18next";
import { GridContext } from "../../contexts/GridContext";
import { showNotification } from "../../../../../../../../App/components/Notifications";
import { formatDate } from "../../../../../../Attendance/ConstantAndUtilities/utilities";
import { SolidDivider } from "../Modals/TimePayment";
import { useModalsContext } from "../../hooks/useModals";
import { MODAL_KEY as ASSIST_MODAL_KEY } from "../Modals/AssistSetting/hooks/useAssistSetting";
import { useDispatch } from "react-redux";
import { showNotificationWarning } from "../../../../../../../../store/actions";
import "../../../../../styles/style.scss";

const byCheckFields = {
	0: 'check_in',
	1: 'check_out',
};

const calcFields = {
	0: 'check_in_calculated',
	1: 'check_out_calculated',
};

export function getCheck(check, byCheck, i, withFormat = true, withField = false) {
	let value = withFormat ? formatDate(check?.clock_process?.[0]?.[calcFields[i]]) : check?.clock_process?.[0]?.[calcFields[i]];
	let field = calcFields[i];

	if (byCheck) {
		field = byCheckFields[i];
		value = withFormat ? formatDate(check?.[field]) : check?.[field];
	}

	return withField ? {
		field,
		value
	} : value;
}

export const getChecksIndicators = ({
	less,
	late,
	over,
	incidence,
	adjust,
}) => ([
	{ id: 1, color: 'green', position: 'top-right', visible: adjust, title: 'Ajuste de asistencia' },
	{ id: 2, color: 'red', position: 'top-left', visible: less, title: 'Jornada laboral incompleta' },
	{ id: 3, color: 'blue', position: 'top-left', visible: over, title: 'Jornada laboral excedente' },
	{ id: 4, color: 'orange', position: 'bottom-right', visible: incidence, title: 'Incidencia de horario' },
	{ id: 5, color: 'lightblue', position: 'bottom-left', visible: late, title: 'Retardo' },
].filter(el => el.visible));

export const getChecksStatus = checks => {
	const less = checks?.some(el => el.less);
	const late = checks?.some(el => el.late);
	const over = checks?.some(el => el.over);
	const incidence = checks?.some(el => el.incidence);
	const adjust = checks?.some(el => el.adjust);

	return {
		less,
		late,
		over,
		incidence,
		adjust,
	};
}

function WorkingDay({ item }) {

	const checks = item?.checks;
	const values = getChecksStatus(checks);
	const flagOptions = getChecksIndicators(values);

	const { handleRefresh, hasOvertimeActive, currentPeriod } = useContext(GridContext);

	const refresh = async () => await handleRefresh(false, item);

	return (
		<Card
			key={`working-day-card-${item?.id}`}
			className={`custom-extra-card mopers-working-day custom-modern-card`}
		>
			{flagOptions.map(el => <Flag key={el.id} {...el} />)}
			<CardContent className='extra-card-content'>
				<WorkingDayContent
					period={currentPeriod}
					item={item?.overtime ?? item}
					refresh={refresh}
					showReal={!hasOvertimeActive}
				/>
			</CardContent>
		</Card>
	);
}

export default WorkingDay;

export const handleContextMenu = (event, setContext, context) => {
	event.preventDefault();
	setContext(
		context === null
			? {
				mouseX: event.clientX + 2,
				mouseY: event.clientY - 6,
			}
			:
			null,
	);
};

export const WorkingDayContent = ({
	item,
	refresh,
	menu = true,
	drag = true,
	showReal = false
}) => {

	const { t } = useTranslation();
	const { checks, type_calculation } = item;
	const { handleOpen, worker, currentPeriod } = useModalsContext();
	const dayDate = new Date(item?.origin_day?.split('-')?.join('/'));
	const byCheck = ((type_calculation ?? 1) === 1) || showReal || isToday(dayDate);
	const workedTime = !byCheck ? item?.total_calculated_rounded : item?.total_real_rounded;

	const [contextMenu, setContextMenu] = useState(null);

	const handleClose = () => setContextMenu(null);

	return (
		<>
			<div className={`workingday-container ${menu ? 'has-menu' : ''}`}>
				{menu && <div className="background-context" onContextMenu={(e) => menu ? handleContextMenu(e, setContextMenu, contextMenu) : null} />}{/*NOSONAR*/}
				<div className='enters-exits'>
					<CheckItem
						date={item?.origin_day}
						item={item}
						checks={checks}
						byCheck={byCheck}
						period={currentPeriod}
						drag={drag}
						menu={menu}
						worker={worker}
						refresh={refresh}
					/>
				</div>
				<SolidDivider vertical />
				<div className={`hours-calc`}>
					<div className="info-wrapper workday">
						<span className="title">
							{t('Jornada')}
						</span>
						<span className="info">
							{buildHours(item?.minutes_workday)}
						</span>
					</div>
					<div className="info-wrapper">
						<span className="title">
							{t('Hrs. Trab')}
						</span>
						<span className={`info ${workedTime < item?.minutes_workday ? 'red' : ''}`}>
							{buildHour(workedTime)}
						</span>
					</div>
				</div>
			</div>
			{menu &&
				<AssistMenu
					item={item}
					period={currentPeriod}
					onClickAdjust={() => handleOpen(ASSIST_MODAL_KEY, item)}
					contextMenu={contextMenu}
					handleClose={handleClose}
				/>}
		</>
	);
}

const CheckItem = ({
	checks,
	byCheck,
	date,
	drag,
	menu,
	worker,
	item,
	refresh,
	period
}) => {

	return (
		<div className="check-item-wrapper">
			{
				[1, 2].map((el, i) =>
					<div key={`check-${el}`} className="single-check">
						<div className="check-icon">
							{i === 0 ? <EnterIcon /> : <ExitIcon />}
						</div>
						<div className="check-info-wrapper">
							<div className="labels">
								<span className="label">
									{i === 0 ? 'Entrada' : 'Salida'}
								</span>
								<span className="label">
									{'Reloj'}
								</span>
							</div>
							<div className="checks-container">
								{size(checks) ?
									checks.map(check => (
										<CheckData
											key={check.id}
											drag={drag}
											item={check}
											period={period}
											parentItem={item}
											menu={menu}
											refresh={refresh}
											worker={worker}
											byCheck={byCheck}
											date={date}
											i={i}
										/>
									))
									:
									<CheckData
										menu={menu}
										drag={drag}
										period={period}
										refresh={refresh}
										worker={worker}
										parentItem={item}
										byCheck={byCheck}
										date={date}
										i={i}
									/>}
							</div>
						</div>
					</div>
				)
			}
		</div>
	);
};

const CheckData = (props) => {

	return (
		<Droppable {...props}>
			<DraggableCheck {...props} />
		</Droppable>
	);
};

const DraggableCheck = ({
	i,
	item,
	date,
	drag,
	menu,
	period,
	byCheck,
	refresh,
	parentItem,
}) => {
	const dispatch = useDispatch();
	const {
		setPressed,
		setDragged,
		dragElement,
		draggedCheck,
		wasDropped,
	} = useDragContext();
	const {
		handleOpen,
		isMyWorkgroup,
		deleteCheckPerm,
	} = useModalsContext();

	const hint = useRef();
	const button = useRef();
	const check = getCheck(item, byCheck, i);
	const hasRequests = parentItem?.request_details?.filter(el => !isAutoMoper(el?.exactMoper ?? el))?.length > 0;

	const [thisDragged, setThisDragged] = useState(false);
	const [contextMenu, setContextMenu] = useState(null);

	const [initial, setInitial] = useState(null);
	const [position, setPosition] = useState({});

	const getLabel = () => {
		const body = { message: null, description: null, maxWidth: 'sm' };
		if (!isMyWorkgroup) {
			body.message = 'No tiene asignado este grupo de trabajo';
			body.description = 'Solicite la asignación de este grupo a su administrador';
			return body;
		}
		if (hasRequests) {
			body.message = 'Existen solicitudes en este día';
			body.description = 'Cancele o restaure las solicitudes antes de continuar';
			return body;
		}
		return body;
	}

	const getDeleteLabel = () => {
		const body = getLabel();
		if (body.message || body.description) {
			return body;
		}
		if (!deleteCheckPerm) {
			body.message = 'Sin permisos de eliminar checadas';
			body.description = 'Su usuario no cuenta con permisos para eliminar checadas';
			return body;
		}
		return body;
	}

	const handleClose = () => setContextMenu(null);

	const handleDelete = async (value, type) => {
		if (getDeleteLabel().message || getDeleteLabel().description) {
			dispatch(showNotificationWarning(getDeleteLabel()));
			return;
		}
		try {
			const request = { type_check: type };
			await mopersESApi.deleteCheck(item.id, request);
			if (isFunction(refresh)) refresh(false);
			showNotification({ message: t('success-deleted-message'), showTitle: false });
		} catch (error) {
			resolveError(error);
		}
	};

	const handlePress = useCallback(() => {
		if (!check) return;
		setPressed(true);
	}, [check]);

	const handleDragStart = useCallback((event) => {
		if (!button.current || !check) return;
		if (getLabel().message || getLabel().description) {
			dispatch(showNotificationWarning(getLabel()));
			return;
		}
		setThisDragged(true);
		setDragged(true);
		const data = {
			id_start: item?.id,
			type_start: i + 1,
			date_start: date,
			item
		};
		draggedCheck.current = data;
		dragElement.current = button.current;
		setInitial({ x: event.clientX, y: event.clientY });
	}, [position.x, position.y, button.current, check]);

	const handleDrag = useCallback(
		(event) => {
			if (!initial || !check) return;
			setPosition({
				x: event.clientX - initial.x + event.scrollX,
				y: event.clientY - initial.y + event.scrollY
			})
		},
		[initial]
	);

	const handleDragEnd = useCallback(() => {
		setInitial(null);
		setThisDragged(false);
		setDragged(false);
		setPosition({});
		if (!wasDropped.current) {
			draggedCheck.current = null;
			dragElement.current = null;
		}
	}, [button.current, wasDropped.current]);

	const handleRelease = useCallback(() => {
		setInitial(null);
		setThisDragged(false);
		setPosition({});
		setDragged(false);
		setPressed(false);
	}, []);

	useDraggable(button, {
		onPress: handlePress,
		onRelease: handleRelease,
		onDragStart: handleDragStart,
		onDrag: handleDrag,
		onDragEnd: handleDragEnd,
	}, { hint });

	const actions = [
		{ label: 'Eliminar', icon: faTrashCan, onClick: handleDelete, class: 'red', type: i + 1 }
	];

	return (
		<>
			{menu &&
				<AssistMenu
					item={parentItem}
					period={period}
					onClickAdjust={() => handleOpen(ASSIST_MODAL_KEY, parentItem)}
					contextMenu={contextMenu}
					handleClose={handleClose}
					contextActions={check ? actions : null}
				/>}
			<Button
				ref={button}
				onContextMenu={(e) => menu ? handleContextMenu(e, setContextMenu, contextMenu) : null}
				className={`value drag-check ${!drag ? 'drag' : ''} ${thisDragged ? 'active' : ''} ${check ? 'has-value' : ''}`}
				disabled={!draggedCheck || !drag}
				style={{ opacity: thisDragged ? 0.5 : 1 }}
				{...(!check ? { onClick: () => handleOpen(ASSIST_MODAL_KEY, parentItem) } : {})}
			>
				<CheckContent item={item} byCheck={byCheck} i={i} date={date} />
			</Button>
			{thisDragged &&
				<Button
					ref={hint}
					disabled={!check}
					className={`value drag-check hint ${thisDragged ? 'active' : ''}`}
					style={thisDragged ? { transform: `translate(${position.x}px, ${position.y}px)` } : {}}
				>
					<CheckContent item={item} byCheck={byCheck} i={i} date={date} />
				</Button>}
		</>
	);
};

const CheckContent = ({ item, byCheck, i, date }) => {
	const isCheckIn = i === 0;
	const check = getCheck(item, byCheck, i);
	const formatDate = date?.split('-')?.reverse()?.join('/');
	const dateCheck = isDate(check) ? format(check, 'dd/MM/yyyy') : check?.split('T')?.[0]?.split('-')?.reverse()?.join('/');
	const diffDate = (isString(date) && check) && formatDate !== dateCheck;
	const noClock = isNil(isCheckIn ? item?.clock_in : item?.clock_out);
	const isUpdate = isCheckIn ? item?.check_in_update : item?.check_out_update;

	return (
		<>
			<div className={`check-info-clock ${noClock ? 'no-clock' : ''} ${isUpdate ? 'is-update' : ''}`}>
				<span className='check-info-value'>
					{buildCheck(check)}
				</span>
				<span className="value">
					{`${isCheckIn ? item?.clock_in?.key ?? 'XX' : item?.clock_out?.key ?? 'XX'}`}
				</span>
			</div>
			{diffDate && <div className="check-diff-date">{dateCheck}</div>}
		</>
	);
}

const Droppable = ({
	children,
	item,
	i,
	date,
	byCheck,
	drag,
	parentItem
}) => {
	const {
		switchCheck,
		dropzone,
		setPressed,
		pressed,
		currentItem,
		wasDropped,
		draggedCheck,
	} = useDragContext();

	const element = useRef();
	const check = getCheck(item, byCheck, i);
	const [isInside, setIsInside] = useState(false);

	const handleDrop = useCallback(() => {
		setIsInside(false);
		setPressed(false);
		if (check || !drag || !draggedCheck.current) return;
		wasDropped.current = true;
		const data = {
			id_final: item?.id,
			type_final: i + 1,
			date_final: date,
			item
		};
		dropzone.current = element.current;
		currentItem.current = parentItem;
		switchCheck(data);
	}, [check, drag, element.current, parentItem, item, date, i, draggedCheck.current]);

	const handleDragEnter = useCallback(() => {
		if (!draggedCheck.current) return;
		setIsInside(true);
	}, [draggedCheck.current]);

	const handleDragLeave = useCallback(() => {
		setIsInside(false);
	}, [draggedCheck.current]);

	useDroppable(element, {
		onDragEnter: handleDragEnter,
		onDragLeave: handleDragLeave,
		onDrop: handleDrop,
	});

	return (
		<div
			ref={element}
			className={`check-item-drop-wrap ${(check || !drag) ? 'disabled' : ''} ${isInside ? 'inside' : ''} ${(pressed && !check) ? 'drag-active' : ''}`}
		>
			{children}
		</div>
	);
}

export const AssistMenu = ({
	contextMenu,
	handleClose,
	item,
	disabled,
	onClickAdjust,
	contextActions,
}) => {
	const date = item?.origin_day ? item.origin_day.split('-')?.reverse()?.join('/') : null;

	const onClickChecks = () => {
		if (isFunction(onClickAdjust)) {
			onClickAdjust();
		}
		handleClose();
	}

	return (
		<Menu
			open={contextMenu !== null}
			onClose={handleClose}
			className="custom-context-menu"
			anchorReference="anchorPosition"
			anchorPosition={
				contextMenu !== null
					? { top: contextMenu.mouseY, left: contextMenu.mouseX }
					: undefined
			}
		>
			<MenuItem className="context-selected-date">
				{date}
			</MenuItem>
			<MenuItem onClick={onClickChecks}>
				<ListItemIcon>
					<FontAwesomeIcon icon={faClock} />
				</ListItemIcon>
				{t('Ajustar checadas')}
			</MenuItem>
			{contextActions?.map(el => {
				const onClick = () => {
					const fn = el?.onClick;
					if (isFunction(fn)) {
						fn(item, el?.type);
					}
					handleClose();
				};
				return (
					<MenuItem key={uid()} onClick={onClick} disabled={el.class === 'disabled' || disabled}>
						<ListItemIcon className={el?.class ?? ''}>
							<FontAwesomeIcon icon={el.icon} />
						</ListItemIcon>
						{el.label}
					</MenuItem>
				)
			})}
		</Menu>
	);
}

WorkingDay.propTypes = {
	item: PropTypes.object,
	worker: PropTypes.object,
};

AssistMenu.propTypes = {
	contextMenu: PropTypes.object,
	handleClose: PropTypes.func,
	onClickAdjust: PropTypes.func,
	disabled: PropTypes.bool,
	item: PropTypes.object,
	contextActions: PropTypes.array,
};

CheckItem.propTypes = {
	period: PropTypes.object,
	checks: PropTypes.array,
	byCheck: PropTypes.bool,
	date: PropTypes.string,
	drag: PropTypes.bool,
	item: PropTypes.object,
	menu: PropTypes.bool,
	worker: PropTypes.object,
	refresh: PropTypes.func
};

DraggableCheck.propTypes = {
	item: PropTypes.object,
	byCheck: PropTypes.bool,
	period: PropTypes.object,
	i: PropTypes.number,
	date: PropTypes.string,
	drag: PropTypes.bool,
	menu: PropTypes.bool,
	refresh: PropTypes.func,
	parentItem: PropTypes.object,
};

WorkingDayContent.propTypes = {
	item: PropTypes.object,
	drag: PropTypes.bool,
	menu: PropTypes.bool,
	period: PropTypes.object,
	showReal: PropTypes.bool,
	refresh: PropTypes.func,
};

Droppable.propTypes = {
	item: PropTypes.object,
	drag: PropTypes.bool,
	i: PropTypes.number,
	children: PropTypes.any,
	date: PropTypes.string,
	byCheck: PropTypes.bool,
	parentItem: PropTypes.object,
};

CheckContent.propTypes = {
	item: PropTypes.object,
	byCheck: PropTypes.bool,
	i: PropTypes.number,
	date: PropTypes.string,
};