import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { debounce, isArray, isEmpty, isFunction, isNumber, isObject, isString, omit, size, toString } from "lodash";
import { copyObject, initialValues, initializeRole, mainItems, stepItemsBase, validateComponentShow, workerName } from "../utils/utilities";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ValidationSchemaEdit } from "../utils/validations";
import { useDispatch, useSelector } from "react-redux";
import { resolveError } from "../../../common/resolve-error";
import { showSuccessNotification } from "../../../../App/components/Notifications";
import { successCreated } from "../../../common/notification-messages";
import { isAbrhil } from "../../../common/auth";
import { usersApi } from "../../../services/administrator";
import { usePackages } from "../../../@hooks/usePackages";
import { t } from "i18next";
import { useRequestLoad } from "../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";
import { noSpaces } from "../../../@components/form/utilities";
import { workersApi } from "../../../services/worker";
import { lockedWindow, showNotificationWarning, unlockedWindow } from "../../../../store/actions";
import { hasValue } from "../../../common/GeneralUtilities";
import useServerController from "./ServerController";
import useGetLoginMode from "./useGetLoginMode";
import {
    attendancePayload,
    calculateUserType,
    deleteParams,
    groupsPayload,
    systemPayload,
    treatItem,
    validateParams,
} from "../utils/constants";
import { permsGate } from "../../../@components/navigation/utilities";

const paramsDelete = [
    'role_select',
    'roles_permissions',
    'branches_permissions',
    'check_all_cancel_mopers',
    'check_all_active_days',
    'full_name',
    'user_type',
    'structure_data',
    'initial_username',
    'initial_email',
    'existingEmail',
    'attendance',
];

const validate = { shouldValidate: true };

function useDialogController({ type, refresh, dataItem, onClose: close } = {}) {

    const { resetConfigs } = usePackages();
    const dispatch = useDispatch();

    const inAttendance = type === 'attendance';
    const canAssignRoles = permsGate('user-and-roles@users.assign-roles');
    const canWrite = permsGate(`${!inAttendance ? 'user-and-roles@users' : 'modules@users-list'}.write`);
    const { current } = useSelector(state => state.tenant);
    const structure = useSelector((state) => state?.configuration?.steps)?.slice(0, -3)?.map(el => el.step) ?? [];

    const [isEdit, setIsEdit] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [required, setRequired] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [initValues, setInitValues] = useState(initialValues(inAttendance, canAssignRoles));
    const [mainTreatedItems, setMainTreatedItems] = useState([]);
    const [finished, setFinished] = useState();
    const [success, setSuccess] = useState();
    const [disabled, setDisabled] = useState(false);
    const [direction, setDirection] = useState(1);
    const [formData, setFormData] = useState(null);
    const [stepItems, setStepItems] = useState([]);
    const [responseError, setResponseError] = useState();
    const [component, setComponent] = useState(null);
    const [items, setItems] = useState([]);
    const [viewName, setViewName] = useState(null);
    const [backupComponent, setBackupComponent] = useState(null);
    const [heightIncreased, setHeightIncreased] = useState(false);
    const [inAccessView, setInAccessView] = useState(false);
    const [inCustomItem, setInCustomItem] = useState(false);
    const [itemBack, setItemBack] = useState(null);
    const [currentInfo, setCurrentInfo] = useState(null);
    const [currentGroup, setCurrentGroup] = useState({});
    const [srcImage, setSrcImage] = useState(null);
    const [roleIndex, setRoleIndex] = useState(null);
    const [workerSelected, setWorkerSelected] = useState(null);
    const stepitemsBackup = useRef(null);
    const activeErrors = useRef({});
    const lastKey = useRef(null);

    const [fetchEmail, emailLoading] = useRequestLoad();
    const [fetchUsername, usernameLoading] = useRequestLoad();

    const loginMode = useGetLoginMode();
    const isSSO = loginMode === 2;

    const definedItems = mainItems(inAttendance, canAssignRoles, loginMode);
    const currentSchema = definedItems.find(el => el.step === activeStep)?.schema;

    const {
        control,
        reset,
        trigger,
        setError,
        setValue,
        getValues,
        clearErrors,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: initValues,
        ...((isEdit || currentSchema) ? {
            resolver: yupResolver(isEdit ? ValidationSchemaEdit(loginMode, canAssignRoles) : currentSchema)
        } : {})
    });

    const serverData = useServerController({ type, open, selected, setValue });

    const {
        loading,
        extraPerms,
        rolesData,
        tenants,
    } = serverData;

    const itemsDatasource = {
        0: null,
        1: null,
        2: !inAttendance ? rolesData : null,
        3: null,
        4: tenants,
    };

    useEffect(() => {
        resetMainMenu();
    }, [open, type])

    useEffect(() => {
        if (!open || !size(rolesData) || isEdit) return;
        initializeSystem();
    }, [open, rolesData])

    useEffect(() => {
        if (!size(dataItem)) return;
        handleOpen(dataItem);
    }, [dataItem])

    useEffect(() => {
        if (open) return;
        const newInit = {
            ...initValues,
            ...initialValues(inAttendance, canAssignRoles),
            tenants: [
                { id: current?.id, name: current?.name }
            ]
        };
        setInitValues(newInit);
        reset({ ...newInit });
    }, [current?.id, open])

    useEffect(() => {
        if (!component) return;
        setBackupComponent(component);
    }, [component])

    useEffect(() => {
        const steps = stepItemsBase(definedItems).map(el => {
            const show = validateComponentShow(el, itemsDatasource, inAttendance, selected, true);
            if (show) return { label: el.label, isValid: undefined, step: el.step, fields: el?.fields };
        }).filter(el => el !== undefined && isNumber(el?.step)).sort((a, b) => { return a.step - b.step });
        stepitemsBackup.current = steps;
        setStepItems(steps);
    }, [tenants, type])

    useEffect(() => {
        const options = definedItems.map(el => {
            const show = validateComponentShow(el, itemsDatasource, inAttendance, selected, false);
            if (show) return el;
        }).filter(el => el !== undefined).sort((a, b) => { return a.menu_order - b.menu_order; });
        setMainTreatedItems(options);
    }, [tenants, selected, type])

    useEffect(() => {
        if (!isEdit) {
            if (mainTreatedItems) {
                const stepItems = [...mainTreatedItems].filter(el => isNumber(el?.step) && stepitemsBackup.current?.find(li => li.label === el.name)).sort((a, b) => { return a.step - b.step });
                const items = stepItems.map(el => el.open);
                setItems(items);
            }
            return;
        }
        if (component && viewName) {
            const goMenu = mainTreatedItems.find(el => el.errorClass !== '');
            const actualItem = mainTreatedItems.find(el => el.name === viewName);
            if (actualItem?.errorClass === '') {
                if (goMenu) {
                    resetMenu();
                }
            }
        }
    }, [mainTreatedItems])

    useEffect(() => {
        if (responseError && open) {
            validateResponseError(responseError);
        }
    }, [responseError])

    const initializeSystem = () => {
        if (!open || !isAbrhil()) return;
        const systemRole = rolesData.find(el => el.config?.system_role);
        const systemRoleTreated = initializeRole(systemRole, getValues);;
        systemRoleTreated.saved = true;
        setValue('groups_perms', [systemRoleTreated]);
    }

    function resetMainMenu() {
        const newItems = mainTreatedItems.map(el => {
            el.errorClass = '';
            return el;
        });
        setMainTreatedItems(newItems);
    }

    function handleOpen(item) {
        setRequired(true);
        setIsEdit(false);
        setSelected(item);
        if (item) {
            setIsEdit(true);
            const cleanItem = treatItem(copyObject(item), getValues);
            const userData = { ...initValues, ...cleanItem };
            setWorkerSelected(userData.worker);
            setRequired(false);
            reset(userData);
        }
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        setActiveStep(0);
        setStepItems(stepitemsBackup.current);
        setSrcImage(null);
        setDirection(1);
        setItemBack(null);
        setBackupComponent(null);
        setSelected(null);
        setInCustomItem(false);
        reset(initialValues(inAttendance, canAssignRoles));
        clearErrors();
        resetMenu();
        if (isFunction(close)) {
            close();
        }
    }

    function validateResponseError(error) {
        let errorFiltered = isArray(error) ? error[0] : error;
        if (isObject(error)) {
            errorFiltered = Object.keys(error)[0];
        }
        if (isString(errorFiltered)) {
            errorFiltered = errorFiltered.toLowerCase();
        }
        const messageError = isObject(error) ? Object.values(error)[0] : errorFiltered;
        if (isString(messageError)) {
            if (messageError.length > 200) {
                return;
            }
        }
        mainTreatedItems.forEach(el => {
            el.fields.forEach(li => {
                if (errorFiltered.includes(li) || errorFiltered.includes(t(li).toLowerCase())) {
                    if (!isEdit) {
                        setActiveStep(el.step);
                        updateSteps(el.step, true, false);
                    }
                    if (li !== 'worker') {
                        setError(li, { type: 'custom', message: messageError });
                    } else {
                        setError('worker.key', { type: 'custom', message: messageError });
                        setError('worker.id', { type: 'custom', message: messageError });
                    }
                    if (isEdit) {
                        validateItems(mainTreatedItems, activeErrors.current);
                    }
                }
            })
        });
    }

    const onSubmit = async (data) => {
        onSubmitError({});
        if (!isEdit) {
            if (activeStep === items.length - 1) {
                setFinished(false);
                updateSteps(activeStep, true, true);
                setDisabled(true);
                const totalData = { ...formData, ...data };
                finalSubmit(totalData);
            } else {
                setFormData((prevData) => ({ ...prevData, ...data }));
                setDirection(-1);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            setFinished(false);
            setDisabled(true);
            finalSubmit(data);
        }
    }

    const onSubmitError = (errors) => {
        if (size(errors)) {
            setDisabled(true);
            setFinished(true);
            setSuccess(false);
        }
        if (isEdit) {
            validateItems(mainTreatedItems, errors);
        } else {
            validateStep(activeStep, errors);
        }
        if (size(errors)) {
            setTimeout(() => {
                setFinished(undefined);
                setSuccess(undefined);
                setDisabled(false);
            }, 600);
        }
    }

    function updateSteps(step, schemaErrors, value) {
        const valueSent = value !== undefined ? value : undefined;
        let valueSet = valueSent === null ? undefined : !schemaErrors;
        if (valueSent !== undefined) {
            valueSet = valueSent;
        }
        const currentSteps = stepItems.map((currentStep, index) => ({
            ...currentStep,
            isValid:
                index === step ? valueSet : currentStep.isValid
        }));
        setStepItems(currentSteps);
    }

    function buildPayload(request) {
        request.username = request.username === "" ? null : request.username;
        request.email = request.email === "" ? null : request.email;
        request.list_group = request.attendance;
        request.worker = request?.worker?.id;
        request.tenants = size(request?.tenants) ? request.tenants.map(el => el.id) : [current?.id];
        if (!isAbrhil()) { delete request.tenants; }
        request = validateParams(request, ['external_name', 'password', 'password2']);
        request.groups_perms = groupsPayload(request.groups_perms, inAttendance);
        request.type_user = calculateUserType(request, inAttendance);
        request = deleteParams(request, paramsDelete);
        request = attendancePayload(request, inAttendance);
        request = systemPayload(request, inAttendance);
        return request;
    }

    function validateItems(items, errors) {
        const newItems = items.map(el => {
            const noValid = validateStep(el.step, errors);
            el.errorClass = noValid ? 'error-main-item' : '';
            return el;
        });
        setMainTreatedItems(newItems);
    }

    async function generalRequest(body) {
        const api = isEdit ? usersApi.patch : usersApi.post;
        const params = isEdit ? [body.id, body] : [body];
        try {
            await api(...params);
            refreshConfigs(body);
            return true;
        } catch (error) {
            resolveError(error);
            setDisabled(false);
            const filteredError = error?.response?.data?.detail ?? error?.response?.data;
            setResponseError(filteredError);
            return false;
        }
    }

    const refreshConfigs = (newConfigs) => {
        if (inAttendance) return;
        const updatedConfigs = omit(newConfigs?.extra_perms, 'id');
        resetConfigs(updatedConfigs);
    }

    const finalSubmit = async (data) => {
        setResponseError(null);
        const dataSend = copyObject(data);
        const body = buildPayload(dataSend);
        const response = await generalRequest(body);
        setDisabled(false);
        setSuccess(response);
        setFinished(true);
        if (response) {
            setTimeout(() => {
                if (isFunction(refresh)) {
                    refresh();
                }
                setDisabled(false);
                if (component && isEdit) {
                    resetMenu();
                } else {
                    handleClose();
                }
                if (!isEdit) {
                    showSuccessNotification(successCreated());
                }
            }, 600);
        }
        setTimeout(() => {
            setFinished(undefined);
            setSuccess(undefined);
        }, 1000);
    }

    function validateStep(step, errors) {
        const item = stepItems.find(el => el.step === step);
        if (!item) return;
        const totalData = item?.fields;
        const builtData = { value_items: totalData, datasource: itemsDatasource[item?.step] };
        return validateValues(builtData, step, errors);
    }

    function validateValues({ value_items, datasource }, step, errors) {
        value_items = value_items.filter(el => typeof el === 'string');
        let schemaErrors = false;
        value_items.forEach(el => {
            if (errors[el]) {
                schemaErrors = true;
            }
        });
        if (schemaErrors) {
            let validDataSource = datasource !== null ? size(datasource) : null;
            if ((validDataSource > 0 && validDataSource !== null) || validDataSource === null) {
                schemaErrors = true;
            } else {
                value_items.forEach(el => clearErrors(el));
                schemaErrors = false;
            }
        }
        updateSteps(step, schemaErrors);
        return schemaErrors;
    }

    function resetMenu() {
        setComponent(null);
        setViewName(null);
    }

    const handleBack = () => {
        updateSteps(activeStep, true, null);
        setDirection(1);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleClick = (item) => {
        setCurrentInfo(null);
        setCurrentGroup(null);
        setViewName(item?.name);
        setComponent(item?.open);
        if (!item) {
            validateItems(mainTreatedItems, activeErrors.current);
        }
    }

    const validateEmail = useCallback(async (email) => {
        if (email === '' || !email.includes('@')) return;
        if (activeErrors.current?.email) return;
        fetchEmail({
            api: usersApi.validateEmail({ email }),
            onFailed: () => null,
            callback: ({ valid } = {}) => {
                let emailResponse = !valid && getValues('initial_email') !== email ? email : undefined;
                setValue('existingEmail', emailResponse, validate);
                trigger('email');
            }
        });
    }, [activeErrors.current]);

    const validateUser = useCallback(async (username) => {
        if (activeErrors.current?.username || username === '') return;
        const usernameFilter = { username, tree: true };
        fetchUsername({
            api: usersApi.get(usernameFilter),
            callback: ([found] = []) => {
                let usernameResp = found?.username;
                if (getValues('initial_username') === usernameResp) {
                    usernameResp = undefined;
                }
                setValue('existingUser', usernameResp, validate);
                trigger('username');
            }
        });
    }, [activeErrors.current]);

    const debounceEmailCheck = useRef(debounce(validateEmail, 100)).current;
    const debounceUsernameCheck = useRef(debounce(validateUser, 200)).current;

    const onEmailChange = useCallback((e) => {
        debounceEmailCheck(e.value);
        return noSpaces(e);
    }, [activeErrors.current]);

    const onUsernameChange = useCallback((e) => {
        debounceUsernameCheck(e.value);
        return noSpaces(e);
    }, [activeErrors.current]);

    const onNew = () => handleOpen();

    const onClose = () => handleClose();

    function saveWorker(worker) {
        setValue(`worker.key`, worker.key, validate);
        setValue(`worker.name`, worker.name, validate);
        setValue(`worker.first_surname`, worker.first_surname, validate);
        setValue(`worker.second_surname`, worker.second_surname, validate);
        setValue(`worker.id`, worker.id, validate);
        setValue(`external_name`, "");
        const fullName = workerName(worker);
        setValue(`worker.full_name`, fullName, validate);
        setSrcImage(isEmpty(worker.photo) ? "" : worker.photo?.full_size);
    }

    const handleSelectWorker = (worker) => {
        saveWorker(worker);
        setWorkerSelected(worker);
    }

    const getEmployee = async (key) => {
        if (key === lastKey) return;
        if (key === '') return dontSaveWorker(false);
        lastKey.current = key;
        dispatch(lockedWindow());
        try {
            const params = {
                tenant: current?.id,
                worker: key,
                select: true,
                tree: true,
                all: true,
                with_permission_user: false,
            };
            const response = await workersApi.get(params);
            if (response.length > 0) {
                const worker = response[0];
                handleSelectWorker(worker);
            } else {
                setValue('worker', null);
                dispatch(showNotificationWarning({ message: `No se encontraron coincidencias para la clave ${key}.` }));
            }
        }
        catch (error) {
            resolveError(error);
        }
        finally {
            dispatch(unlockedWindow());
        }
    }

    const debounceWorker = useRef(debounce(getEmployee, 1000)).current;

    const onWorkerChange = (event) => {
        debounceWorker(event.value);
        return noSpaces(event);
    }

    const handleKeyPress = ({ charCode, key, target, type }) => {
        if ((charCode === 13 || (key ?? "").toString().toLowerCase() === "enter") || (type === "blur" && toString(workerSelected?.key) !== target?.value)) {
            if (hasValue(target?.value)) {
                getEmployee(target.value);
            }
        }
    };

    function handleInternalUser(e) {
        dontSaveWorker();
        return e;
    }

    const passwordValidation = e => {
        e = noSpaces(e);
        return e;
    }

    function dontSaveWorker(clear = true) {
        setValue(`worker.key`, "");
        setValue(`worker.name`, "");
        setValue(`worker.first_surname`, "");
        setValue(`worker.second_surname`, "");
        setValue(`worker.full_name`, "");
        setValue(`worker.id`, null);
        setValue(`external_name`, "");
        setValue(`worker.photo`, {});
        setSrcImage(null);
        setWorkerSelected(null);
        clear && clearErrors(['worker']);
    }

    const validateRoleAssign = () => {
        if (!canAssignRoles) {
            dispatch(showNotificationWarning({
                message: 'Sin permisos de asignar roles',
                description: 'Este permiso es necesario para realizar esta acción'
            }));
        }
        return canAssignRoles;
    }

    return {
        control,
        open,
        onNew,
        items,
        stepItems,
        isEdit,
        onClose,
        errors,
        rolesData,
        structure,
        extraPerms,
        required,
        selected,
        finished,
        disabled,
        direction,
        component,
        activeStep,
        canAssignRoles,
        canWrite,
        viewName,
        success,
        tenants,
        current,
        loading,
        reset,
        trigger,
        setError,
        setValue,
        getValues,
        onSubmit,
        handleBack,
        handleClose,
        handleSubmit,
        onSubmitError,
        setCurrentInfo,
        setCurrentGroup,
        validateRoleAssign,
        srcImage,
        currentGroup,
        currentInfo,
        handleClick,
        emailLoading,
        inCustomItem,
        itemBack,
        setItemBack,
        setInCustomItem,
        usernameLoading,
        backupComponent,
        inAccessView,
        roleIndex,
        setRoleIndex,
        setInAccessView,
        heightIncreased,
        mainTreatedItems,
        setHeightIncreased,
        onEmailChange,
        onUsernameChange,
        passwordValidation,
        handleSelectWorker,
        onWorkerChange,
        handleKeyPress,
        workerSelected,
        handleInternalUser,
        inAttendance,
        loginMode,
        isSSO,
    };
};

const DialogContext = createContext({});

export const useDialogContext = () => useContext(DialogContext);

export default function DialogController({
    children,
    ...others
}) {
    const dialogValues = useDialogController(others);

    return (
        <DialogContext.Provider value={dialogValues}>
            {children}
        </DialogContext.Provider>
    );
};

DialogController.propTypes = {
    children: PropTypes.any,
}