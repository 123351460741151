import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { workgroupsApi } from "../../../../../../services/administrator";
import { resolveError } from "../../../../../../common/resolve-error";
import { useDispatch } from "react-redux";
import { isArray, isFunction, isNull, isUndefined } from "lodash";
import { showNotificationWarning } from "../../../../../../../store/actions";
import { useRequestLoad } from "./useResolveIncidence";
import { workersApi } from "../../../../../../services/worker";
import { hasValue } from "../../../../../../common/GeneralUtilities";
import { store } from "../../../../../../../store";
import { t } from "i18next";
import {
    getMoperPerm,
    isPeriodLocked,
    validateCreateCheck,
    validateDayCalculate,
    validateRequestOvertime,
    validateRequestOvPayment
} from "../utils/utilities";

const includeFields = [
    'workgroup',
    'enterprise_structure_organization.settings.*'
].join(',');

const fieldsReq = [
    'id',
    'key',
    'workgroup',
    'enterprise_structure_organization.settings.overtime_config',
].join(',');

const mopersKeywords = [
    'mopers',
    'attendance'
];

const verifyMopersFields = [
    'mopers.flow_signature',
    'mopers.is_active',
    'mopers.moper_catalog',
    'mopers.id',
].join(',');

export const isFromMopers = () => {
    const focused = store.getState().navigate?.focused_window;
    const currentElement = store.getState().navigate?.[focused];
    const moduleId = currentElement?.id;
    const modulePath = currentElement?.path;
    const from_mopers = mopersKeywords.some(el => moduleId?.includes(el) || modulePath?.includes(el));
    return from_mopers ? { from_mopers } : {};
}

function useModal({
    worker: propWorker,
    moperPermsData,
    currentPeriod,
    ...others
}) {
    const dispatch = useDispatch();
    const [verifications, setVerifications] = useState({});
    const [selected, setSelected] = useState({});
    const [worker, setWorker] = useState(null);
    const [fetch, loading] = useRequestLoad();
    const [workerEndContract, setWorkerEndContract] = useState(null);

    const periodLocked = isPeriodLocked(currentPeriod);

    // START MODAL STATES
    const [openTimePayment, setOpenTimePayment] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [openDayCalculate, setOpenDayCalculate] = useState(false);
    const [openResolve, setOpenResolve] = useState(false);
    const [openApplyRest, setOpenApplyRest] = useState(false);
    const [openAssist, setOpenAssist] = useState(false);
    const [openAbsen, setOpenAbsen] = useState(false);
    // END MODAL STATES

    const modalValues = [
        { value: openTimePayment, setState: setOpenTimePayment, moper: 'txt', validation: validateRequestOvertime, label: t('overtime') },
        { value: openPayment, setState: setOpenPayment, moper: 'txt', validation: validateRequestOvPayment, label: t('overtime') },
        { value: openDayCalculate, setState: setOpenDayCalculate, validation: () => validateDayCalculate(worker) },
        { value: openResolve, setState: setOpenResolve },
        { value: openApplyRest, setState: setOpenApplyRest },
        { value: openAssist, setState: setOpenAssist, moper: 'ajuste_asist', validation: (item) => validateCreateCheck(item, isMyWorkgroup, createCheckPerm), label: t('assist-adjustment') },
        { value: openAbsen, setState: setOpenAbsen, moper: ['vacaciones', 'ausen_sin_goce', 'ausen_con_goce'], label: t('absenteeisms') },
    ];

    const hasAssistActive = verifications?.ajuste_asist ?? !isNull(verifications);
    const hasOvertimeActive = verifications?.txt ?? !isNull(verifications);

    // USER PERMS
    const isMyWorkgroup = worker?.workgroup?.is_my_workgroup ?? true;
    const createCheckPerm = getMoperPerm('create_check');
    const deleteCheckPerm = getMoperPerm('delete_check');

    useEffect(() => {
        handleInitialValues();
    }, [propWorker])

    useEffect(() => {
        setWorkerEndContract(worker?.current_status?.end_contract || worker?.current_status?.active);
    }, [worker])

    const handleInitialValues = () => {
        const workerConfig = propWorker?.enterprise_structure_organization?.settings?.overtime_config;
        if (hasValue(workerConfig)) {
            setWorker(propWorker);
            initialVerifyMoper(propWorker);
        } else {
            getWorker(propWorker?.id ?? propWorker);
        }
    }

    const initialVerifyMoper = (worker) => {
        if (hasValue(moperPermsData)) {
            setVerifications(verifyMopers(moperPermsData));
            return;
        }
        getWorkgroupMopers(worker);
    }

    const getWorker = (id) => {
        if (!id) return;
        fetch({
            api: workersApi.getOne(id,
                {
                    include: includeFields,
                    fields: fieldsReq,
                    ...isFromMopers()
                }),
            callback: (resp) => {
                initialVerifyMoper(resp);
                setWorker(resp);
            },
        });
    }

    const verifyMopers = (mopers) => mopers?.length ? mopers?.reduce((o, item) => ({
        ...o,
        [item.moper_catalog.key]: item.is_active && item.flow_signature?.length > 0
    }), {}) : null;

    const getWorkgroupMopers = async (worker) => {
        try {
            const response = await workgroupsApi.getOne(
                worker?.workgroup?.id,
                {
                    include: 'mopers.*',
                    fields: verifyMopersFields
                });
            setVerifications(verifyMopers(response.mopers));
        } catch (error) {
            resolveError(error);
        }
    }

    const getIsActive = (value) => {
        if (isArray(value)) {
            return value.some(el => verifications?.[el]);
        }
        return verifications?.[value];
    }

    function handleClose(key, reset) {
        const { setState } = modalValues[key];
        setState(false);
        setSelected({});
        if (isFunction(reset)) {
            reset({});
        }
    };

    async function handleOpen(key, item) {
        const { setState, moper, label, validation } = modalValues[key];
        if (!generalValidation(item)) return;
        if (!getIsActive(moper) && !isUndefined(moper)) {
            dispatch(showNotificationWarning({
                maxWidth: 'sm',
                message: `Moper de ${label} no configurado en este grupo de trabajo`,
                description: `El moper que intenta usar no está activado o configurado en ${worker?.workgroup?.name}`
            }));
            return;
        }
        if (isFunction(validation)) {
            if (!validation(item, key)) return;
        }
        setSelected(item);
        setState(true);
    };

    const generalValidation = item => {
        const body = { message: null, description: null, maxWidth: 'sm' };
        if (workerEndContract < item.origin_day || workerEndContract === false) {
            body.message = 'Contrato el colaborador finalizado';
            body.description = 'El contrato del colaborador está finalizado en esta fecha';
        }
        if (periodLocked) {
            body.message = 'El periodo seleccionado está bloqueado';
            body.description = 'Cambie de periodo en los filtros de la cabecera o reaperture el periodo';
        }
        if (!isMyWorkgroup) {
            body.message = 'No tiene asignado este grupo de trabajo';
            body.description = 'Solicite la asignación de este grupo a su administrador';
        }
        if (body.message || body.description) {
            dispatch(showNotificationWarning(body));
            return;
        }
        return true;
    }

    return {
        ...others,
        worker,
        loading,
        selected,
        periodLocked,
        modalValues,
        verifications,
        isMyWorkgroup,
        currentPeriod,
        deleteCheckPerm,
        createCheckPerm,
        hasAssistActive,
        workerEndContract,
        hasOvertimeActive,
        generalValidation,
        handleClose,
        handleOpen,
    };
};

const ModalsContext = createContext({});

export const useModalsContext = () => useContext(ModalsContext);

export default function ModalsController({ worker, children, ...others }) {
    const modalValues = useModal({ worker, ...others });

    return (
        <ModalsContext.Provider value={modalValues}>
            {children}
        </ModalsContext.Provider>
    );
};

ModalsController.propTypes = {
    worker: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.object
    ]),
    children: PropTypes.any
};