import PropTypes from "prop-types";
import React from 'react';
import { omit } from 'lodash';
import MuiIB from '@mui/material/IconButton';
import { CustomTooltip } from '../Templates/cells';
import IconUse from '../../../core/common/IconUse';
import { permsGate } from "../../../core/@components/navigation/utilities";

/**
 * Botón general del sistema para aplicar los icon buttons
 * @param {object} params
 * @param {function} params.onClick - función acción del botón
 * @param {node} params.icon - tiene que ser un icon de fontawesome
 * @param {node} params.altIcon - tiene que ser un icon de fontawesome
 * @param {object} params.buttonProps - objeto con propiedades del botón, son todas las que puede tener el icon button de material
 * @param {object} params.iconProps - objeto con propiedades del icono, son toda las que puede contener el Elemento de fontAwesome
 * @param {?string} params.title - El titulo a mostrar al posicionarse sobre el botón
 * @param {?"contained"|"text"|"flat"|"create"|"outlined"} params.design - Indica el estilo del botón que se va a usar, por default outlined
 * @returns {ButtonNode}
 */
function IconButton({
	onClick,
	icon,
	altIcon,
	color,
	perms,
	buttonProps = {},
	iconProps = {},
	title = "",
	disabled = false,
	design = "flat",
}) {

	if (perms && !permsGate(perms)) return null;

	return (
		<CustomTooltip title={disabled || buttonProps?.disabled ? (buttonProps?.title ?? "") : title}>
			<div style={{ display: "inline-flex" }}>
				<MuiIB
					size={"small"}
					disabled={disabled}
					color={color}
					{...(omit(buttonProps, ["title"]) || {})}
					className={`design-${design} ${buttonProps?.className ?? ""} actionButton ${altIcon ? "hasAlt" : ""}`}
					onClick={onClick}
				>
					{altIcon && <IconUse {...iconProps} className={`${iconProps?.className ?? ""} alt`} icon={altIcon} />}
					<IconUse {...iconProps} className={`${iconProps?.className ?? ""} normal`} icon={icon} />
				</MuiIB>
			</div>
		</CustomTooltip>
	);
}

IconButton.propTypes = {
	altIcon: PropTypes.any,
	buttonProps: PropTypes.object,
	color: PropTypes.any,
	design: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.any,
	iconProps: PropTypes.object,
	onClick: PropTypes.func,
	title: PropTypes.string
};

export default IconButton;
