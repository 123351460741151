import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm as useReactForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { size } from "lodash";
import FileSaver from "file-saver";
import { format } from "date-fns";
import { hasErrorTetant } from "../../../../../../common/validate-tenant";
import { unlockedWindow } from "../../../../../../../store/actions";
import { initialValues, structureClearValue, structureKeysByReport, validationSchema } from "../utils/constants";
import { StructureFunctions, structureSteps } from "../../../../../../common/StructureUtilities";
import { payrollConceptsApi } from "../../../../../../services/payroll";
import { employerRegistrationAPI, getJobPositionsTypeApi, managerAPI } from "../../../../../../services/enterprise-structure";
import { socketsApi } from "../../../../../../services/socket-report";
import { payrollStatusesApi } from "../../../../../../services/RRHH";
import { payrollPeriodDataConstructor } from '../../../../../../../App/components/Select/PayrollPeriod/searchUtilities';
import { isLocked } from '../../../../../../../App/components/Select/PayrollPeriod/itemUtilities';
import { getPayrollTypes } from '../../../../../../../App/components/Select/PayrollType';
import { valueOrOption } from '../../../../../../common/GeneralUtilities';
import { parseRequest } from "../../../../../../common/parse-request";
import { implementService } from "../../../../../../services/implemet-service";
import { MAP_REQUEST_PAYROLL } from "../utils/download";
import { reportsApi } from "../../../../../../services/reports";
import TriggerNotificationSocket from "../../../../../../common/trigger-notification-socket";
import { resolveError } from "../../../../../../common/resolve-error";

const INIT_RANGE = { start_admin_court_date: null, end_admin_court_date: null }

const report_concepts = {
  "payroll-report-loans": "loans",
  "payroll-report-concept": "all",
}

const usePayrollForm = ({
  handleCloseForm,
  // handleOpenPreviewForm,
  reportType,
  formProps,
  setReportType,
}) => {

  const dispatch = useDispatch();

  const [payrollTypes, setPayrollTypes] = useState([]);
  const [employerRegistrationList, setEmployerRegistrationList] = useState([]);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [workerType, setWorkerType] = useState([]);
  const [payrollConcepts, setPayrollConcepts] = useState([]);
  const [workersSelected, setWorkersSelected] = useState([]);
  const [typeManager, setTypeManager] = useState([]);
  const [payrollPeriods, setPayrollPeriods] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [payrollLoansConcepts, setPayrollLoansConcepts] = useState([]);
  const [dateRange, setDateRange] = useState(INIT_RANGE)

  let structure = useSelector((state) => state.configuration.steps) ?? [];

  if (reportType in structureKeysByReport) {
    const keysToInclude = structureKeysByReport[reportType].structure_keys;
    structure = structure.filter(object => keysToInclude.includes(object.key));
  }

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    clearErrors,
    trigger,
    getFieldState,
    formState: { errors }
  } = useReactForm({
    mode: "onChange",
    resolver: yupResolver(validationSchema[reportType]),
    defaultValues: initialValues(structure, {}, reportType),
  });

  const initialYear = useWatch({ control, name: "initial_year" });
  const selectedPeriod = useWatch({ control, name: "selected_period" });
  const typeFile = useWatch({ control, name: "type_file" });
  const breakDownBy = useWatch({ control, name: "break_down_by" });
  const byBreakDownBy = breakDownBy == "type_summary" ? "summary_report" : "general_report"
  const generalReportType = typeFile == 1 ? "excel_report" : byBreakDownBy;
  const organizatedStructure = structureSteps(structure);

  const {
    resetBegins,
    getExtras,
    handleSelect,
    getSelected,
  } = StructureFunctions({
    structure: organizatedStructure,
    setValue,
    getValues,
    clearValue: structureClearValue,
  });

  useEffect(() => {
    if (!formProps.open) return;
    getPayrollsTypes();
    getEmployerRegistration();
    getPaymentTypes();
    getManagerNoManager();
    getJobPositionsTypes();
    getPayrollConcepts(report_concepts[formProps?.type]);
    // eslint-disable-next-line
  }, [formProps?.open]);

  useEffect(() => {
    if (!formProps.open) return;
    setWorkersSelected(getSelected("worker"));
    //eslint-disable-next-line
  }, []);

  const onWorkerSelect = (selection) => {
    setWorkersSelected([selection]);
    handleSelect("worker", [selection]);
  };

  const handleSelected = (selection, field, index) => {
    const value = selection;
    resetBegins(index, field, value);
    setValue(field, value);
  };

  const handleChange = (e, field, index) => {
    if (!e.value) {
      e.value = "*"
      e.target.value = "*"
    }
    const value = e.target?.value || "*";
    handleSelect(field, [value]);
    resetBegins(index, field, value);
    return e;
  };

  useEffect(() => {
    if (!formProps.open) return;
    setReportType(`${formProps.type.replace(/-(.)/g, (_, match) => match.toUpperCase())}Constant`);
    const fetchPayrollPeriods = async () => {
      await getPayrollPeriods();
    };
    fetchPayrollPeriods();
  }, [formProps?.open, initialYear]);

  useEffect(() => {
    if (!formProps.open) return;
    onChangeYear();
  }, [payrollPeriods]);

  const onChangePayroll = ({ selected, ...others }) => {
    setValue("selected_period", selected);
    setValue("payroll_is_blocked", isLocked(selected));
    setSelectedItem(selected);
    onChangeYear(selected);
    return others;
  };

  const onChangeYear = (selectedItemPeriod = selectedPeriod) => {
    const foundIndex = payrollPeriods.findIndex(item => item?.id === selectedItemPeriod?.id);
    if (foundIndex !== -1 && foundIndex < payrollPeriods.length - 1) {
      const nextPeriod = payrollPeriods[foundIndex + 1];
      setValue("end_year", nextPeriod.current_year);
      setValue("end_month", nextPeriod.current_month);
      setValue("end_payroll_type", nextPeriod.payroll_type);
      setValue("end_computed_type", nextPeriod.computed_type);
      setValue("end_period_value", nextPeriod.id);
    }
  }

  const getPayrollsTypes = async () => {
    if (hasErrorTetant()) {
      return;
    }

    const { data, predetermined } = await getPayrollTypes();
    if (size(data)) {
      const initial = valueOrOption(predetermined, data[0]?.id);
      setValue("payroll_type", initial);
      setValue("initial_payroll_type", initial);
    }

    setPayrollTypes(data);
  };

  const getEmployerRegistration = async () => {
    if (hasErrorTetant()) {
      return;
    }

    let params = {
      tree: "",
      select: true,
      distinct: "",
    };
    await implementService(employerRegistrationAPI.get(params), (response) => {
      setEmployerRegistrationList(response);
    }).finally(() => dispatch(unlockedWindow()));
  };

  const getJobPositionsTypes = async () => {
    if (hasErrorTetant()) {
      return;
    }

    let params = {
      tree: "",
      select: true,
      distinct: "",
    };

    await implementService(getJobPositionsTypeApi.get(params), (response) => {
      setWorkerType(response);
    }).finally(() => dispatch(unlockedWindow()));
  };


  const getPaymentTypes = async () => {
    if (hasErrorTetant()) {
      return;
    }
    await implementService(payrollStatusesApi.get({ tree: "", }), (response) => {
      setPaymentTypes(response);
    }).finally(() => dispatch(unlockedWindow()));
  };

  const getManagerNoManager = async () => {
    if (hasErrorTetant()) {
      return;
    }

    await implementService(managerAPI.get({
      tree: "",
      select: "",
    }), (response) => {
      setTypeManager(response);
    }).finally(() => dispatch(unlockedWindow()));
  };

  const getPayrollPeriods = async (newYear = initialYear) => {
    if (hasErrorTetant()) {
      return;
    }

    const { data } = await payrollPeriodDataConstructor({
      includeExtras: true,
      current_year: newYear
    });
    setPayrollPeriods(data);
  };

  const getPayrollConcepts = async (type) => {
    if (!type) return
    if (hasErrorTetant()) {
      return;
    }
    const request = parseRequest(
      {},
      {
        tree: "",
        select: true,
        frequency: type === "loans" ? "E" : undefined,
        concept_type: type === "loans" ? 2 : undefined
      }
    );
    await implementService(payrollConceptsApi.get(request), (response) => {
      type === "loans" ? setPayrollLoansConcepts(response) : setPayrollConcepts(response);
      const init = {}
      response.forEach(f => {
        init[`_${type === "loans" ? f.key : f.id}`] = false
      })
      setValue("concept", init)
    }).finally(() => dispatch(unlockedWindow()));
  };

  //Download
  async function handleDownload(values) {
    const employerRegistration = employerRegistrationList
      .filter(object => values?.employer_registration?.includes(object.key))
      .map(object => object.id);

    values.employer_registration = values.employer_registration === '*' ?
      values.employer_registration : employerRegistration?.toString();

    const key = values.key
    const url = values.backend_path
    const request = MAP_REQUEST_PAYROLL(key, values);

    try {
      const response = await reportsApi.download(url, request, true);
      const socketName = url.replace(/-/g, '_');
      TriggerNotificationSocket(socketName, response.task_id);
      handleCloseForm()
    } catch (error) {
      resolveError(error);
    }
    //Se comenta de momento verificar si a alguien le sirve está funcionalidad
    // dispatch(lockedWindow());
    // const url = getValues('backend_path');

    // let request = startingRequest(values);

    // if (url === 'payroll-report') {
    //   request = generalPayrollValidation(request, values);
    // } else if (url === 'payroll-report-taxes') {
    //   request = {
    //     ...request,
    //     period: values.key_period,
    //   };
    // } else if (url === 'payroll-report-excel-general' && values.anex_info) {
    //   request = {
    //     ...request,
    //     data_worker: values.anex_info.includes(1),
    //     is_data_structure_extra: values.anex_info.includes(2),
    //     is_data_benefits: values.anex_info.includes(3),
    //     is_data_movement: values.anex_info.includes(4),
    //     is_data_absenteeism: values.anex_info.includes(5),
    //     is_exempt_grav: values.anex_info.includes(6),
    //   };
    // } else if (url === 'payroll-report-capture-not-applied') {
    //   request = {
    //     ...request,
    //     type_cut: values.type_cut_applied ? 2 : 1
    //   };
    // }

    // try {
    //   const response = await reportsApi.download(url, request, request.is_socket);
    //   if (request.is_socket) {
    //     const socketName = url.replace(/-/g, '_');
    //     TriggerNotificationSocket(socketName, response.task_id);
    //     setWorkersSelected([]);
    //     handleCloseForm();
    //   } else {
    //     setValue("pdf_file", response);
    //     handleOpenPreviewForm({ type: 'preview-report', item: { name: "Nómina general" } });
    //   }
    // } catch (error) {
    //   resolveError(error);
    // } finally {
    //   dispatch(unlockedWindow());
    // }
  }

  // const generalPayrollValidation = (request, values) => {
  //   request = {
  //     ...request,
  //     add_settlement: values?.general_payroll_options?.includes(1),
  //     add_payroll_not_fixed: values?.general_payroll_options?.includes(2),
  //     add_payroll_zero_AD: values?.general_payroll_options?.includes(3),
  //     divide_51ISPT_MIP3: values?.general_payroll_options?.includes(4),
  //   };

  //   if (values.view === 'general_view') {
  //     if (values?.broken_down_by === ReportBrokenDown.MONTH) {
  //       request = omit(request, ['period']);
  //     } else if (values?.broken_down_by === ReportBrokenDown.YEAR) {
  //       request = omit(request, ['period', 'month']);
  //     }
  //   } else {
  //     request = omit(request, ['branch', 'area', 'department', 'employer_registration']);
  //   }
  //   return request;
  // };

  const formatDate = (date) => {
    return format(date, 'yyyy-MM-dd');
  };

  // const startingRequest = (values) => {
  //   const employerRegistration = employerRegistrationList
  //     .filter(object => values.employer_registration.includes(object.key))
  //     .map(object => object.id);

  //   const cleanData = omitBy({
  //     ...values,
  //     employer_registration: values.employer_registration === '*' ? values.employer_registration : employerRegistration?.toString(),

  //     area: values?.area === '*' ? values?.area : values?.area?.toString(),

  //     branch: values?.branch === '*' ? values?.branch : values?.branch?.toString(),

  //     department: values?.department === '*' ? values?.department : values?.department?.toString(),

  //     worker: values?.worker?.toString(),

  //     payment_types: values?.payment_types?.toString(),
  //     type_manager: values?.type_manager?.toString(),
  //     concept: values?.concept?.toString(),
  //     worker_type: values?.worker_type?.toString(),
  //     group_by: values?.group_by?.toString(),
  //     order_by: values?.order_by?.toString(),
  //     is_year_report: values?.view === 'by_year_view',
  //     is_month_range: values?.type_cut === 2,
  //   }, (value, key) => (
  //     isNil(value) || key === 'undefined' || key === 'backend_path' || key === 'view' || (typeof value === 'string' && value.trim() === '')
  //   ));

  //   return cleanData;
  // };

  const onCancel = () => {
    setWorkersSelected([]);
  }

  function onChangePeriod(e) {  
    const start_admin_court_date = e?.selected?.start_admin_court_date
    const end_admin_court_date = e?.selected?.end_admin_court_date
    const type_report = getValues('type_report')
    setValue("start_date", start_admin_court_date)
    setValue("end_date", end_admin_court_date)
    setDateRange(
      {
        start_admin_court_date,
        end_admin_court_date
      }
    )
    if(reportType == "payrollReportBonusExcelConstant" && type_report == 0){
      setValue("month", e?.selected?.current_month )
    }
    return e
  }

  return {
    control,
    setValue,
    getValues,
    handleSubmit,
    onCancel,
    reset,
    payrollTypes,
    paymentTypes,
    workerType,
    employerRegistrationList,
    workersSelected,
    setWorkersSelected,
    getExtras,
    onWorkerSelect,
    handleSelect,
    handleChange,
    handleDownload,
    handleSelected,
    organizatedStructure,
    getSelected,
    structure,
    typeManager,
    clearErrors,
    trigger,
    getFieldState,
    onChangePayroll,
    payrollPeriods,
    selectedItem,
    formatDate,
    payrollConcepts,
    payrollLoansConcepts,
    generalReportType,
    ...formProps,
    errors,
    onChangePeriod,
    dateRange
  };
};

export const downloadReport = async (taskID) => {
  try {
    let response = await socketsApi.downloadReport(taskID);
    FileSaver.saveAs(
      response,
      `Reporte_${format(
        new Date(),
        "yyyy-MM-dd hh:mm:ss"
      )}`
    );
  } catch (error) {
    return error;
  }
};


export const PayrollContext = React.createContext({});

export default usePayrollForm;