import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resolveError } from '../../../common/resolve-error';
import {
    getJobPositionsTypeApi,
} from '../../../services/enterprise-structure';
import { attendanceListAPI } from '../../../services/RRHH';
import { size, toString } from 'lodash';
import { currentTenant } from '../../../common/validate-tenant';
import { useDispatch, useSelector } from 'react-redux';
import TriggerNotificationSocket from '../../../common/trigger-notification-socket';
import { socketsApi } from '../../../services/socket-report';
import FileSaver from "file-saver";
import { format, differenceInDays } from "date-fns";
import { lockedWindow, showNotificationWarning, unlockedWindow } from '../../../../store/actions';
import { warningCustomize } from '../../../common/notification-messages';
import { useTranslation } from "react-i18next";
import useAttendance from './useController';
import { getCatalog } from '../ConstantAndUtilities/utilities';
import { printValidationSchema, printInitValues } from '../ConstantAndUtilities/constants';
import { useRequestLoad } from '../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence';
import { getUserWorkgroupsAPI } from '../../../services/administrator';

function usePrintList() {
    const structure = useSelector((state) => state?.configuration?.steps) ?? [];
    const catalogsConfig = useSelector((state) => state?.configuration?.human_resource_package);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const catalogRef = useRef();
    const [open, setOpen] = useState(false);
    const [workerTypes, setWorkerTypes] = useState([]);
    const [userWorkgroups, setUserWorkgroups] = useState([]);
    const { filter } = useAttendance();
    const catalog = getCatalog(catalogsConfig, catalogRef);

    const [fetch, loading] = useRequestLoad();

    const {
        control,
        setValue,
        getValues,
        handleSubmit,
        reset,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(printValidationSchema),
        defaultValues: printInitValues({
            catalog,
            workerTypes,
            filter,
        }),
        mode: "onChange",
    });

    const employer_selection = useWatch({ control, name: 'structure.employer_registration' });
    const branch_selection = useWatch({ control, name: 'structure.branch' });

    useEffect(() => {
        if (!open) return;
        getWorkerTypes();
    }, [open])

    useEffect(() => {
        if (!open) return;
        getUserWorkgroups();
    }, [open, employer_selection, branch_selection])

    const getUserWorkgroups = () => {
        fetch({
            api: getUserWorkgroupsAPI.get({
                tree: true,
            }),
            callback: setUserWorkgroups,
        });
    }

    function handleClose() {
        setOpen(false);
        reset(printInitValues({
            catalog,
            workerTypes,
            filter,
        }));
    }

    function handleOpen() {
        const initDate = filter?.initial_date;
        const endDate = filter?.end_date;
        if (!initDate || !endDate) {
            let missing = !initDate ? 'initial_date' : 'end_date';
            missing = !initDate && !endDate ? 'both' : '';
            const datesMessage = `${t('initial_date')} y ${t('end_date')}`;
            dispatch(showNotificationWarning(warningCustomize(
                t('warning-general-title'),
                `El parámetro de ${missing === 'both' ? datesMessage : t(missing)} no está configurado`,
                'Por favor, configure los parámetros'
            )));
            return;
        }
        const sInitDate = initDate.split('-');
        const sEndDate = endDate.split('-');
        const fInitDate = new Date(sInitDate[0], sInitDate[1] - 1, sInitDate[2]);
        const fEndDate = new Date(sEndDate[0], sEndDate[1] - 1, sEndDate[2]);
        const difference = differenceInDays(fEndDate, fInitDate);
        if (difference <= 20) {
            reset(printInitValues({
                catalog,
                workerTypes,
                filter,
            }));
            setOpen(true);
            return;
        }
        dispatch(showNotificationWarning(warningCustomize(
            t('warning-general-title'),
            'La cantidad de días del periodo debe ser menor o igual a 20',
            'Por favor, configure los parámetros'
        )));
    }

    async function getWorkerTypes() {
        dispatch(lockedWindow());
        const request = {
            tenant: currentTenant(),
            tree: "",
        }
        try {
            const response = await getJobPositionsTypeApi.get(request);
            setWorkerTypes(response);
        } catch (error) {
            resolveError(error);
        } finally {
            dispatch(unlockedWindow());
        }
    }

    function getWorkerTypeId(data) {
        let type;
        for (let option in data) {
            const value = data[option];
            if (value && option !== 'all') {
                type = workerTypes.find(type => type.name === option)?.id;
            } else if (option === 'all') {
                type = null;
            }
        }
        return type;
    }

    const getWorkgroup = (data) => {
        const pType = data?.print_type;
        switch (pType) {
            case 0:
                return "*";
            case 1:
                return toString(filter?.workgroup);
            case 2: {
                const selectedWgs = data?.structure?.workgroup?.join(',');
                return selectedWgs ? selectedWgs : "*";
            }
            default:
                return "*";
        }
    }

    async function onSubmit(params) {
        dispatch(lockedWindow());
        const request = { ...params };
        delete request.disabled;
        for (let sec in request) {
            if (sec !== 'dates'
                && sec !== 'structure'
                && sec !== 'worker_type') {
                const section = request[sec];
                if (typeof section === 'object') {
                    let value = null;
                    if (size(section) > 1) {
                        const keys = Object.keys(section);
                        const index = keys.indexOf(keys.find((key) => section[key] === true));
                        value = index;
                    } else {
                        for (let option in section) {
                            if (section[option]) {
                                value = 1;
                            } else {
                                value = 0;
                            }
                        }
                    }
                    request[sec] = value >= 0 ? value : null;
                }
            }
        }
        request.initial_date = format(request.dates?.initial_date, 'yyyy-MM-dd');
        request.end_date = format(request.dates?.final_date, 'yyyy-MM-dd');
        delete request.dates;
        request.branches = request.structure?.branch;
        request.employer_registrations = request.structure?.employer_registration;
        request[catalog] = request.structure?.[catalog];
        request.workgroup = getWorkgroup(request);
        delete request.structure;
        request.worker_type = getWorkerTypeId(request.worker_type);
        request.period = filter?.period;
        Object.keys(request).forEach(key => {
            if (request[key] === null) {
                delete request[key];
            }
        });
        try {
            const response = await attendanceListAPI.exportList(request);
            TriggerNotificationSocket(`attendancelist_print`, response.task);
            handleClose();
        } catch (error) {
            resolveError(error);
        } finally {
            dispatch(unlockedWindow());
        }
    }

    return {
        open,
        handleClose,
        handleOpen,
        control,
        setValue,
        getValues,
        onSubmit,
        handleSubmit,
        catalog,
        setError,
        errors,
        clearErrors,
        workerTypes,
        structure,
        loading,
        userWorkgroups,
    };
}

export default usePrintList;

export const downloadAttendanceList = async (taskID) => {
    try {
        let response = await socketsApi.downloadReport(taskID);
        FileSaver.saveAs(
            response,
            `${"Lista de asistencia"}_${format(
                new Date(),
                "yyyy-MM-dd hh:mm:ss"
            )}`
        );
    } catch (error) {
        return error;
    }
}
