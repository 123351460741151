import { ParseParams, ServerAPI, encodeURIParams, getUrl } from "./ServerAPI";
import axios from "axios";
import { API, config } from "./config";

/**
	all principal methods -> (get, post, put, destroy)
	@example vacationBalancesApi.get(params)
*/
export const vacationBalancesApi = ServerAPI("vacation-balance", true, true);
export const vacationRequisitionsApi = ServerAPI("mopers/vacation");
export const mopersApi = ServerAPI("moper-catalog");
export const mopersConceptsApi = ServerAPI("mopers-concepts");
export const mopersSignatureSettingApi = ServerAPI("mopers-signature-setting");
export const mopersAbsenteeismCalculate = ServerAPI("mopers-absenteeisms/calculate");
export const mopersClockWorker = ServerAPI("mopers-clock-worker");
export const clockWorkerList = ServerAPI("clock-worker-list");
export const mopersComments = ServerAPI("mopers-predefined-comment");
export const counterMoper = ServerAPI("counter-moper");
export const mopersCompleteTurnList = ServerAPI("worker-calculate-worked-list");
export const mopersCompleteTurn = ServerAPI("moper-complete-shift");
export const balancesOvertimeTXT = ServerAPI("attendance-list/balances-overtime-txt");
export const shiftsAPI = ServerAPI('shifts');

const moperPreRequest = () => {
	const baseName = 'moper-pre-request';
	const baseApi = ServerAPI(baseName);

	const patch = (id, params) => {
		const url = getUrl(`${baseName}/${id}/request`, true);

		return axios.patch(url, ParseParams({ params }), config())
			.then((response) => response.data);
	};

	const getTotals = () => {
		const url = getUrl(`${baseName}/total`, true);
		return axios.get(url, config())
			.then((response) => response.data);
	}

	return {
		...baseApi,
		patch,
		getTotals,
	}
}

export const moperPreRequestApi = moperPreRequest();

const cancelMoperPreRequest = () => {
	const baseName = 'pendding-signatures';

	const patch = (id, request) => {
		const url = getUrl(`${baseName}/${id}/cancel`, true);

		return axios.patch(url, ParseParams({ params: request }), config())
			.then((response) => response.data);
	};

	return {
		patch,
	}
}

export const cancelMoperPreRequestAPI = cancelMoperPreRequest();

const mopersSignList = () => {

	const baseName = "mopers-sign-list";
	const baseApi = ServerAPI(baseName);

	const cancelMoper = (params, body) => {
		const request = encodeURIParams(params);

		const url = (API + `/${baseName}/status/${request}`);

		return axios.post(url, body, config())
			.then((response) => response.data);
	};

	return {
		...baseApi,
		cancelMoper,
	}
}

export const mopersSignListApi = mopersSignList();

const mopersClockWorker2 = () => {

	const baseName = "mopers-clock-worker";
	const baseApi = ServerAPI(baseName);

	const cancelMoper = (id, request) => {
		const url = getUrl(`${baseName}/${id}/cancel`, true);

		return axios.patch(url, ParseParams({ params: request }), config())
			.then((response) => response.data);
	};

	const saveChecks = (request) => {
		const url = getUrl(`${baseName}/many-checks-create`, true);
		return axios.post(url, ParseParams({ params: request }), config())
			.then(response => response.data);
	}

	return {
		...baseApi,
		cancelMoper,
		saveChecks,
	}
}

export const mopersClockWorkerApi = mopersClockWorker2();

const mopersES = () => {

	const baseName = "clock-worker-list";
	const baseApi = ServerAPI(baseName);

	const changeES = (id, request) => {
		const url = getUrl(`${baseName}/${id}/change-es`, true);

		return axios.put(url, ParseParams({ params: request }), config())
			.then((response) => response.data);
	};

	const deleteCheck = (id, params) => {
		const url = getUrl(`${baseName}/${id}`, true);
		const payload = config(params);
		return axios.delete(url, payload, config())
			.then(response => response.data);
	}

	return {
		...baseApi,
		changeES,
		deleteCheck,
	}
}

export const mopersESApi = mopersES();

const mopersAbsenteeism = () => {

	const baseName = "mopers-absenteeisms";
	const baseApi = ServerAPI(baseName);

	const changeStatus = (id, request) => {
		const url = getUrl(`${baseName}/${id}/status`, true);

		return axios.patch(url, ParseParams({ params: request }), config())
			.then((response) => response.data);
	};

	return {
		...baseApi,
		changeStatus,
	}
}

export const mopersAbsenteeismApi = mopersAbsenteeism();


const MoperSignaturesApi = () => {

	const baseName = "moper-signature";
	const baseApi = ServerAPI(baseName);

	const sign = (request) => {
		const url = getUrl(`${baseName}/sign`, true);

		return axios.post(url, ParseParams({ params: request }), config())
			.then((response) => response.data);
	}

	const signGroup = (params, body) => {

		const request = encodeURIParams(body);
		const url = (API + `/${baseName}/sign-group/${request}`);

		return axios.post(url, params, config())
			.then((response) => response.data);
	}

	const signMassive = (payload) => {
		const url = getUrl(`${baseName}/sign-massive`, true);
		return axios.post(url, {}, config(payload)).then(response => response.data);
	}

	return {
		...baseApi,
		sign,
		signGroup,
		signMassive
	}
}

export const moperSignaturesApi = MoperSignaturesApi();

const OvertimeMopers = () => {
	const baseName = "moper-overtime";
	const baseApi = ServerAPI(baseName);

	const createMoper = (request) => {
		const url = getUrl(`${baseName}/moper-overtime-create`, true);
		return axios.post(url, ParseParams({ params: request }), config())
			.then((response) => response.data);
	}

	const cancelMoper = (request) => {
		const url = getUrl(`${baseName}/moper-overtime-cancel`, true);
		return axios.post(url, ParseParams({ params: request }), config())
			.then((response) => response.data);
	}

	const cancelTxT = (request) => {
		const url = getUrl(`${baseName}/overtime-txt-cancel-balance`, true);
		return axios.post(url, ParseParams({ params: request }), config())
			.then((response) => response.data);
	}

	const getWorkers = (params) => {
		const url = getUrl(`${baseName}/worker-cover-overtime`, true);
		return axios.get(url, config(params))
			.then((response) => response.data);
	}

	const getPaymentsAvailable = (params) => {
		const url = getUrl(`${baseName}/payments-available-worker`, true);
		return axios.get(url, config(params))
			.then((response) => response.data);
	}

	const restoreMoper = (id, params) => {
		const url = getUrl(`${baseName}/${id}/restore-moper`, true);
		const payload = config({}, params);
		return axios.delete(url, payload)
			.then((response) => response.data);
	}

	const changeCalc = (params) => {
		const url = getUrl(`${baseName}/change`, true);
		return axios.patch(url, ParseParams({ params }), config())
			.then((response) => response.data);
	}

	const resolveIncidence = (params) => {
		const url = getUrl(`${baseName}/resolve-incidence`, true);
		return axios.put(url, ParseParams({ params }), config())
			.then(response => response.data);
	}

	const calculate = (params) => {
		const url = getUrl('overtime-calculation', true);
		return axios.get(url, config(params))
			.then((response) => response.data);
	}

	const verifyCoverWorker = (params) => {
		const url = getUrl(`cover-worker-validate`, true);
		return axios.get(url, config(params))
			.then(response => response.data);
	}

	return {
		...baseApi,
		calculate,
		createMoper,
		cancelMoper,
		cancelTxT,
		getWorkers,
		restoreMoper,
		changeCalc,
		resolveIncidence,
		verifyCoverWorker,
		getPaymentsAvailable,
	}
};

export const overtimeMopersAPI = OvertimeMopers();

const PromotionsMopers = () => {
	const baseName = "moper-promotion";
	const baseApi = ServerAPI(baseName);

	return {
		...baseApi
	}
}

export const promotionsMopersAPI = PromotionsMopers();

const MopersHistory = () => {

	const vacations = (params) => {
		const url = getUrl('vacations-historics', true);
		return axios.get(url, config(params))
			.then(response => response.data);
	}

	const absenteeisms = (params) => {
		const url = getUrl('absenteeism-historics', true);
		return axios.get(url, config(params))
			.then(response => response.data);
	}

	const overtime = (params) => {
		const url = getUrl('get-count-mopers', true);
		return axios.get(url, config(params))
			.then(response => response.data);
	}

	return {
		vacations,
		absenteeisms,
		overtime
	}
}

export const mopersHistoryAPI = MopersHistory();