import { lazy } from "react";
import {
    faFolderGear,
} from "@fortawesome/pro-light-svg-icons";

const abrilItems = [
    {
        id: 'abrhil@corporate',
        type: 'item',
        validateItem: true,
        icon: "corporate",
        componentElm: lazy(async ()  => import("../../../../modules/AbrhilApp/Corporate")),
    },
    {
        id: 'abrhil@business',
        type: 'item',
        icon: "enterprise",
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/Company')),
        sub_routes: [
            {
                index: true, // Indica que será el componente representante
                componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/Company')),
            },
        ]
    },
    {
        id: 'abrhil@migrations',
        type: 'item',
        validateItem: true,
        icon: "migration",
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/Migrations')),
    },
    {
        id: 'abrhil@packages_admin',
        type: 'item',
        inConstruction: true,
        validateItem: true,
        icon: faFolderGear,
        // componentElm: <PackagesAdmin />
    },
    {
        id: 'abrhil@nationalities',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@contract-catalog',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/ContractCatalog')),
    },
    {
        id: 'abrhil@federal-entities',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/FederalEntities')),
    },
    {
        id: 'abrhil@migratory-status',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/MigratoryState')),
    },
    {
        id: 'abrhil@blood-type',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/Bloodtype'))
    },
    {
        id: 'abrhil@calendar-holidays',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/CalendarHolidays')),
    },
    {
        id: 'abrhil@zipcode',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/PostalCode')),
    },
    {
        id: 'abrhil@coworker-type',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.worker_type"],
        componentElm: lazy(async ()  => import('../../../../modules/WorkerType')),
    },
    {
        id: 'abrhil@tax-tables',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/Taxes')),
    },
    {
        id: 'abrhil@job-position-type',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.job_position_type"],
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/JobPositionType')),
    },
    // {
    //     id: 'abrhil@position-classification',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@daily-minimun-salary',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/DailyMinimum')),
    },
    {
        id: 'abrhil@professions',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Professions')),
    },
    {
        id: 'abrhil@uma',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/UMA')),
    },
    {
        id: 'abrhil@umi',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/UMI')),
    },
    {
        id: 'abrhil@profesional-minimun-salary',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/ProfessionalMinimum')),
    },
    {
        id: 'abrhil@federal-entities',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    // {
    //     id: 'abrhil@municipalities',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@type-sat-regime-list',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/RegimeType')),
    },
    {
        id: 'abrhil@pay-frequency-sat',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/PeriodicitySAT')),
    },
    {
        id: 'abrhil@resource-origin',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/ResourceOrigin')),
    },
    {
        id: 'abrhil@contracting-regime',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/ContractingRegime')),
    },
    {
        id: 'abrhil@tax-regime',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@type-contract-sat',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/ContractSAT')),
    },
    // {
    //     id: 'abrhil@imss-classification',
    //     type: 'item',
    //     package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@imss-division',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: lazy(async ()  => import('../../../../modules/IMSSDivision')),
    },
    {
        id: 'abrhil@imss-fraction',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: lazy(async ()  => import('../../../../modules/IMSSFraction')),
    },
    {
        id: 'abrhil@imss-group',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: lazy(async ()  => import('../../../../modules/IMSSGroup')),
    },
    {
        id: 'abrhil@imss-class',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: lazy(async ()  => import('../../../../modules/IMSSClass')),
    },
    {
        id: 'abrhil@payment-percentage-history',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/HistoryCyV')),
    },
    {
        id: 'abrhil@working-day',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@salary-type',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    // {
    //     id: 'abrhil@shifts',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@inability-classification',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/InabilityClassification')),
    },
    {
        id: 'abrhil@insurance-branch',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/InsuranceBranch')),
    },
    {
        id: 'abrhil@inability-control',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/InabilityControl')),
    },
    {
        id: 'abrhil@sequel-consequence',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/SequelConsequence')),
    },
    {
        id: 'abrhil@type-of-risk',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/TypeOfRisk')),
    },
    {
        id: 'abrhil@inability-covid',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/Covid')),
    },
    {
        id: 'abrhil@quota-imss',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/QuotaIMSS'))
    },
    {
        id: 'abrhil@imss-reason-leaving',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/ImssReasonLeaving')),
    },
    {
        id: 'abrhil@payroll-types',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/PayrollTypes')),
    },
    {
        id: 'abrhil@child-support',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/CatAlimony'))
    },
    {
        id: 'abrhil@federal-entities-taxes',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/FederalEntitiesTaxes')),
    },
    {
        id: 'abrhil@tax-tables',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@pay-frequency',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Periodicity'))
    },
    // {
    //     id: 'abrhil@type-tax-adjustment',
    //     type: 'item',
    //     inConstruction: true,
    //     componentElm: true
    // },
    {
        id: 'abrhil@schematics',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Schematics'))
    },
    {
        id: 'abrhil@payment-types',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/PaymentTypes'))
    },
    {
        id: 'abrhil@status-payroll',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@credit-type-discount',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@movement-type',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@credit-type-place',
        type: 'item',
        inConstruction: true,
        showInMenu: false,
        componentElm: true
    },
    {
        id: 'abrhil@banks',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/Banks')),
    },
    {
        id: 'abrhil@banks-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@layouts',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/Layouts')),
    },
    {
        id: 'abrhil@dynamic-banks-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@food-voucher-enterprise',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@voucher-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@dynamic-voucher-fields',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@absenteeism-list',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/Absenteeism')),
    },
    {
        id: 'abrhil@absenteeism-reasons',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/AbsenteeismReasons'))
    },
    {
        id: 'abrhil@moper-catalog',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Mopers')),
    },
    {
        id: 'abrhil@education-stps',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/EducationSTPS')),
    },
    {
        id: 'abrhil@device',
        type: 'item',
        icon: 'clock',
        componentElm: lazy(async ()  => import('../../../../modules/ClockApp/Device')),
    },
    {
        id: 'abrhil@concept-sat',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/StampConcept/StampConceptContainer')),
    },
    {
        id: 'abrhil@rfc',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/RFC')),
    },
    {
        id: 'abrhil@voucher-banks',
        type: 'item',
        package_permissions: ["payroll_package", "payroll_package.period_payment_voucher"],
        componentElm: lazy(async ()  => import('../../../../modules/VoucherBanks')),
    },
    {
        id: 'abrhil@voucher-layouts',
        type: 'item',
        package_permissions: ["payroll_package", "payroll_package.period_payment_voucher"],
        componentElm: lazy(async ()  => import('../../../../modules/VoucherLayouts')),
    }
];

export default abrilItems;