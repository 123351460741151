export const vacationsBalanceReport = {
    general: 1,
    individual: 2,
    end_year_excel: 3
};

export const vacationsBalanceOuput = {
    pdf: "pdf",
    xlsx: "xlsx",
};

export const ReportBrokenDown = {
    PERIOD: 0,
    MONTH: 1,
    YEAR: 2,
};