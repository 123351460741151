import React from "react";
import PropTypes from "prop-types";
import { AvatarAndTooltip } from "../../../../@components/photo/CustomAvatar";
import { store } from "../../../../../store";
import { unlockedWindow } from "../../../../../store/actions";
import { resolveError } from "../../../../common/resolve-error";
import { CustomTooltip } from "../../../../../App/components/Templates/cells";
import { AnimatePresence, motion } from "framer-motion";
import { opacityAnimation } from "../../../../@components/form/Permission/Utils";
import { hasValue } from "../../../../common/GeneralUtilities";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isFunction, isObject, isString, size, toString } from "lodash";
import { workerFullName } from "../../../../@components/employees/constants";
import "../../style/style.scss";

export const useCanViewSalary = () => store.getState().userConfigs?.configs?.can_see_salaries;

export const useCanViewMopersAmounts = () => store.getState().userConfigs?.user_info?.moper_attendance?.find(el => el.key === 'see_salaries')?.has_perm;

/**
 * Componente para validar permisos de ver salarios
 * @param {object} params
 * @param {boolean} params.censure
 * @param {"kardex" | "mopers"} params.type - Tipo de permiso a utilizar (kardex o mopers)
 */
export const SalariesGate = ({ children, type = 'kardex', censure = false }) => {
    let hasPerm = useCanViewSalary();

    if (type === 'mopers') {
        hasPerm = useCanViewMopersAmounts();
    }

    if (!children) return null;

    if (!hasPerm && !censure) return null;

    if (!hasPerm && censure) return SALARY_CENSURE;

    return children;
};

SalariesGate.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element,
    ])
};

export const SalaryText = ({ children, value }) => {
    const salaryPerm = useCanViewSalary();
    return validateSalaryPerms(children || value, salaryPerm);
}

SalaryText.propTypes = {
    children: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

export const CustomTag = ({
    label,
    onClick,
    className,
    style = {},
}) => {

    return (
        <div
            {...(isFunction(onClick) ? { role: "button" } : {})}
            className={`sbw-custom-tag ${className}`}
            style={style}
            onClick={onClick}
        >
            {label}
        </div>
    );
};

CustomTag.propTypes = {
    label: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

export const SALARY_CENSURE = '$****';

export const validateSalaryPerms = (value, perm) => {
    if (!value) return '';
    if (isString(value)) {
        if (value?.includes('$')) {
            return perm ? value : SALARY_CENSURE;
        }
    }
    return value;
}

export const GenericLabel = ({
    label,
    value,
    labelIcon: LabelIcon,
    valueIcon,
    tooltip,
    weight = '400',
    blueValue = true,
    useKardexSalary = false,
    useSalaryPerm = true,
    primaryLabel = false,
    wrapLabel = false,
    wrapValue = false,
    className = "",
    withValue = true,
    disableTooltip = false,
    fontSize = "clamp(12px, 0.7dvw, 14px)",
    align = "horizontal",
    style = {},
    labelStyle = {},
}) => {

    let iconRender = null;
    const salariesPerm = !useKardexSalary ? useCanViewMopersAmounts() : useCanViewSalary();

    value = !isObject(value) && !isFunction(value) && hasValue(value) ? toString(value) : value;

    if (LabelIcon?.prefix) {
        iconRender = <FontAwesomeIcon icon={LabelIcon} />;
    } else if (LabelIcon?.render) {
        iconRender = <LabelIcon />;
    } else {
        iconRender = LabelIcon;
    }

    const validatePerms = text => {
        if (!useSalaryPerm) return text;
        return validateSalaryPerms(text, salariesPerm);
    }

    return (
        <CustomTooltip title={!disableTooltip ? (tooltip ?? validatePerms(value)) : ''}>
            <div className={`sbw-label-generic-item ${align} ${className}`}>
                {
                    hasValue(label) ?
                        <span
                            className="label"
                            style={{
                                fontSize,
                                fontWeight: weight,
                                ...labelStyle,
                                ...(primaryLabel ? { color: 'var(--egyptianBlue)' } : {}),
                                ...(wrapLabel ? {
                                    whiteSpace: 'wrap',
                                    wordBreak: 'normal',
                                } : {}),
                                ...(wrapValue ? {
                                    alignSelf: 'flex-start'
                                } : {})
                            }}>
                            {iconRender}
                            {`${label}${withValue && value ? ':' : ''}`}
                        </span>
                        :
                        null
                }
                {
                    value ?
                        <span
                            className={`value ${blueValue ? 'blue' : ''}`}
                            style={{
                                ...style,
                                fontSize,
                                fontWeight: weight,
                                ...(wrapValue ? {
                                    whiteSpace: 'wrap',
                                    wordBreak: 'normal',
                                } : {})
                            }}
                        >
                            {valueIcon ? <FontAwesomeIcon icon={valueIcon} /> : null}
                            {validatePerms(value)}
                        </span>
                        :
                        null
                }
            </div>
        </CustomTooltip>
    );
};

GenericLabel.propTypes = {
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    value: PropTypes.any,
    weight: PropTypes.string,
    blueValue: PropTypes.bool,
    wrapLabel: PropTypes.bool,
    wrapValue: PropTypes.bool,
    primaryLabel: PropTypes.bool,
    useSalaryPerm: PropTypes.bool,
    useKardexSalary: PropTypes.bool,
    align: PropTypes.string,
    valueIcon: PropTypes.any,
    labelIcon: PropTypes.any,
    style: PropTypes.object,
    withValue: PropTypes.bool,
    disableTooltip: PropTypes.bool,
    className: PropTypes.string,
    fontSize: PropTypes.string,
    tooltip: PropTypes.any,
    labelStyle: PropTypes.object,
};

export const Avatar = ({
    photo,
    worker,
    width,
    fontSize,
    border = true,
    withTooltip = true,
    className = "",
}) => {

    const fullName = size(worker) ? workerFullName(worker) : '';
    const cPhoto = photo ?? worker?.photo?.medium_square_crop ?? worker?.photo?.full_size ?? worker?.photo;
    const status = worker?.current_status?.active;
    const isActive = status ? 'active' : 'inactive';

    return (
        <div
            className={`sbw-custom-avatar ${size(worker) ? isActive : ''} ${border ? 'with-border' : ''} ${className}`}
        >
            <AvatarAndTooltip
                photo={cPhoto}
                string={fullName.trim()}
                fontSize={fontSize ?? '40px'}
                withTooltip={withTooltip}
                sx={{
                    width: width ?? '65px',
                    height: width ?? '65px'
                }}
            />
        </div>
    );
};

Avatar.propTypes = {
    photo: PropTypes.any,
    worker: PropTypes.object,
    width: PropTypes.string,
    border: PropTypes.bool,
    fontSize: PropTypes.string,
    className: PropTypes.string,
    withTooltip: PropTypes.bool,
};

export const onFailed = error => {
    store.dispatch(unlockedWindow());
    resolveError(error);
}

export const TextMatrix = ({
    items,
    withTooltips = true
}) => {
    const isNotBlank = value => !value?.includes('blank');

    return (
        <table className="gs-text-matrix">
            <tbody>
                {items?.map((item, ind) =>
                    <tr key={ind}>
                        <td>{isNotBlank(item.label) && <span><GenericLabel label={item.label} tooltip={item.value} disableTooltip={!withTooltips} />{':'}</span>}</td>
                        <td>{isNotBlank(item.label) && <GenericLabel value={item.value} className={item.class ?? ''} disableTooltip={!withTooltips} />}</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
};

TextMatrix.propTypes = {
    items: PropTypes.array,
    withTooltips: PropTypes.bool
};

export const TextAsync = ({
    text,
    className,
    style = {},
    loaderSize = 15,
}) => {

    TextAsync.propTypes = {
        text: PropTypes.any,
        loaderSize: PropTypes.number,
        style: PropTypes.object,
        className: PropTypes.string,
    };

    return (
        <AnimatePresence mode="wait">
            {
                hasValue(text) ?
                    <motion.span
                        key={text}
                        className={`custom-text-async ${className}`}
                        style={style}
                        {...opacityAnimation}
                    >
                        {text}
                    </motion.span>
                    :
                    <CircularProgress className="async-text-loader" size={loaderSize} />
            }
        </AnimatePresence>
    );
};

export const Loader = ({ size }) => {

    Loader.propTypes = {
        size: PropTypes.number
    };

    return (
        <div className="loader-wrap">
            <svg className="gradient">
                <defs>
                    <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                        <stop offset="20%" stopColor="#1beef9" />
                        <stop offset="40%" stopColor="#5aaafa" />
                        <stop offset="60%" stopColor="#007aff" />
                        <stop offset="80%" stopColor="#1a55eb" />
                        <stop offset="100%" stopColor="#26328a" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress
                variant="indeterminate"
                className="custom-abr-loader"
                size={size}
                thickness={4}
                classes={{ circle: 'locked-circle' }}
                sx={{
                    animationDuration: '1200ms',
                }}
            />
        </div>
    );
};

export const PaddingWidget = ({
    children,
    value = '10px',
    className = '',
    style = {},
}) => {

    return (
        <div className={`sbw-padding-widget ${className}`} style={{ padding: value, ...style }}>
            {children}
        </div>
    );
};

PaddingWidget.propTypes = {
    children: PropTypes.any,
    value: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object
};