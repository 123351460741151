import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, validations } from "../Components/constants";
import { each, isString, omit, pick, size } from "lodash";
import { useGridContext } from "./useGrid";
import { fileFromUrl } from "../../../EstablishmentKey/hooks/useDialogController";
import { useDispatch } from "react-redux";
import { companyApi, corporateAPI } from "../../../../services/company";
import { lockedWindow, unlockedWindow } from "../../../../../store/actions";
import TriggerNotificationSocket from "../../../../common/trigger-notification-socket";
import { resolveError } from "../../../../common/resolve-error";
import { usersApi } from "../../../../services/administrator";
import { countriesAPI } from "../../../../services/countries";
import { useRequestLoad } from "../../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";
import { statusLabel } from "../../../../../App/components/StatusColumn";
import { showSuccessNotification } from "../../../../../App/components/Notifications";
import { successUpdated } from "../../../../common/notification-messages";

export const defTabs = (isEdit, isActive, withSSO) => ([
    { id: 1, label: 'Información General', key: 'general', icon: 'Settings', errorFields: ['name', 'owner', 'corporate', 'slug', 'securitytype', 'country'] },
    { id: 2, label: 'Seguridad y Autenticación', key: 'security', icon: 'Shield', errorFields: ['login_mode', 'sso_config'] },
    { id: 3, label: 'Roles Externos', key: 'external_roles', icon: 'ExternalRoles', errorFields: ['roles_map'], isSSO: true },
    { id: 4, label: 'Tokens', key: 'tokens', icon: 'Token', hideNew: true },
    { id: 5, label: 'Foto de la Empresa', key: 'picture', icon: 'Picture' },
    { id: 6, label: 'Estado de la Empresa', key: 'status', icon: 'PowerOnOff', customRender: statusLabel(isActive) },
].filter(el => ((!isEdit && !el.hideNew) || isEdit) && (!withSSO && !el.isSSO) || withSSO));

function useDialog() {
    const dispatch = useDispatch();

    const { form, loadCompanies, onClose, setForm } = useGridContext();
    const values = form?.item;
    const isEdit = form?.isEdit;
    const item = form?.item;
    const open = form?.open;

    const initValues = { ...defaultValues, ...values };
    const filesFields = ['cert_sp', 'key_sp', 'cert_idp'];

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(validations()),
        defaultValues: initValues,
        mode: "onChange"
    });

    const noSSOTabs = defTabs(isEdit, getValues('is_active'), false);

    const [tabs, setTabs] = useState(noSSOTabs);
    const [tab, setTab] = useState(0);

    const [users, setUsers] = useState([]);
    const [corporatives, setCorporatives] = useState([]);
    const [dataCountry, setDataCountry] = useState([]);

    const [fetchUsers, loadingUsers] = useRequestLoad();
    const [fetchCorp, loadingCorp] = useRequestLoad();
    const [fetchCountries, loadingCountries] = useRequestLoad();

    useEffect(() => {
        if (!open) {
            reset({ ...defaultValues });
            setTab(0);
            return;
        }
        initializeValues();
        getCorporative();
        getUsers();
        getCountries();
    }, [open])

    function getUsers() {
        const { id } = item;
        const request = { tree: true, fields: 'id,email', omit: 'groups_perms', tenant: id };
        fetchUsers({
            api: usersApi.getPublicUsers(request),
            callback: setUsers
        });
    }

    const getCorporative = () => {
        fetchCorp({
            api: corporateAPI.get({ tree: true }),
            callback: setCorporatives
        });
    };

    const getCountries = () => {
        fetchCountries({
            api: countriesAPI.get({ tree: true }),
            callback: setDataCountry
        });
    };

    const initializeValues = async () => {
        const files = await initializeFiles(pick(initValues?.sso_config, filesFields));
        initValues.sso_config = {
            ...defaultValues.sso_config,
            ...omit(initValues?.sso_config ?? {}, ['user_fields_map']),
            ...(initValues?.sso_config?.user_fields_map ?? {}),
            ...files,
        };
        initValues.roles_map = initValues.sso_config.roles_map;
        reset(initValues);
    }

    const initializeFiles = async (data) => {
        if (!size(data)) return data;
        const newData = {};
        await Promise.all(
            Object.entries(data).map(async (el) => {
                const item = el[1];
                const field = el[0];
                if (!item) return;
                newData[field] = await fileFromUrl(item);
            })
        );
        return newData;
    }

    const onSubmit = async (data) => {
        data = omit(data, ['settings', 'settings_v2', 'packages']);
        data.sso_config = omit(data?.sso_config, ['user_fields_map']);

        const ssoData = pick(data?.sso_config, ['email', 'username', 'roles']);
        if (size(ssoData)) {
            const filtered = Object.fromEntries(Object.entries(ssoData).filter(el => el[1]));
            data.user_fields_map = JSON.stringify(filtered);
        }

        if (data?.sso_config) {
            data.sso_config.id_sso = data.sso_config.id;
            const fieldsOmit = [];
            each(filesFields, field => {
                const value = data.sso_config[field];
                if (isString(value)) {
                    fieldsOmit.push(field);
                }
            });
            data.sso_config = omit(data?.sso_config, ['id', ...fieldsOmit]);
        }
        await performRequest(data);
    }

    const performRequest = async (data) => {
        dispatch(lockedWindow());
        try {
            if (isEdit) {
                const configOptions = [2];
                const requireConfig = configOptions.includes(parseInt(data?.login_mode));
                data = omit({ ...data, ...(data?.sso_config ?? {}), roles_map: data.roles_map ? JSON.stringify(data.roles_map) : data.roles_map }, ['sso_config']);
                if (!data.single_logout_service_idp) {
                    data.single_logout_service_idp = "";
                }
                const resp = await companyApi.putMedia(data?.id, omit(data, ['logo']));
                if (requireConfig && resp) {
                    await companyApi.updateSsoConfigs({ company: data?.id });
                }
                showSuccessNotification(successUpdated());
            } else {
                const response = await companyApi.postMedia(data);
                TriggerNotificationSocket('create_tenant', response?.task_id);
            }
            onClose();
            dispatch(unlockedWindow());
            loadCompanies();
        } catch (error) {
            resolveError(error);
        } finally {
            dispatch(unlockedWindow());
        }
    }

    const onTabChange = value => setTab(value);

    return {
        tab,
        open,
        tabs,
        item,
        users,
        isEdit,
        control,
        onClose,
        setForm,
        noSSOTabs,
        defTabs,
        setTabs,
        onSubmit,
        onTabChange,
        handleSubmit,
        getValues,
        setValue,
        initValues,
        loadCompanies,
        loadingUsers,
        loadingCorp,
        dataCountry,
        corporatives,
        loadingCountries,
        currentTab: tabs[tab]
    };
};

const DialogContext = createContext({});

export const useDialogContext = () => useContext(DialogContext);

export default function DialogController({
    children,
}) {
    const values = useDialog();

    return (
        <DialogContext.Provider value={values}>
            {children}
        </DialogContext.Provider>
    );
};

DialogController.propTypes = {
    children: PropTypes.any
};