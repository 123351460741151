import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Grid from '@mui/material/Grid2';
import { gridSize } from "../..";
import IconUse from "../../../../../../../common/IconUse";
import { BaseButton } from "../../../../../../../../App/components/Buttons";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { opacityAnimation, scaleAnimation } from "../../../../../../../@components/form/Permission/Utils";
import { first, isFunction } from "lodash";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { scanFile } from "../../../../../../../services/ServerAntivirus";
import { urltoFile } from "../../../../../../../@components/modal/PhotoUpload";
import { resolveError } from "../../../../../../../common/resolve-error";
import { uid } from "uid";
import { companyApi } from "../../../../../../../services/company";
import { useDialogContext } from "../../../../hooks/useDialog";
import { useRequestLoad } from "../../../../../../MOPERSByWorker/components/container/Overtime/hooks/useResolveIncidence";
import { showSuccessNotification } from "../../../../../../../../App/components/Notifications";
import { successUpdated } from "../../../../../../../common/notification-messages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenLine } from "@fortawesome/pro-light-svg-icons";
import { Divider } from "@mui/material";
import FormInput from "../../../../../../../@components/form/Field";

export const withLayout = {
    component: motion.div,
    layout: true,
};

function Picture() {
    const { item, getValues, setValue, control, loadCompanies } = useDialogContext();
    const [fetch, loading] = useRequestLoad();
    const [isEditing, setIsEditing] = useState(false);

    const onSubmit = async (image) => {
        if (item?.id) {
            fetch({
                api: companyApi.updateLogo(item.id, { logo: image }),
                callback: (res) => {
                    showSuccessNotification(successUpdated());
                    setValue('logo', res.logo);
                    loadCompanies();
                }
            });
            return;
        }
        setValue('logo', image);
    }

    return (
        <Grid
            {...gridSize(12)}
            container
            direction="column"
            spacing={4}
        >
            <ImageEditor
                src={getValues("logo")?.full_size}
                handleSubmit={onSubmit}
                loading={loading}
                setIsEditing={setIsEditing}
            />
            <LayoutGroup>
                {!isEditing &&
                    <Grid
                        key='company-dialog-divider'
                        className="company-dialog-divider"
                        {...gridSize(12)}
                        {...withLayout}
                        {...scaleAnimation}
                    >
                        <Divider>o</Divider>
                    </Grid>}
                {!isEditing &&
                    <Grid
                        key='url-logo-input'
                        {...gridSize(12)}
                        {...withLayout}
                        {...scaleAnimation}
                    >
                        <FormInput
                            control={control}
                            fieldInput="TextField"
                            name="url_logo"
                            label="Importar por medio de URL"
                        />
                    </Grid>}
                <Grid key='suggestion-message' {...gridSize(12)} style={{ fontWeight: 300 }} {...withLayout}>
                    Recomendación: La imagen debe tener un tamaño mínimo de 1024 x 1024 píxeles.
                </Grid>
            </LayoutGroup>
        </Grid>
    );
};

export default Picture;

export const ImageEditor = ({ src, handleSubmit, setIsEditing, loading }) => {
    const dropZone = useRef();
    const [image, setImage] = useState(src ?? null);
    const [inside, setInside] = useState(false);
    const [editing, setEditing] = useState(false);
    const [output, setOutput] = useState(null);
    const fileExtenstion = useRef();

    useEffect(() => {
        const ext = getExtension(image);
        fileExtenstion.current = ext ? `.${ext}` : null;
    }, [image])

    const getExtension = photo => {
        const regex = /(?:\.([^.]+))?$/;
        const match = regex.exec(photo);
        return match[1];
    }

    const onEnter = () => {
        setInside(true);
    }

    const onExit = () => {
        setInside(false);
    }

    const onDrop = (e) => {
        e.preventDefault();
        const source = first(e.dataTransfer?.files);
        if (source) {
            addImage(source);
        };
        onExit();
    }

    const addImage = (file) => {
        if ((file?.validationErrors || []).length > 0) {
            setImage(null);
            return;
        }
        const reader = new FileReader();
        reader.addEventListener('load', async () => {
            try {
                await scanFile(await urltoFile(reader.result));
                setImage(reader.result);
                updateEditing(true);
            } catch (error) {
                resolveError(error);
                setImage(null);
            }
        });
        reader.readAsDataURL(file);
    }

    const onCancel = () => {
        setImage(src);
        updateEditing(false);
    }

    const onRemove = () => {
        setImage(null);
    }

    const onSubmit = () => {
        if (output) {
            const realExt = fileExtenstion.current?.replace(/\./g, '') ?? 'png';
            const imageInfo = `image/${realExt}`;
            const urlFile = output.toDataURL(imageInfo, .5);
            urltoFile(urlFile, (`${uid()}.${realExt}`), imageInfo)
                .then(file => {
                    setImage(urlFile);
                    if (isFunction(handleSubmit)) {
                        handleSubmit(file);
                    }
                });
        }
        updateEditing(false);
    }

    const updateEditing = value => {
        setEditing(value);
        if (isFunction(setIsEditing)) {
            setIsEditing(value);
        }
    }

    return (
        <Grid
            container
            {...gridSize(12)}
            spacing={2}
        >
            <Grid
                ref={dropZone}
                {...gridSize(12)}
                className={`abrl-image-editor ${!image ? 'empty' : ''} ${inside ? 'inside' : ''}`}
                onDrop={onDrop}
                onDragEnter={onEnter}
                onDragLeave={onExit}
                onDragOver={e => e.preventDefault()}
            >
                {!image
                    ?
                    <EmptyContent
                        inside={inside}
                        addImage={addImage}
                    />
                    :
                    <ImageContainer
                        src={image}
                        setIsEditing={updateEditing}
                        isEditing={editing}
                        setOutput={setOutput}
                    />}
            </Grid>
            <AnimatePresence mode="wait">
                <EditorActions
                    editing={editing}
                    image={image}
                    loading={loading}
                    onCancel={onCancel}
                    onRemove={onRemove}
                    onSubmit={onSubmit}
                />
            </AnimatePresence>
        </Grid>
    );
};

const EditorActions = ({
    editing,
    onCancel,
    loading,
    image,
    onRemove,
    onSubmit,
}) => {

    return (
        <>
            {editing &&
                <Grid
                    key='image-editor-actions'
                    {...gridSize(12)}
                    className="image-editor-actions"
                    {...withLayout}
                    {...scaleAnimation}
                >
                    <motion.div key='editor-cancel-action' layout>
                        <BaseButton
                            label={'Cancelar'}
                            onClick={onCancel}
                            design="contained"
                            disabled={loading}
                            className="big-cancel-button"
                        />
                    </motion.div>
                    {image &&
                        <motion.div key='editor-remove-action' layout>
                            <BaseButton
                                label={'Eliminar'}
                                onClick={onRemove}
                                design="contained"
                                disabled={loading}
                                className="big-cancel-button"
                            />
                        </motion.div>}
                    <motion.div key='editor-save-action' layout>
                        <BaseButton
                            label={'Guardar'}
                            onClick={onSubmit}
                            design="contained"
                            loading={loading}
                            className="bluenight-button"
                        />
                    </motion.div>
                </Grid>}
        </>
    );
};

const EmptyContent = ({ inside, addImage }) => {

    return (
        <LayoutGroup id="empty-photo-layout">
            <Grid
                key='empty-photo-container'
                {...gridSize(12)}
                container
                className={`empty-photo-container ${inside ? 'inside' : ''}`}
                direction="column"
                spacing={2}
                {...withLayout}
            >
                <Grid
                    key='picture-icon-wrap'
                    {...withLayout}
                >
                    <IconUse icon='Picture' />
                </Grid>
                <Grid
                    key='image-editor-empty-message'
                    {...withLayout}
                >
                    Arrastra la imagen aquí o haz clic en el botón para seleccionarla desde tu equipo.
                </Grid>
                <AnimatePresence>
                    <UploadButton addImage={addImage} show={!inside} />
                </AnimatePresence>
            </Grid>
        </LayoutGroup>
    );
};

const ImageContainer = ({
    src,
    setOutput,
    isEditing,
    setIsEditing,
}) => {

    return (
        <>
            {isEditing ?
                <ImageCrop
                    src={src}
                    setOutput={setOutput}
                />
                :
                <ImagePreview
                    src={src}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />}
        </>
    );
};

const ImagePreview = ({ src, setIsEditing, isEditing }) => {
    const [show, setShow] = useState(false);

    return (
        <Grid
            {...gridSize(12)}
            className="image-editor-preview"
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            onClick={() => !isEditing ? setIsEditing(true) : null}
            component={motion.div}
            {...opacityAnimation}
        >
            <img
                crossOrigin="anonymous"
                alt={uid()}
                src={src}
                style={{
                    border: 'thin solid var(--ovDivider)',
                    overflow: 'hidden',
                    objectFit: 'cover'
                }}
            />
            <AnimatePresence mode="wait">
                {show &&
                    <motion.div
                        key='edit-current-image-mask'
                        className="edit-current-image-mask"
                        {...opacityAnimation}
                    >
                        <FontAwesomeIcon icon={faPenLine} />
                    </motion.div>}
            </AnimatePresence>
        </Grid>
    );
}

ImagePreview.propTypes = {
  isEditing: PropTypes.any,
  setIsEditing: PropTypes.func,
  src: PropTypes.any
}

const ImageCrop = ({ src, setOutput }) => {
    const imgRef = useRef();
    const [crop, setCrop] = useState({ aspect: 1 });

    const onComplete = (PixelCrop) => {
        const image = imgRef.current;
        const canvas = document.createElement('canvas');
        const crop = PixelCrop;

        const ctx = canvas.getContext('2d');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const pixelRatio = window.devicePixelRatio;
        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = 'medium';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        setOutput(canvas);
    }

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const initial = centerAspectCrop(notUndefined(width), notUndefined(height));
        setCrop(initial);
        onComplete(initial);
    };

    const centerAspectCrop = (mediaWidth, mediaHeight) => {
        if (!mediaWidth || !mediaHeight) return;
        const makedspect = makeAspectCrop(
            {
                width: mediaWidth,
                height: mediaHeight,
            },
            1,
            mediaWidth,
            mediaHeight,
        );
        return centerCrop(makedspect, mediaWidth, mediaHeight);
    };

    const notUndefined = (size) => {
        if ([null, undefined, ""].includes(size)) {
            return 50;
        }
        return size;
    };

    return (
        <ReactCrop
            crop={crop}
            onComplete={onComplete}
            onChange={setCrop}
            minWidth={100}
            minHeight={100}
            aspect={1}
        >
            <img
                ref={imgRef}
                crossOrigin="anonymous"
                alt={uid()}
                src={src}
                onLoad={onImageLoad}
                style={{ objectFit: 'cover' }}
            />
        </ReactCrop>
    );
};

const UploadButton = ({ addImage, show = true }) => {
    const inputRef = useRef();

    return (
        <>
            <input
                ref={inputRef}
                type="file"
                accept="image/*"
                hidden
                onChange={(e) => {
                    addImage(first(e.target.files));
                }}
            />
            {show &&
                <Grid
                    key='select-image-button'
                    {...withLayout}
                    {...scaleAnimation}
                >
                    <BaseButton
                        label='Seleccionar foto'
                        onClick={() => inputRef.current?.click()}
                    />
                </Grid>}
        </>
    );
};

ImageEditor.propTypes = {
    src: PropTypes.any,
    handleSubmit: PropTypes.func,
    setIsEditing: PropTypes.func,
    loading: PropTypes.bool
};

EditorActions.propTypes = {
    editing: PropTypes.bool,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
    image: PropTypes.any,
    onRemove: PropTypes.func,
    onSubmit: PropTypes.func,
};

ImageContainer.propTypes = {
    src: PropTypes.any,
    setOutput: PropTypes.func,
    isEditing: PropTypes.bool,
    setIsEditing: PropTypes.func,
};

ImageCrop.propTypes = {
    src: PropTypes.any,
    setOutput: PropTypes.func,
};

UploadButton.propTypes = {
    addImage: PropTypes.func,
    show: PropTypes.bool,
};

EmptyContent.propTypes = {
    inside: PropTypes.bool,
    addImage: PropTypes.func,
};