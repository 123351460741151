import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeWindow, focusWindow } from '../../../../../store/triggers/navigate';
import { hasValue } from '../../../../common/GeneralUtilities';
import { showNotificationWarning } from '../../../../../store/actions';
import { windowCloseWarning } from '../../../../common/notification-messages';
import { Tab } from './Tab';
import { store } from '../../../../../store';
import { isDevMode } from '../../../../modules/User/utils/utilities';
import { last } from 'lodash';
import { AnimatePresence, LayoutGroup } from 'framer-motion';

export const Tabs = () => {
	const dispatch = useDispatch();
	const {
		active_windows, focused_window,
	} = useSelector(state => state.navigate);

	const [active, setActive] = useState(null);

	const setActiveWindow = (key) => {
		dispatch(focusWindow(key));
	};

	const onClose = (key) => {
		if (!/window_[1-6]/.test(key)) {
			return dispatch(showNotificationWarning(windowCloseWarning()));
		}
		dispatch(closeWindow(key));
	};

	useEffect(() => {
		const windowIndex = last(focused_window?.split('_'));
		if (hasValue(focused_window)) {
			setActive(focused_window);
		}
		if (hasValue(windowIndex)) {
			localStorage.setItem('active_index', parseInt(windowIndex) - 1);
		}
		//eslint-disable-next-line
	}, [focused_window]);

	const handleDoubleClick = (e, windowKey) => {
		const { shiftKey } = e;
		if (!shiftKey) return;
		const itemEvent = store.getState().navigate?.[windowKey];
		if (itemEvent?.id && navigator?.clipboard?.writeText && isDevMode) {
			navigator.clipboard.writeText(itemEvent.id);
		}
	}

	const handleClick = (item) => {
		setActiveWindow(item?.window_key);
	}

	return (
		<LayoutGroup id='abrl-windows-tabs'>
			<AnimatePresence>
				{active_windows.map((item) =>
					<Tab
						key={`tab-button-${item?.window_key}`}
						active={active === item?.window_key}
						title={item?.title}
						onClick={() => handleClick(item)}
				onDoubleClick={(e) => handleDoubleClick(e, item?.window_key)}
						onClose={() => onClose(item?.window_key)}
					/>
				)}
			</AnimatePresence>
		</LayoutGroup>
	);
};
