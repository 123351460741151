import React, { useState, useEffect, useContext, createContext } from "react";
import PropTypes from "prop-types";
//--language
import { useTranslation } from "react-i18next";
//--redux
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { lockedWindow, showNotificationError, showNotificationWarning, unlockedWindow, } from "../../../../store/actions";
//--hook
import { useForm, useWatch } from "react-hook-form";
import useAbsenteeismList from "../../../@hooks/useAbsenteeismList";
import { store } from "../../../../store";
//--axios
import { implementService } from "../../../services/implemet-service";
import { parseRequest } from "../../../common/parse-request";
import { payrollTypeApi } from "../../../services/payroll";
import { attendanceListAPI } from "../../../services/RRHH";
import { getUserBranchesAPI, getUserWorkgroupsAPI, workgroupsApi } from "../../../services/administrator";
import { resolveError } from "../../../common/resolve-error";
//--utilities
import { errorGet } from "../../../common/notification-messages";
import { currentGroup, hasErrorTetant } from "../../../common/validate-tenant";
import { collapseMenu, hasValue, parseDatetime, valueOrOption } from "../../../common/GeneralUtilities";
import { excludeInputs, initialPage, initValues, orderOptions } from "../ConstantAndUtilities/constants";
import { payrollPeriodDataConstructor } from '../../../../App/components/Select/PayrollPeriod/searchUtilities';
import { reversDate } from '../../../../App/components/Select/PayrollPeriod/itemUtilities';
import { computedType } from "../../PayrollPeriods/dictionaries";
import { isNil, omitBy, omit, isUndefined, isNumber, first, size, isNull, isEqual } from "lodash";
import useAbrhilWindow from "../../../@components/navigation/contextsAndControllers/useWindow";
import { closeWindow } from "../../../../store/triggers/navigate";
import { payrollPeriodItemvalueRender } from "../../../../App/components/Select/PayrollPeriod/renderTemplates";
import { resetFilters } from "../ConstantAndUtilities/utilities";

const useController = () => {
  const tenant = useSelector((state) => state.tenant);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const refreshAttendance = useSelector(state => state.attendance);
  //perms
  const attendancePerms = store.getState().userConfigs.user_info?.moper_attendance;
  const activeDaysPerms = store.getState().userConfigs.user_info?.active_days;
  const groupConfigPerms = store.getState().userConfigs?.user_info?.groups_perms;
  const userCountry = store.getState().app?.country;
  const { select } = useSelector((state) => state.group);
  //grid
  const [resetData, setResetData] = useState(false);
  const [init, setInit] = useState(false);
  const [data, setData] = useState([]);
  const [auxData, setAuxData] = useState([]);
  const [page, setPage] = useState(initialPage);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({ filters: initValues });
  const [dateColumns, setDateColumns] = useState([]);
  const [listType, setListType] = useState("compact");
  //worker filter
  const [workerSelection, setWorkerSelection] = useState([]);
  const [wloader, setWloader] = useState(false);
  //topBar && advanceFilters
  const [periods, setPeriods] = useState([]);
  const [workgroups, setWorkgroups] = useState([]);
  const [workgroupsFromBranch, setWorkgroupsFromBranch] = useState([]);
  const [wgBranchTotal, setWgBranchTotal] = useState(0);
  const [acessSourceTotal, setAcessSourceTotal] = useState({
    workgroup: null,
    branch: null,
  });
  const [branches, setBranches] = useState([]);

  const [payrollType, setPayrollType] = useState([]);
  const { absenteeism, getAbsenteeismList } = useAbsenteeismList();
  const [periodSelected, setPeriodSelected] = useState({});
  const [isReloaded, setIsReloaded] = useState(false);
  const [signListPerm, setSignListPerm] = useState(false);
  //Calendar
  const [openCalendar, setOpenCalendar] = useState(false);
  //Mopers modal
  const [searchParams, setSearchParams] = useSearchParams();
  //FootBar
  const [openFootbar, setOpenFootbar] = useState(false);
  //User selection list info modal
  const [loadInitial, setLoadInitial] = useState(false);
  const [listTypeInfoModal, setListTypeInfoModal] = useState(false);
  const actualGroupInfo = groupConfigPerms?.find(element => element?.group?.id === select?.id) ?? groupConfigPerms[0];
  const [verifyData, setVerifyData] = useState([]);
  const [lastWorkgroup, setLastWorkgroup] = useState(null);

  //Search Worker
  const [worker, setWorker] = useState(null);
  const [openWorkerSearch, setOpenWorkerSearch] = useState(false);
  const [reloadWorker, setReloadWorker] = useState(false);
  const [lastAccessFilters, setLastAccessFilters] = useState({});

  const [loadingPeriod, setLoadingPeriod] = useState(false);
  const [reloadAccessData, setReloadAccessData] = useState(true);

  const handleOpenSearch = () => {
    setOpenWorkerSearch(true);
  }

  const handleCloseSearch = () => {
    setOpenWorkerSearch(false);
  }
  // Window focused
  const {
    isFocused,
    window_key
  } = useAbrhilWindow();

  const {
    control,
    setValue,
    getValues,
    reset
  } = useForm({
    defaultValues: initValues,
    mode: "onChange",
  });

  const payrollPeriod = useWatch({ control, name: 'period' });
  const payrollTypeValue = useWatch({ control, name: 'payroll_type' });
  const currentWorker = useWatch({ control, name: 'cell_selected' });
  const currentWorkgroup = useWatch({ control, name: 'workgroup' });
  const typeAbsenteeism = useWatch({ control, name: 'type_absenteeism' });
  const userSelection = useWatch({ control, name: 'user_access' });
  const onlyBranch = useWatch({ control, name: 'only_branch' });
  const branch = useWatch({ control, name: 'branch' });
  const month = useWatch({ control, name: 'month' });
  const year = useWatch({ control, name: 'year' });
  const activeDates = useWatch({ control, name: 'active_date' });

  const userHaveAllAccess = () => {
    const canEdit = parseInt(getValues('user_access')) === 0;
    return canEdit;
  }

  function handleRefresh() {
    setValue('view_switch_period_filters', true, { shouldValidate: true });
    setValue('view_switch_access_filters', true, { shouldValidate: true });
    handleCloseSearch();
    setInit(true);
    getData();
  }

  function handleRefreshWorker(all = false, item = []) {
    if (all) {
      handleRefresh();
      return;
    }
    setValue('view_switch_period_filters', true, { shouldValidate: true });
    setValue('view_switch_access_filters', true, { shouldValidate: true });
    handleCloseSearch();
    if (!hasValue(filter?.worker_key) || hasValue(item)) {
      filter.worker_key = hasValue(item) ? item?.key : currentWorker?.key;
    }
    setInit(true);
    getData(true);
  }

  function handlePage(event) {
    setInit(true);
    setPage({
      ...page,
      skip: event.skip,
      take: event.take,
    });
  }

  // filters
  const handleFilter = (
    reload = true,
  ) => {
    handleCloseSearch();
    if (listTypeInfoModal || isUndefined(getValues('user_access')) || !isFocused) {
      return;
    }

    setInit(reload);
    const filters = {
      ...filter,
      ...getValues(),
    }
    if (filters?.workgroup || filters?.branch || filters?.employer_registration) {
      setWorkerSelection(null);
      const access = userHaveAllAccess();

      if (hasValue(workgroups) && access) {
        const initialWorkgroups = workgroups.filter(element => element?.id !== 0);
        setWorkgroups(initialWorkgroups);
      } else if (hasValue(branches) && !access) {
        const initialBranches = branches.filter(element => element?.id !== 0);
        setBranches(initialBranches);
        const initialWorgroupsByBranch = workgroupsFromBranch.filter(element => element?.id !== 0);
        setWorkgroupsFromBranch(initialWorgroupsByBranch);
      }
      setWorkerSelection(null);
      delete filters?.worker_key;
    }
    reset(filters);
    parseInt(filters?.workgroup) === 0 && delete filters?.workgroup;
    parseInt(filters?.branch) === 0 && delete filters?.branch;
    parseInt(filters?.workgroup_by_branch) === 0 && delete filters?.workgroup_by_branch;
    setFilter(filters);
  }

  const onAdvanceFilter = (newFilters) => {
    handleCloseSearch();
    setInit(true);
    let actualValues = getValues();
    if (!hasValue(newFilters?.filters)) {
      const access = userHaveAllAccess();
      actualValues = omitBy(Object.fromEntries(
        Object.entries(actualValues).map(([key, value]) => [
          key,
          excludeInputs.includes(key) ? value : undefined,
        ])
      ), isNil);
      setWorkerSelection(null);
      const initialWorkgroups = workgroups?.filter(element => element?.id !== 0);
      setWorkgroups(initialWorkgroups);
      const initialBranches = branches?.filter(element => element?.id !== 0);
      setBranches(initialBranches);
      const initialWorgroupByBranch = workgroupsFromBranch?.filter(element => element?.id !== 0);

      setWorkgroupsFromBranch(initialWorgroupByBranch);
      const principalWorkgroup = workgroups.find(element => element?.is_my_workgroup)?.id;
      const newWorkgroup = access ? (principalWorkgroup ?? workgroups.find(element => element?.id !== 0)) : null;
      const newBranch = access ? null : branches.find(element => element?.id !== 0);
      const newWorkgroupByBranch = access ? null : workgroupsFromBranch.find(element => element?.id !== 0);
      setValue('workgroup', newWorkgroup?.id ?? newWorkgroup, { shouldValidate: true });
      setValue('branch', newBranch?.id ?? newBranch, { shouldValidate: true });
      setValue('workgroup_by_branch', workgroupsFromBranch?.id ?? newWorkgroupByBranch, { shouldValidate: true });
      onCleanWorker();

      actualValues = {
        ...actualValues,
        workgroup: getValues('workgroup'),
        branch: getValues('branch'),
        workgroup_by_branch: getValues('workgroup_by_branch'),
      }
    }
    const filtersToUpdate = newFilters?.filters;
    const filters = {
      ...actualValues,
      ...initValues,
      ...filtersToUpdate,
      attendance_mode: listType,
      only_branch: getValues('only_branch'),
      user_access: parseInt(getValues('user_access')),
      ...(filtersToUpdate?.workgroup ? {
        workgroup: filtersToUpdate?.workgroup,
        worker_key: null,
      } : {}),
    };

    if (!hasValue(filtersToUpdate?.filter_type) && hasValue(filters?.filter_type)) {
      delete filters.filter_type;
    }

    if (!hasValue(filtersToUpdate?.absenteeism) && hasValue(filters?.absenteeism)) {
      delete filters.absenteeism;
    }

    if (isNull(filters?.worker_key)) {
      setWorkerSelection([]);
      delete filters?.worker_key;
    }

    if (userHaveAllAccess()) {
      delete filters?.branch;
      delete filters?.workgroup_by_branch;
    } else {
      delete filters?.workgroup;
    }
    filters.ordering = orderOptions.find((element) => element?.value === filters?.sort_by)?.ordering;

    if (!filters?.active_date && filters?.period && hasValue(periodSelected)) {
      filters.initial_date = periodSelected?.start_admin_court_date;
      filters.end_date = periodSelected?.end_admin_court_date;
    }

    reset(filters);
    if (filters?.worker_key) {
      setValue('workgroup', 0, { shouldValidate: true });
      delete filters?.workgroup;
      setValue('branch', 0, { shouldValidate: true });
      delete filters?.branch;
      setValue('workgroup_by_branch', 0, { shouldValidate: true });
      delete filters?.workgroup_by_branch;
      setInit(true);
    }

    setFilter(filters);
  };

  const workerFilter = () => {
    handleCloseSearch();
    const actualValues = filter;
    const newFilters = {
      ...actualValues,
      worker_key: workerSelection,
    }
    delete newFilters?.workgroup;
    delete newFilters?.branch;
    delete newFilters?.workgroup_by_branch;
    const access = userHaveAllAccess();

    if (hasValue(workgroups) && access && !workgroups.find(element => element?.name === "...")) {
      setWorkgroups(prevValues => ([
        { id: 0, key: '', name: '...' },
        ...prevValues,
      ]));
    } else if (hasValue(branch) && !access && !branches.find(element => element?.name === "...")) {
      setBranches(prevValues => ([
        { id: 0, key: '', name: '...' },
        ...prevValues,
      ]));

      setWorkgroupsFromBranch(prevValues => ([
        { id: 0, key: '', name: '...' },
        ...prevValues,
      ]));
    }
    const event = { filters: newFilters };
    onAdvanceFilter(event);
  }

  const orderFilter = (e) => {
    const values = getValues();
    const newValues = e?.filters;

    const newFilters = {
      year: values?.year,
      period: values?.period,
      payroll_type: values?.payroll_type,
      workgroup: values?.workgroup,
      ...newValues
    }

    const event = { filters: newFilters };
    onAdvanceFilter(event);
  }

  const onChangeAccessMode = () => {
    handleCloseSearch();
    setWorkerSelection([]);
    setData([]);
    setDateColumns([]);
    setResetData(true);
    setInit(false);
    setPage(initialPage);
    setTotal(0);
    setListType("compact");
    const actualFilters = {
      ...filter,
    }
    delete actualFilters?.worker_key;
    delete actualFilters?.workgroup;
    delete actualFilters?.branch;
    delete actualFilters?.workgroup_by_branch;
    const newAccess = parseInt(userSelection) === 0 ? 1 : 0
    setValue('user_access', newAccess, { shouldValidate: true });
    setValue('attendance_mode', "compact", { shouldValidate: true });
    const newFilters = resetFilters(getValues(), newAccess);
    reset(newFilters);
    handleInitialWorgroupFilter(false);
  }


  const initialPeriod = async (payrollType) => {
    if (hasValue(payrollType)) {
      const {
        data,
        current,
      } = await payrollPeriodDataConstructor({
        computed_type: computedType.normal,
        payroll_type: payrollType,
        ...((isNil(getValues('year')) || isNaN(getValues('year'))) ? {} : { current_year: getValues('year') }),
        ...((isNil(month) || isNaN(month)) ? {} : { current_month: month }),
        lockWindow: false
      });
      setPeriods(data);
      const first = current ?? data?.[0]?.id;
      setValue('period', first, { shouldValidate: true });
      if (month) {
        const periodData = data.find(element => element?.id === first);
        if (first !== periodSelected?.id) {
          setPeriodValues(periodData);
          setPeriodSelected(periodData);
          return;
        }
        handleFilter(false);
      }
      setLoadingPeriod(false);
    }
  }

  const onReloadPeriod = () => {
    setLoadingPeriod(true);
    initialPeriod(payrollTypeValue);
  }

  const valueRender = (li, item) => {
    return payrollPeriodItemvalueRender(li, item, () => onReloadPeriod())
  };

  const setPeriodValues = (periodData) => {
    const administrativeCourt = `${reversDate(periodData?.start_admin_court_date) ?? ''} - ${reversDate(periodData?.end_admin_court_date) ?? ''}`;
    setValue('administrative_court', administrativeCourt ?? '');
    const openPeriodDate = parseDatetime(periodData?.locked_at, "dd/MM/yyyy HH:mm a");
    const startDate = periodData?.start_admin_court_date;
    const endDate = periodData?.end_admin_court_date;
    setValue('open_period_date', openPeriodDate, { shouldValidate: true });
    setValue('open_period_date', openPeriodDate, { shouldValidate: true });
    setValue('initial_date', startDate, { shouldValidate: true });
    setValue('end_date', endDate, { shouldValidate: true });
    setValue('month', parseInt(periodData?.current_month), { shouldValidate: true });
    if (listTypeInfoModal) {
      return;
    }
    handleFilter(false);
  }

  // grid

  function handleChangeList() {

    if (!hasValue(data)) {
      return;
    }
    const newType = listType === "compact" ? "detail" : "compact";
    setValue('attendance_mode', newType, { shouldValidate: true });
    handleFilter(false);
    setListType(newType);
  }

  const handleWorkerSelection = (selection) => {
    saveAccessFilters();
    setWorkerSelection(selection?.key);
    handleCloseSearch();
    setReloadWorker(hasValue(selection?.key));
  }

  const saveAccessFilters = () => {
    const { workgroup, workgroup_by_branch, branch } = getValues() ?? {};

    let newAccess = {
      ...lastAccessFilters,
    };

    if (hasValue(workgroup) && workgroup !== 0 && userHaveAllAccess()) {
      newAccess = {
        ...newAccess,
        workgroup: workgroup,
      }
    }
    if (hasValue(branch) && branch !== 0 && !userHaveAllAccess()) {
      newAccess = {
        ...newAccess,
        branch: branch,
      }
    }
    if (hasValue(workgroup_by_branch) && workgroup_by_branch !== 0 && !userHaveAllAccess()) {
      newAccess = {
        ...newAccess,
        workgroup_by_branch: workgroup_by_branch,
      }
    }

    if (!isEqual(newAccess, lastAccessFilters)) {
      setLastAccessFilters(newAccess);
    }
  }

  const handleClick = ({ dataItem }) => {
    const data = omit(dataItem, 'attendance_list');
    setValue('cell_selected', {
      ...data,
    });
    setValue('cell_date', null, { shouldValidate: true });
  }

  const isWorkerSelected = ({ workerKey }) => {
    return currentWorker?.key === workerKey;
  }

  const onReloadAccess = () => {
    setAcessSourceTotal({
      workgroup: null,
      branch: null,
    });
    setReloadAccessData(true);
  }
  // modals

  const handleOpenCalendar = () => {
    setOpenCalendar(true);
  }

  const handleCloseCalendar = () => {
    setOpenCalendar(false);
  }

  const handleOpenModalSelection = () => {
    setListTypeInfoModal(true);
  }

  const handleCloseModalSelection = () => {
    setListTypeInfoModal(false);
    handleInitialWorgroupFilter();
  }

  const handleOpenFootBar = () => {
    setOpenFootbar(true);
  }

  const handleCloseFootBar = () => {
    setOpenFootbar(false);
  }

  // @get
  const getData = async (all = false) => {
    if ((!filter?.workgroup && !filter?.branch && !filter?.worker_key) || (parseInt(filter?.workgroup) === 0 || parseInt(filter?.branch) === 0 || parseInt(filter?.employer_registration) === 0) && listTypeInfoModal) {
      return;
    }

    let newFilters = filter;
    if (newFilters?.payroll_type === 99) {
      newFilters = omit(newFilters, 'payroll_type');
    }

    if (hasValue(newFilters?.workgroup_by_branch) && !hasValue(newFilters?.workgroup)) {
      delete newFilters.branch;
      newFilters.workgroup = newFilters?.workgroup_by_branch;
    }

    const request = parseRequest(
      {
        include:
          "absenteeisms,enterprise_structure_organization.enterprise_structure.*,enterprise_structure_organization.employer_registration.*,workgroup,enterprise_structure_organization.settings.*",
        current_user_group: currentGroup(),
      },
      newFilters,
      filter?.worker_key ? initialPage : page,
      {
        contract_start: "contract_end_gte",
        contract_end: "contract_end_lte",
        contract_switch: "is_permanent",
        low_start: "leave_gte",
        low_end: "leave_lte",
      }
    );

    implementService(attendanceListAPI.get(request), (response) => {
      dispatch(lockedWindow());

      if (!all) {
        updateGrid(response);
        return;
      }
      const reloadWorker = valueOrOption(first(response?.results), {});

      const resultsData = auxData.map(element => {
        if (element?.key === reloadWorker?.key) {
          return reloadWorker;
        }
        return element;
      })

      const newResponse = {
        count: total,
        results: resultsData,
      }
      if (filter?.workgroup) {
        delete filter?.worker_key;
      }
      updateGrid(newResponse);
    },
      (error) => {
        dispatch(unlockedWindow());
        const errorInfo = error?.response?.data;
        if (errorInfo?.key === 'worker-with-low') {
          dispatch(unlockedWindow());
          lowNotification(errorInfo?.detail);
          return;
        }
        resolveError(error);
      },
      false,
    );
  }

  // format data
  const updateGrid = (data) => {
    setAuxData(data?.results);
    setTotal(data?.count);
    setData(data?.results);
    setResetData(true);
  }

  const lowNotification = (message) => {
    dispatch(showNotificationWarning({ message: message, onConfirm: onCleanWorker }));
  }

  async function getWorkgroups() {
    if (hasErrorTetant()) {
      return;
    }
    const params = {
      tree: true,
      ordering: "-name",
    };

    implementService(getUserWorkgroupsAPI.get(params), response => {
      setWorkgroups(
        response.filter((f) => f.type === 1)
      );
      setAcessSourceTotal((prevValues) => ({
        ...prevValues,
        workgroup: 1
      }));
    }, (error) => {
      if (error.response.status === 403) {
        dispatch(showNotificationWarning({ message: error.response?.data?.detail }));
      } else {
        dispatch(showNotificationError(errorGet(error)));
      }
    }, false, {}, false);
  }

  async function getBranches() {
    if (hasErrorTetant()) {
      return;
    }

    const params = {
      current_user_group: currentGroup(),
      is_active: true
    }

    implementService(getUserBranchesAPI.get(params), response => {
      setBranches(response);
      setAcessSourceTotal((prevValues) => ({
        ...prevValues,
        branch: 1
      }));
    }, (error) => {
      dispatch(showNotificationError(errorGet(error)));
    }, false, {}, false);
  }

  async function getWorgroupsByBranch() {
    if (hasErrorTetant()) {
      return;
    }
    setWloader(true);
    const request = parseRequest({
      current_user_group: currentGroup(),
      branch: branch,
      tree: "",
      type: 1,
      fields: 'id,key,name,type',
    });
    implementService(workgroupsApi.get(request), response => {
      const data = response.filter((f) => f?.type === 1);
      setWorkgroupsFromBranch(data);
      setWgBranchTotal(1);
      if (size(data) >= 1) {
        const workgroupsByBranch = data[0]?.id;
        setValue("workgroup_by_branch", workgroupsByBranch);
        setFilter(prevValues => ({
          ...prevValues,
          workgroup_by_branch: workgroupsByBranch
        }));
      }
    });
  }

  async function getPayrollType() {
    if (hasErrorTetant()) {
      return;
    }

    const params = {
      is_active: true,
      tree: true,
      include: "periodicity",
    };

    implementService(payrollTypeApi.get(params), (response) => {
      const defaultType = response.find((f) => f.is_predetermined)?.id;
      setValue("payroll_type", defaultType);
      response.unshift({ id: 99, key: '*', name: t('_all').toUpperCase() });
      setPayrollType(response);
    }, null, false, {}, false);
  }

  const onCleanWorker = () => {
    const { workgroup, branch, workgroup_by_branch } = lastAccessFilters ?? {};
    setValue('worker_key', null, { shouldValidate: true });
    setValue('workgroup', workgroup, { shouldValidate: true });
    setValue('branch', branch, { shouldValidate: true });
    setValue('workgroup_by_branch', workgroup_by_branch, { shouldValidate: true });
    handleFilter();
  }

  const handleInitialWorgroupFilter = (withReload = false) => {
    if (!isFocused) {
      return;
    }
    const access = userHaveAllAccess();
    const principalWorkgroup = workgroups.find(element => element?.is_my_workgroup)?.id;

    const bothAccess = (hasValue(branches) && hasValue(workgroups)) && listTypeInfoModal;
    const bothAccessWorkgroup = bothAccess ? getValues('workgroup') : (principalWorkgroup ?? workgroups[0]?.id);
    const newWorkgroup = access ? bothAccessWorkgroup : null;
    const bothAccessBranches = bothAccess ? getValues('branch') : branches[0]?.id;
    const newBranch = access ? null : bothAccessBranches;
    const bothAccessWorkgroupByBranch = bothAccess ? getValues('workgroup_by_branch') : workgroupsFromBranch[0]?.idM
    const newWorkgroupByBranch = access ? null : bothAccessWorkgroupByBranch;
    setValue('workgroup', newWorkgroup, { shouldValidate: true });
    setValue('branch', newBranch, { shouldValidate: true });
    setValue('workgroup_by_branch', newWorkgroupByBranch, { shouldValidate: true });
    handleFilter(withReload);
  }

  const reloadAccessFilters = () => {
    setReloadAccessData(false);
    if (!hasValue(branches) && !hasValue(workgroups)) {
      dispatch(showNotificationWarning({
        message: t('No cuenta con grupos de trabajo o sucursales asignados.'), onConfirm: () => {
          dispatch(closeWindow(window_key));
        }
      }));
      return;
    }

    const lastUserAccess = getValues('user_access');
    const newAccess = hasValue(workgroups) ? 0 : 1;
    const lastSwitchView = !getValues('view_switch_access_filters');
    if (newAccess === 1 && lastUserAccess === 0) {
      setValue('user_access', newAccess, { shouldValidate: true });
      setValue('view_switch_access_filters', lastSwitchView, { shouldValidate: true });
      const newFilters = resetFilters(getValues(), newAccess);
      reset(newFilters);
      handleInitialWorgroupFilter(true);
    }
  }

  const verifyMoper = async (id) => {
    setLastWorkgroup(id);
    await implementService(workgroupsApi.getOne(id, {
      include: "mopers.*",
    }), (response) => {
      setVerifyData({
        isMyWorkgroup: response?.is_my_workgroup,
        mopers: response?.mopers
      });
      const signListItem = response?.mopers?.find(element => element?.moper_catalog?.key === "firmar_lista");
      setSignListPerm(signListItem?.is_active);
    }, null, false, {}, false);
  }

  useEffect(() => {
    const itemWorkgroup = first(data)?.workgroup?.id;
    if (!hasValue(data) || (itemWorkgroup !== currentWorkgroup && !filter?.worker_key)) {
      return;
    }
    const workgroupId = (currentWorkgroup > 0 && !filter?.worker_key) ? currentWorkgroup : itemWorkgroup;
    if (hasValue(currentWorkgroup) && isNumber(currentWorkgroup) && lastWorkgroup !== workgroupId) {
      verifyMoper(workgroupId);
    }
  }, [currentWorkgroup, filter?.worker_key, data]);

  useEffect(() => {
    const value = actualGroupInfo?.group?.config?.only_branch;
    setValue('only_branch', isUndefined(value) ? true : value);
  }, [actualGroupInfo]);

  useEffect(() => {
    if (hasValue(branch) && !filter?.worker_key) {
      getWorgroupsByBranch();
    }
  }, [branch]);

  useEffect(() => {
    if (!acessSourceTotal?.workgroup || !acessSourceTotal?.branch) {
      return;
    }
    if (!isReloaded && isFocused) {
      setReloadAccessData(false);
      if (!hasValue(branches) && !hasValue(workgroups)) {
        dispatch(showNotificationWarning({
          message: t('No cuenta con grupos de trabajo o sucursales asignados.'), onConfirm: () => {
            dispatch(closeWindow(window_key));
          }
        }));
      }
      if (hasValue(branches) && hasValue(workgroups) && !reloadAccessData) {
        setIsReloaded(true);
        setValue('user_access', 0, { shouldValidate: true });
        handleInitialWorgroupFilter(true);
      }
    }
    // eslint-disable-next-line
  }, [branches, workgroups, isFocused]);

  useEffect(() => {
    if (!acessSourceTotal?.workgroup || !acessSourceTotal?.branch || !hasValue(payrollPeriod) || !hasValue(payrollType) || !isFocused || !isUndefined(userSelection) || listTypeInfoModal || !loadInitial) {
      return;
    }
    setReloadAccessData(false);
    setLoadInitial(false);
    setIsReloaded(true);
    setValue('user_access', hasValue(workgroups) ? 0 : 1, { shouldValidate: true });
    handleInitialWorgroupFilter();
  }, [loadInitial, isFocused]);

  useEffect(() => {
    if (hasValue(workerSelection) && reloadWorker) {
      setReloadWorker(false);
      workerFilter();
    }
    // eslint-disable-next-line
  }, [reloadWorker]);

  useEffect(() => {
    if (isReloaded && !listTypeInfoModal) {
      handleFilter(true);
    }
  }, [listTypeInfoModal]);

  useEffect(() => {

    if (!hasValue(payrollPeriod) && isReloaded && !listTypeInfoModal) {
      dispatch(showNotificationWarning({ message: t('El tipo de nómina seleccionado, no cuenta con periodos de nómina') }));
      setInit(false);
      setPeriodValues(null);
      setPeriodSelected({});
      setDateColumns([]);
      setWorkerSelection([]);
      setData([]);
      setResetData(true);
      return;
    }
    if (!hasValue(payrollPeriod)) {
      return
    }
    setInit(false);
    const foundPeriod = periods?.find(el => el?.id === payrollPeriod);
    if (!hasValue(foundPeriod)) {
      return;
    }
    setPeriodValues(foundPeriod);
    setPeriodSelected(foundPeriod);
    // eslint-disable-next-line
  }, [payrollPeriod]);

  useEffect(() => {
    if (hasValue(periods) && !month && payrollPeriod) {
      const foundPeriod = periods?.find(el => el?.id === payrollPeriod);
      setPeriodValues(foundPeriod);
      setPeriodSelected(foundPeriod);
    }
  }, [periods, payrollPeriod]);

  useEffect(() => {
    if (hasValue(payrollType)) {
      initialPeriod(payrollTypeValue);
    }
    // eslint-disable-next-line
  }, [payrollTypeValue, month, year]);

  useEffect(() => {
    setResetData(false);
    if (!data.length && page.skip && init) {
      setPage(initialPage);
    }
    dispatch(unlockedWindow());
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    saveAccessFilters();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (!init) {
      return;
    }
    setValue('cell_selected', null, { shouldValidate: true });
    setValue('cell_date', null, { shouldValidate: true });
    handleRefresh();
    // eslint-disable-next-line
  }, [page, filter]);

  useEffect(() => {
    if (!isFocused) {
      handleCloseModalSelection();
    }
  }, [isFocused]);

  useEffect(() => {
    if (!isReloaded && hasValue(payrollPeriod) && hasValue(payrollType) && acessSourceTotal?.workgroup && acessSourceTotal?.branch) {
      setLoadInitial(true);
    }
  }, [payrollPeriod, payrollType, isReloaded, workgroups, branches]);

  useEffect(() => {
    setWgBranchTotal(0);
    setDateColumns([]);
    setWorkerSelection([]);
    setData([]);
    setTotal(0);
    setInit(false);
    setIsReloaded(false);
    setPage(initialPage);
    setFilter(initValues);
    getPayrollType();
    getAbsenteeismList(false);
    // eslint-disable-next-line
  }, [tenant]);

  useEffect(() => {
    if (reloadAccessData && isEqual({
      workgroup: null,
      branch: null,
    }, acessSourceTotal)) {
      getWorkgroups();
      getBranches();
    }
    // eslint-disable-next-line
  }, [reloadAccessData, acessSourceTotal]);

  useEffect(() => {
    if (reloadAccessData && acessSourceTotal?.workgroup && acessSourceTotal?.branch) {
      reloadAccessFilters();
    }
    // eslint-disable-next-line
  }, [branches, workgroups, acessSourceTotal]);

  useEffect(() => {
    collapseMenu();
    if (searchParams.has('worker')) {
      searchParams.delete('worker');
      searchParams.delete('index');
      searchParams.delete('force');
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (wloader && (hasValue(workgroupsFromBranch) || wgBranchTotal > 0)) {
      setWgBranchTotal(0);
      setWloader(false);
    }
  }, [workgroupsFromBranch]);

  useEffect(() => {
    if (refreshAttendance.show) {
      handleRefresh();
    }
  }, [refreshAttendance]);

  useEffect(() => {
    setResetData(false);
  }, [listType]);

  return {
    //grid
    resetData,
    data,
    init,
    page,
    total,
    filter,
    handlePage,
    handleFilter,
    handleRefresh,
    dateColumns,
    listType,
    currentWorker,
    handleClick,
    isWorkerSelected,
    handleChangeList,
    workerSelection,
    handleWorkerSelection,
    //advanceFilters
    setFilter,
    onAdvanceFilter,
    periods,
    setPeriods,
    periodSelected,
    payrollType,
    workgroups,
    absenteeism,
    branches,
    workgroupsFromBranch,
    onChangeAccessMode,
    orderFilter,
    //hook
    control,
    setValue,
    getValues,
    //Perms
    attendancePerms,
    activeDaysPerms,
    //calendar
    handleOpenCalendar,
    openCalendar,
    handleCloseCalendar,
    typeAbsenteeism,
    //ListType user selection modal
    listTypeInfoModal,
    handleCloseModalSelection,
    handleOpenModalSelection,
    //User Access
    userHaveAllAccess,
    onlyBranch,
    branch,
    payrollPeriod,
    acessSourceTotal,
    //FootBar
    handleOpenFootBar,
    handleCloseFootBar,
    openFootbar,
    userCountry,
    //SearchWorker
    worker,
    setWorker,
    handleOpenSearch,
    handleCloseSearch,
    openWorkerSearch,
    signListPerm,
    handleRefreshWorker,
    verifyData,
    wloader,
    activeDates,
    onCleanWorker,
    initialPeriod,
    valueRender,
    loadingPeriod,
    onReloadAccess,
    reloadAccessData
  };
}


export const AttendanceContext = createContext();

export const AttendanceProvider = ({ children, }) => (
  <AttendanceContext.Provider value={useController()}>
    {children}
  </AttendanceContext.Provider>
);

AttendanceProvider.propTypes = {
  children: PropTypes.any,
}

export const useAttendance = () => useContext(AttendanceContext);

export default useAttendance;