import { each, findIndex, isUndefined } from "lodash";
import { getStructureFields } from "../filter/structureFilters";

export const isVisible = (title, columns) => {
    const data = columns;
    const foundItem = data.find(item => item.value === title);
    const isInArray = foundItem !== undefined;
    return isInArray;
};

export const getExtras = (column, filters, structure, extraFilters = {}) => {
    const index = findIndex(structure, function (o) { return o.step === column; });
    const considere = getStructureFields(index, structure, "before");
    const values = filters?.filters || filters || {};

    each(considere, extra => {
        const extraValue = values[extra];
        if (!isUndefined(extraValue) && extraValue !== null && extraValue !== "") {
            extraFilters[extra] = extraValue;
        }
    });

    return extraFilters;

};

export const changeCallback = (filters, callback, column, structure) => {
    const index = findIndex(structure, function (o) { return o.step === column; });
    const afterFields = getStructureFields(index, structure, "after");
    const newFilters = filters?.filters || filters || {};
    each(afterFields, step => {
        if (newFilters[step]) {
            delete newFilters[step];
        }
    });
    callback({ filters: newFilters });
};
