import { lazy } from "react";
import { faUtensils } from "@fortawesome/pro-light-svg-icons";

const moduleItems = [
    // RAIZ //
    {
        id: "modules@inability",
        type: "item",
        icon: 'sick-leaves',
        showInMenu: false,
        // componentElm: lazy(async ()  => import("../../../../modules/Inability")),
    },
    {
        id: "modules@contracts-and-formats",
        type: "item",
        icon: 'contractsandformats',
        componentElm: lazy(async ()  => import("../../../../modules/Worker/Contracts")),
    },
    {
        id: "modules@dining-room",
        type: "item",
        icon: faUtensils,
        inConstruction: true,
        componentElm: true
    },
    // CAPACITACION //
    {
        id: "modules@training",
        type: "item",
        icon: "training",
        inConstruction: true,
        componentElm: true
    },
    {
        id: "modules@courses",
        type: "item",
        package_permissions: ["training"],
        componentElm: lazy(async ()  => import('../../../../modules/Courses')),
    },
    {
        id: "modules@courses-by-worker",
        type: "item",
        package_permissions: ["training"],
        componentElm: lazy(async ()  => import('../../../../modules/CoursesByWorker')),
    },
    {
        id: 'modules@txt-util',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/OvertimeUtilities')),
    },
    {
        id: "modules@establishment-key",
        type: "item",
        package_permissions: ["training", "training.establishment_key"],
        componentElm: lazy(async ()  => import('../../../../modules/EstablishmentKey')),
    },
    {
        id: "abrhil@training-objectives",
        type: "item",
        package_permissions: ["training", "training.training_goals"],
        componentElm: lazy(async ()  => import('../../../../modules/TrainingObjectives')),
    },
    {
        id: "abrhil@intitution-types",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/InstitutionTypes')),
    },
    {
        id: "modules@study-levels",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/StudyLevels')),
    },
    {
        id: 'modules@education-mapping',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/Education')),
    },
    {
        id: "modules@disabilities",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/Disabilities')),
    },
    {
        id: "abrhil@training-modalities",
        type: "item",
        package_permissions: ["training", "training.modality"],
        componentElm: lazy(async ()  => import('../../../../modules/TrainingModalities')),
    },
    {
        id: "abrhil@thematic-areas",
        type: "item",
        package_permissions: ["training", "training.thematic_area"],
        componentElm: lazy(async ()  => import('../../../../modules/ThematicAreas')),
    },
    {
        id: "abrhil@productive-area",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/ProductiveArea')),
    },
    {
        id: "abrhil@occupations-workers",
        type: "item",
        package_permissions: ["training", "training.national_catalogs_of_occupations"],
        componentElm: lazy(async ()  => import('../../../../modules/OccupationsWorkers')),
    },
    {
        id: "abrhil@agents-types",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/AgentsType')),
    },
    {
        id: "modules@job-mapping",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/JobMapping')),
    },
    {
        id: "abrhil@courses-classifications",
        type: "item",
        package_permissions: ["training"],
        componentElm: lazy(async ()  => import('../../../../modules/CoursesClassifications')),
    }, {
        id: "modules@training-places",
        type: "item",
        package_permissions: ["training"],
        componentElm: lazy(async ()  => import('../../../../modules/TrainingPlaces')),
    },
    {
        id: "modules@reason-for-absence",
        type: "item",
        package_permissions: ["training"],
        componentElm: lazy(async ()  => import('../../../../modules/ReasonAbsence')),
    }, {
        id: "modules@instructors",
        type: "item",
        package_permissions: ["training"],
        componentElm: lazy(async ()  => import('../../../../modules/InstructorsTraining')),
    },
    {
        id: "modules@courses-catalog",
        type: "item",
        package_permissions: ["training"],
        componentElm: lazy(async ()  => import('../../../../modules/CoursesCatalog')),
    },
    {
        id: "abrhil@status",
        type: "item",
        package_permissions: ["training"],
        componentElm: lazy(async ()  => import('../../../../modules/StatusTraining')),
    },
    // RECURSOS HUMANOS //
    {
        id: 'modules@attendance-catalogs',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@timbrasoft-catalogs',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@timbrasoft-settings',
        package_permissions: ["payroll_package"],
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/ConfigTimbrasoft')),
    },
    {
        id: 'modules@payroll-catalogs',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@payroll-settings',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/SettingsPayroll')),
    },
    {
        id: 'modules@training-settings',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/SettingsTraining')),
    },
    {
        id: 'modules@attendance-settings',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/SettingsAttendance')),
    },
    {
        id: 'modules@payroll-report',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@imss-catalogs',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@imss-settings',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules//SettingsIMSS')),
    },
    {
        id: 'modules@human-resources-catalogs',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@human-resources-settings',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/SettingsRH')),
    },
    {
        id: "modules@massive-salary-increases",
        type: "item",
        componentElm: lazy(async ()  => import('../../../../modules/MassiveSalaryIncreases')),
    },
    {
        id: 'modules@users-list',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/User')),
        extraProps: { type: 'attendance' }
    },
    {
        id: 'modules@mopers-signatures-general',
        type: 'item',
        icon: 'mopers',
        componentElm: lazy(async ()  => import('../../../../modules/MOPERSSignature')),
    },
    {
        id: 'modules@working-days',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Journeys')),
    },
    {
        id: 'modules@schedules',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Schedules')),
    },
    {
        id: 'modules@reasons-for-hiring',
        type: 'item',
        package_permissions: ["ATTENDANCE_LIST", "ATTENDANCE_LIST.staff_requisitions"],
        componentElm: lazy(async ()  => import('../../../../modules/HiringReasons')),
    },
    {
        id: 'modules@comment-predefined',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/PredefinedComments')),
    },
    {
        id: 'modules@workgroups',
        type: 'item',
        icon: 'workgroups',
        componentElm: lazy(async ()  => import('../../../../modules/Workgroup')),
    },
    {
        id: 'modules@signatures',
        type: 'item',
        icon: 'graphicsignatures',
        componentElm: lazy(async ()  => import('../../../../modules/GraphicSignatures')),
    },
    {
        id: "modules@attendance",
        type: "item",
        icon: "attendance",
        componentElm: lazy(async ()  => import("../../../../modules/Attendance")),
    },
    {
        id: "modules@mopers",
        type: "item",
        icon: 'moperssign',
        componentElm: lazy(async ()  => import("../../../../modules/MOPERSByWorker")),
    },
    {
        id: 'modules@worker-add',
        type: 'item',
        icon: 'worker-add',
        componentElm: lazy(async ()  => import('../../../../modules/Worker/Add')),
    },
    {
        id: 'modules@kardex',
        type: 'item',
        icon: 'worker-kardex',
        componentElm: lazy(async ()  => import('../../../../modules/Worker/Kardex')),
    },
    {
        id: 'modules@structure-enterprise',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/StructureEnterprise')),
    },
    {
        id: 'modules@employer-registration',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: lazy(async ()  => import('../../../../modules/PatronalRegistration')),
    },
    {
        id: 'modules@patronal-registration-settings',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.employer_registration"],
        componentElm: lazy(async ()  => import('../../../../modules/PatronalRegistrationSettings')),
    },
    {
        id: 'modules@branch',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.branch"],
        componentElm: lazy(async ()  => import('../../../../modules/Branch')),
    },
    {
        id: 'modules@sub-branch',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.sub_branch"],
        componentElm: lazy(async ()  => import('../../../../modules/SubBranch')),
    },
    {
        id: 'modules@area',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.area"],
        componentElm: lazy(async ()  => import('../../../../modules/Area')),
    },
    {
        id: 'modules@sub-area',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.sub_area"],
        componentElm: lazy(async ()  => import('../../../../modules/SubArea')),
    },
    {
        id: 'modules@department',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.department"],
        componentElm: lazy(async ()  => import('../../../../modules/Depto')),
    },
    {
        id: 'modules@sub-department',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.sub_department"],
        componentElm: lazy(async ()  => import('../../../../modules/SubDepto')),
    },
    {
        id: 'modules@job-position',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.job_position"],
        componentElm: lazy(async ()  => import('../../../../modules/JobPosition')),
    },
    {
        id: 'modules@jobs-management',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.management"],
        componentElm: lazy(async ()  => import('../../../../modules/Management')),
    },
    {
        id: 'modules@job-grouping',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.group"],
        componentElm: lazy(async ()  => import('../../../../modules/GroupingPosition')),
    },
    {
        id: 'modules@identifier-position',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.position_identifier"],
        componentElm: lazy(async ()  => import('../../../../modules/IdentifierPosition')),
    },
    {
        id: 'modules@jobs-hierarchical-level',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.hierarchical_level"],
        componentElm: lazy(async ()  => import('../../../../modules/HierarchicalLevel')),
    },
    {
        id: 'modules@position-professional-salary',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/ProfessionalMinimum')),
    },
    {
        id: 'modules@gender',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Gender')),
    },
    {
        id: 'modules@marital-status',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/CivilStatus')),
    },
    {
        id: 'modules@job-changes',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/JobChanges')),
    },
    {
        id: 'modules@salary-adjustment',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/SalaryAdjustment')),
    },
    {
        id: 'modules@reason-leaving',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/ReasonLeaving')),
    },
    {
        id: 'modules@validities',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.extra_validities"],
        componentElm: lazy(async ()  => import('../../../../modules/Validity')),
    },
    {
        id: 'modules@requirements',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.extra_requirements"],
        componentElm: lazy(async ()  => import('../../../../modules/Requirements')),
    },
    {
        id: 'modules@extra-fields',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.extra_fields"],
        componentElm: lazy(async ()  => import('../../../../modules/ExtraData')),
    },
    {
        id: 'modules@bans',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'abrhil@degree-documents',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/DegreeDocuments')),
    },
    {
        id: 'modules@education',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/EducationRH')),
    },
    {
        id: 'modules@manage-corporate',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'modules@integration-factor-type',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/IntegratingFactorType')),
    },
    {
        id: 'modules@integration-factor-history',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/IntegrationFactorHistory')),
    },
    {
        id: "modules@staff-requisition",
        type: "item",
        package_permissions: ["ATTENDANCE_LIST", "ATTENDANCE_LIST.staff_requisitions"],
        componentElm: lazy(async ()  => import('../../../../modules/PersonalRequisition')),
    },
    {
        id: "modules@type-of-job-requisition",
        type: "item",
        package_permissions: ["ATTENDANCE_LIST", "ATTENDANCE_LIST.staff_requisitions"],
        componentElm: lazy(async ()  => import('../../../../modules/TypeJobRequisition')),
    },
    // IMSS //
    {
        id: 'modules@imss-catalogs',
        title: 'catalogs',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@imss-settings',
        title: 'settings',
        type: 'item',
        inConstruction: true,
        componentElm: true,
    },
    {
        id: 'modules@affiliate-report',
        type: 'item',
        icon: 'idsetransfer',
        componentElm: lazy(async ()  => import('../../../../modules/AffiliateReport')),
    },
    {
        id: 'modules@confirmation',
        type: 'item',
        icon: 'idseconfirmation',
        componentElm: lazy(async ()  => import('../../../../modules/IDSEConfirmation')),
    },
    {
        id: 'modules@idse-audit',
        type: 'item',
        icon: 'idseaudit',
        componentElm: lazy(async ()  => import('../../../../modules/IDSEAudit')),
    },
    {
        id: 'modules@worker-movement-report',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/WorkerMovementsReport')),
    },
    {
        id: 'modules@transfer',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/SUAtransfer')),
    },
    {
        id: 'modules@liquidation',
        type: 'item',
        icon: 'settlement',
        componentElm: lazy(async ()  => import('../../../../modules/Liquidation')),
    },
    {
        id: 'modules@monthly-settlement-comparison',
        type: 'item',
        icon: 'compare',
        componentElm: lazy(async ()  => import('../../../../modules/MSettlementComparasion')),
    },
    {
        id: 'modules@bimonthly-settlement-comparison',
        type: 'item',
        icon: 'compare',
        componentElm: lazy(async ()  => import('../../../../modules/BSettlementComparasion')),
    },
    {
        id: 'modules@EMA-EBA',
        type: 'item',
        icon: 'emaeba',
        componentElm: lazy(async ()  => import('../../../../modules/EMA-EBA')),
    },
    {
        id: 'modules@variables',
        type: 'item',
        package_permissions: ["payroll_package"],
        icon: 'bimonthlyvariables',
        componentElm: lazy(async ()  => import('../../../../modules/BimonthlyVariables')),
    },
    {
        id: 'modules@work-risk-history',
        type: 'item',
        icon: 'workriskhistory',
        componentElm: lazy(async ()  => import('../../../../modules/WorkRiskHistory')),
    },
    {
        id: 'modules@periods',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/PayrollPeriods')),
    },
    {
        id: 'modules@box',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'modules@cash',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'modules@billing-companies',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'modules@concepts',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/PayrollConcepts')),
    },
    {
        id: 'modules@concepts-accounts',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/PayrollConceptsAccounts')),
    },
    {
        id: 'modules@alimony',
        type: 'item',
        icon: 'alimony',
        componentElm: lazy(async ()  => import('../../../../modules/Alimony')),
    },
    {
        id: "modules@calculate",
        type: "item",
        icon: 'payroll-kardex',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import("../../../../modules/PayrollCalculate")),
    },
    {
        id: "modules@payroll-dispersion",
        type: "item",
        icon: 'payroll-dispersion',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/PayrollDispersion')),
    },
    {
        id: "modules@payroll-policy",
        type: "item",
        icon: 'Payroll-Policy',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/PayrollPolicy')),
    },
    {
        id: "modules@provision-policy",
        type: "item",
        icon: 'Provision-Policy',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/PayrollProvisionPolicy')),
    },
    {
        id: "modules@voucher-dispersion",
        type: "item",
        icon: 'voucher-dispersion',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/VoucherDispersion')),
    },
    {
        id: "modules@request-voucher-accounts",
        type: "item",
        icon: 'voucher-dispersion',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/RequestVoucherAccounts')),
    },
    {
        id: "modules@import-voucher-accounts",
        type: "item",
        icon: 'voucher-dispersion',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/ImportVoucherAccounts')),
    },
    {
        id: "modules@scheduled-concepts",
        type: "item",
        package_permissions: ["payroll_package"],
        icon: 'scheduled-concepts',
        componentElm: lazy(async ()  => import('../../../../modules/ScheduledConcepts')),
    },
    {
        id: "modules@loans-and-paids",
        type: "item",
        icon: 'loans-and-paids',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/LoansAndPaids')),
    },
    {
        id: 'modules@validation_c_p',
        package_permissions: ["payroll_package"],
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/SATValidation')),
    },
    {
        id: 'modules@periodicity-abr-sat',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/PeriodicityAbrSAT')),
    },
    {
        id: 'modules@contract-abr-sat',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/ContractAbrSAT')),
    },
    {
        id: 'modules@config-report-stamp',
        type: 'item',
        inConstruction: true,
        componentElm: true,
        //componentElm: <ConfigReportStamp />
    },
    {
        id: 'modules@concept-abr-sat',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/ConceptAbrSAT')),
    },
    {
        id: 'modules@timbrasoft_app',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/Timbrasoft')),
    },
    {
        id: 'modules@stamp-notifications',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/StampNotification')),
    },
    {
        id: 'modules@config-stamp-receipt',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/ConfigStampReceipt')),
    },
    {
        id: 'modules@receipt_vault',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true,
        //componentElm: <Mod />
    },
    {
        id: 'modules@confronts',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true,
        //componentElm: <Mod />
    },
    //* MIRRORS
    {
        id: 'modules@rfc',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/RFC')),
    },
    {
        id: 'modules@concept-sat-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/TimbrasoftApp/StampConcept/StampConceptContainer')),
    },
    {
        id: 'modules@child-support-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/CatAlimony')),
    },
    {
        id: 'modules@zipcode-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/PostalCode')),
    },
    {
        id: 'modules@uma-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/UMA')),
    },
    {
        id: 'modules@umi-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/UMI')),
    },
    {
        id: 'modules@daily-minimun-salary-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/DailyMinimum')),
    },
    {
        id: 'modules@professions-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Professions')),
    },
    {
        id: 'modules@payment-percentage-history-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/HistoryCyV')),
    },
    {
        id: 'modules@federal-entities-taxes-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/FederalEntitiesTaxes')),
    },
    {
        id: 'modules@profesional-minimun-salary-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/ProfessionalMinimum')),
    },
    {
        id: 'modules@nationalities-mirror',
        type: 'item',
        inConstruction: true,
        componentElm: true
    },
    {
        id: 'modules@coworker-type-mirror',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.worker_type"],
        componentElm: lazy(async ()  => import('../../../../modules/WorkerType')),
    },
    {
        id: 'modules@job-position-type-mirror',
        type: 'item',
        package_permissions: ["human_resource_package", "human_resource_package.job_position_type"],
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/JobPositionType')),
    },
    {
        id: 'modules@absenteeism-reasons-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/AbrhilApp/AbsenteeismReasons')),
    },
    {
        id: 'modules@quota-imss-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/QuotaIMSS')),
    },
    {
        id: 'modules@imss-reason-leaving-mirror',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/ImssReasonLeaving')),
    },
    {
        id: 'modules@tax-tables-mirror',
        type: 'item',
        package_permissions: ["payroll_package"],
        componentElm: lazy(async ()  => import('../../../../modules/Taxes')),
    },
    {
        id: 'modules@thematic-areas-mirror',
        type: 'item',
        package_permissions: ["training", "training.thematic_area"],
        componentElm: lazy(async ()  => import('../../../../modules/ThematicAreas')),
    },
    {
        id: 'modules@courses-classifications-mirror',
        type: 'item',
        package_permissions: ["training"],
        componentElm: lazy(async ()  => import('../../../../modules/CoursesClassifications')),
    },
    {
        id: 'modules@occupations-workers-mirror',
        package_permissions: ["training", "training.national_catalogs_of_occupations"],
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/OccupationsWorkers')),
    },
    {
        id: 'modules@training-objectives-mirror',
        type: 'item',
        package_permissions: ["training", "training.training_goals"],
        componentElm: lazy(async ()  => import('../../../../modules/TrainingObjectives')),
    },
    {
        id: 'modules@training-modalities-mirror',
        type: 'item',
        package_permissions: ["training", "training.modality"],
        componentElm: lazy(async ()  => import('../../../../modules/TrainingModalities')),
    },
    {
        id: 'modules@agents-types-mirror',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/AgentsType')),
    },
    {
        id: 'modules@status-mirror',
        type: 'item',
        package_permissions: ["training"],
        componentElm: lazy(async ()  => import('../../../../modules/StatusTraining')),
    },
    {
        id: 'modules@intitution-types-mirror',
        type: 'item',
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/InstitutionTypes')),
    },
    {
        id: "modules@productive-area-mirror",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/ProductiveArea')),
    },
    {
        id: "modules@degree-documents-training-mirror",
        type: "item",
        package_permissions: ["training", "training.stps"],
        componentElm: lazy(async ()  => import('../../../../modules/DegreeDocuments')),
    },

    //* SECCION DE REPORTES *//

    // REPORTS DE CAPACITACION
    {
        id: "modules@modules-training-reports",
        type: "item",
        componentElm: lazy(async ()  => import('../../../../modules/Reports')),
        extraProps: { tag: "culture" }
    },
    // REPORTES DE RH
    {
        id: "modules@modules-human-resources-reports-group",
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Reports')),
        extraProps: { tag: "culture" }
    },
    // REPORTES DE ASISTENCIA Y MOPERS
    {
        id: "modules@modules-attendance-list-reports-group",
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Reports')),
        extraProps: { tag: "mopers" }
    },
    // REPORTES TIMBRASOFT
    {
        id: 'modules@timbrasoft-reports',
        type: 'item',
        package_permissions: ["payroll_package"],
        inConstruction: true,
        componentElm: true
        //componentElm: lazy(async ()  => import('../../../../modules/Reports')),
        //extraProps: { tag:"payroll" }
    },
    // REPORTES DE NOMINA
    {
        id: 'modules@modules-payroll-reports',
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Reports')),
        extraProps: { tag: "payroll" }
    },
    // REPORTES IMSS
    {
        id: "modules@imss-report-group",
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Reports')),
        extraProps: { tag: "imss" }
    },
    // REPORTES BITACORA
    {
        id: "modules@system-reports-group",
        type: 'item',
        componentElm: lazy(async ()  => import('../../../../modules/Reports')),
        extraProps: { tag: "audit" }
    },
];

export default moduleItems;