import i18next from "i18next";
import * as yup from "yup";
import { stringValidation } from "../../common/validations/stringValidation";
import { monthLabel } from '../../../App/components/Dates/utilities';
const { t } = i18next;

export const SUAVersions = [
  // { id: 0, name: t("SUA") },
  // { id: 1, name: t("SUA 3.5.2") },
  // { id: 2, name: t("SUA 3.5.3 / 3.5.4 / 3.5.6") },
  { id: 3, name: t("SUA 3.5.7") },
];

export const bimesters = [
  { label: `${monthLabel(1)} - ${monthLabel(2)}`, value: "1,2", send: 1 },
  { label: `${monthLabel(3)} - ${monthLabel(4)}`, value: "3,4", send: 2 },
  { label: `${monthLabel(5)} - ${monthLabel(6)}`, value: "5,6", send: 3 },
  { label: `${monthLabel(7)} - ${monthLabel(8)}`, value: "7,8", send: 4 },
  { label: `${monthLabel(9)} - ${monthLabel(10)}`, value: "9,10", send: 5 },
  { label: `${monthLabel(11)} - ${monthLabel(12)}`, value: "11,12", send: 6 },
];

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

export const bimonthly = bimesters.find(
  (f) =>
    f.value
      .split(",")
      .map((m) => parseInt(m))
      .indexOf(parseInt(currentMonth)) !== -1
);

export const initValues = {
  year: currentYear.toString(),
  bimester: bimonthly?.value,
  sua: 3,
  version: "SUA 3.5.7",
}

export const ValidationSchema = yup.object().shape({
  year: stringValidation({ required: true, min: 4 }),
  bimester: stringValidation({ required: true }),
  sua: stringValidation({ required: true }),
  employer_registration: stringValidation({ required: true, min: 1 })
});
