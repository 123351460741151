import React, { forwardRef, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Zoom from '@mui/material/Zoom';
import { ModalTitle } from './ModalTitle';
import { AllMenuSearchProvider } from '../../../contextsAndControllers/useAllMenuSearch';
import Content from './Content';
import { focusElement, hasValue, valueOrOption } from '../../../../../common/GeneralUtilities';
import { useDispatch } from 'react-redux';
import { setSearch } from '../../../../../../store/actions';
import useAbrhilSideMenu from '../../../contextsAndControllers/useSideMenu';
import { getPredefinedIcon, getTabIcon } from '../../../utilities';
import { each, findIndex, last, size } from 'lodash';
import { conditional } from '../../../../grid/CommandCell';

const Transition = forwardRef(function Transition(props, ref) {
	return <Zoom ref={ref} {...props} />;
});

const Modal = ({
	open,
	setOpen,
}) => {

	const dispatch = useDispatch();
	const { completeMenu } = useAbrhilSideMenu();
	const [menuItems, setMenuItems] = useState([]);

	const onClose = () => {
		dispatch(setSearch(null));
		setOpen(false);
	};

	useEffect(() => {
		if (open) {
			focusElement("all-options-search", null, true);
		}
		//eslint-diable-next-line
	}, [open]);

	useEffect(() => {
		const items = groupItemsByBreads(completeMenu, []);
		setMenuItems(items);
		//eslint-disable-next-line
	}, [completeMenu]);

	return (
		<Dialog
			maxWidth="xl"
			className={"search-modal"}
			open={open}
			onClose={onClose}
			aria-labelledby="abrhil-search-modal-title"
			aria-describedby="abrhil-search-modal-description"
			TransitionComponent={Transition}
		>
			<ModalTitle onClose={onClose} />
			<AllMenuSearchProvider >
				<Content onClose={onClose} menuItems={menuItems} />
			</AllMenuSearchProvider>
		</Dialog>
	);
};
export default Modal;

const customTitle = (groupTitle) => {

	let title = size(groupTitle);
	
	if (title === 1) {
		title = groupTitle[0];
	} else if (title === 2) {
		title = `${groupTitle[1]}|&|(${groupTitle[0]})`;
	} else {
		title = `${last(groupTitle)}|&|(${groupTitle.slice(0, -1).join("/")})`;
	}

	return title;
};

const groupItemsByBreads = (items, current_array = [], parent = {}) => {
	items = valueOrOption(items, []);

	each(items, item => {
		
		if (item?.showInMenu) {
			const item_icon = valueOrOption(item?.icon, item?.icon_name);
			const predefined = getPredefinedIcon(item_icon, item?.key);
			let icon = valueOrOption(predefined, item_icon);
			if (!hasValue(icon)) {
				icon = valueOrOption(icon, getTabIcon(item, parent.title));
			}

			if (hasValue(item?.children) && item?.type !== 'item') {
				groupItemsByBreads(item.children, current_array, item);
			} else {
				
				item.icon = icon;
				let groupTitle = conditional(size(item?.bread) > 1, item?.bread?.slice(0, -1), item?.bread);

				let title = customTitle(groupTitle);
				const existIndex = findIndex(current_array, ["title", title]);

				if (existIndex !== -1) {
					current_array[existIndex]?.children?.push(item);
				} else {
					current_array.push({
						title,
						showInMenu: true,
						type: "group",
						children: [item]
					});
				}
			}
		}
	});
	return current_array;
};