import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";

function Card({ children, ...others }) {

    return (
        <Grid size={12} className="sbw-popup-card" {...others}>
            {children}
        </Grid>
    );
};

export default Card;

Card.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.array,
    ])
};