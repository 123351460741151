import Vacations from "./Vacations";
import Overtime from "./Overtime";
import Absenteeisms from "./Absenteeisms";
// TODO: Implement lazy loading and suspense with skeletons

const PopupsDict = (key) => ({
    vacaciones: Vacations,
    overtime: Overtime,
    ausen_con_goce: Absenteeisms,
    ausen_sin_goce: Absenteeisms,
}[key]);

export default PopupsDict;