import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { size } from 'lodash';
import abrhilLogo from '../../assets/images/abrhil-logo.png';
import timbraLogo from '../../assets/images/timbra-logo.png';
import MenuSkeleton from "./MenuSkeleton";
//import Helpdesk from "./Helpdesk";
import Timbrasoft from "./Timbrasoft";
import Search from './Search';
import Menu from './Menu';
import useAbrhilSideMenu from '../../contextsAndControllers/useSideMenu';
import { getCurrentPath } from '../../utilities';
import StampMenu from '../../../../modules/TimbrasoftApp/StampMenu';

const SideMenu = () => {

	const [fixedElement, setFixedElement] = useState(false);
	const { stamp } = useSelector(state => state.app);

	useEffect(() => {}, [stamp]);
	
	useEffect(() => {
		let current = getCurrentPath();
		setFixedElement(current.includes("packages"));
		//eslint-disable-next-line
	}, []);

	if (fixedElement) {
		return null;
	}
	
	return (
		<div id="sidebar-menu" className={`app-sidebar`} >
			<div className='side-menu-container'>
				{stamp ? <StampMenuBuild /> : <AbrlMenu />}
			</div>
		</div>
	);
};

export default SideMenu;


const AbrlMenu = () => {

	const { completeMenu } = useAbrhilSideMenu();
	const stampMenuOptions = completeMenu.find(element => element.key === 'timbrasoft');
	const stampMenuChilds = stampMenuOptions?.children ?? [];
	let stampMenuPermission = []
	stampMenuChilds.forEach(element => {
		const perms = element?.perms;
		if(perms){
			const accesPerm = perms.find( item => item.has_perm)
			if(accesPerm)stampMenuPermission.push(element);
		}
	});

	return(
		<div className="sidebar-fixed-container">
			<div className='abrhil-logo'>
				<img src={abrhilLogo} alt="abrhil-logo" />
			</div>
			{
				size(completeMenu)
					?
					<>
						<Search />
						<Menu items={completeMenu} />
						{size(stampMenuPermission) ? <Timbrasoft /> : null}
					</>
					: <MenuSkeleton />
			}
		</div>
	)
};

const StampMenuBuild = () => {

	const { completeMenu } = useAbrhilSideMenu();
	const stampMenuOptions = completeMenu.find(element => element.key === 'timbrasoft');
	
	return (
		<div className="sidebar-fixed-container stamp-container-menu-margin">
			<div className="sidebar-fixed-container stamp-container-menu">
				<div className='stamp-logo'>
					<img src={timbraLogo} alt="timbrasoft-logo" />
				</div>
				{
					size(stampMenuOptions?.children)
						?
						<div className="stamp-elements">
							<StampMenu items={stampMenuOptions?.children} />
						</div>
						: <MenuSkeleton />
				}
			</div>
				<Timbrasoft />
		</div>
	)
};
