import PropTypes from "prop-types"
import React from 'react';
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import MUIDialogActions from '@mui/material/DialogActions';
import Button from './Button';
import { isFunction } from 'lodash';
const def = {
	padding: '15px 29px',
	border: 0
};

const StyledDialogActions = styled(MUIDialogActions)(def);

const DialogActions = (props) => {
	return (
		<StyledDialogActions {...props}>
			{props.children}
		</StyledDialogActions>
	);
};

export default DialogActions;

export const BasicDialogActions = ({
	onCancel,
	onAccept,
	loading,
	disabledSubmit,
	submitLabel,
	type = 'button'
}) => {

	const { t } = useTranslation();

	return (
		<DialogActions>
			{isFunction(onCancel) &&
				<Button design="text" type="button" onClick={onCancel} >
					{t('cancel')}
				</Button>
			}
			{(isFunction(onAccept) || type === 'submit') &&
				<Button
					design="contained"
					{...(isFunction(onAccept) ? { onClick: onAccept } : {})}
					onClick={onAccept}
					loading={loading}
					disabled={disabledSubmit}
					type={type}
				>
					{submitLabel ?? t('accept')}
				</Button>
			}
		</DialogActions>
	);
};

BasicDialogActions.propTypes = {
	loading: PropTypes.any,
	onAccept: PropTypes.any,
	onCancel: PropTypes.any,
	type: PropTypes.string,
	disabledSubmit: PropTypes.bool,
	submitLabel: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func,
	]),
}
