import PropTypes from "prop-types";
import React, { useState, useEffect, Fragment } from 'react';
import lang from "i18next";
import { isUndefined, omit } from 'lodash';
import Grid from "@mui/material/Grid2";
import FilterUi from './FilterUi';
import KendoDateRange from '../../../App/components/Dates/KendoDateRange';
import SwitchMui from '../../../App/components/Booleans/SwitchMui';
import { hasValue, valueOrOption } from '../../common/GeneralUtilities';
import { monthLabel } from '../../../App/components/Dates/utilities';

const { t } = lang;

export const getFilterName = (field, prefix = "") => {
	prefix = valueOrOption(prefix, "");
	field = valueOrOption(field, "not_filter");
	let name = `${prefix}${field}`.replace(/[.|]/g, "_");
	return name.replace(/_+/g, "_");
};

export const isDropdown = (element) => {
	return ["status", "statusd", "month", "dropdown", "dropdownnokey",
		"serverdropdown", "multiselect", "multiselecttree"].includes((element || "not_in_dropdowns").toLowerCase());
};

export const focusFirst = () => {
	const timer = setTimeout(() => {
		let filterContainerClass = document.querySelector(".class-first-open");
		if (filterContainerClass) {
			const finded = filterContainerClass.querySelector('input');
			if (finded) {
				finded.focus();
			}
			filterContainerClass.classList.remove("class-first-open");
		}
	}, 100);
	return () => clearTimeout(timer);
};

export const formatProps = (item) => {
	let data = [];
	let extraProps = item[3];
	if (isDropdown(item[2])) {
		data = item[3];
		extraProps = item[4];
	}

	return {
		field: item[0],
		filterType: item[2],
		data: data,
		extraProps: omit(extraProps, ["colProps"]),
		colProps: extraProps?.colProps ?? {}
	};
};

/* clean unsoported props */
export const getExtraProps = (extraProps) => {
	let extras = { ...(extraProps || {}) };
	if (!isUndefined(extras?.validationRules)) {
		delete extras.validationRules;
	}
	return extras;
};

const basicProps = {
	autoComplete: "off",
	size: "medium",
};

export const FilterController = ({
	filterType,
	data,
	field,
	values,
	onChange,
	label,
	extraProps,
	asV2 = false,
}) => {

	const value = hasValue(values[field]) ? values[field] : null;
	const [input, setInput] = useState(value);

	useEffect(() => {
		setInput(value);
		// eslint-disable-next-line
	}, [value]);

	const setValue = (inputValue) => {
		setInput(inputValue);
		onChange(field, inputValue, extraProps?.changeCallback);
	};

	const inputProps = {
		...basicProps,
		name: `advance_filter_${field}`,
		label: label,
		className: hasValue(value) ? "hasValue" : "",
		...getExtraProps(extraProps),
	};

	return <FilterUi
		inputProps={inputProps}
		filterType={filterType}
		data={data}
		field={field}
		value={input}
		setValue={setValue}
		validationRules={extraProps?.validationRules}
		asV2={asV2}
	/>;
};

FilterController.propTypes = {
	asV2: PropTypes.bool,
	data: PropTypes.any,
	extraProps: PropTypes.shape({
		changeCallback: PropTypes.any,
		validationRules: PropTypes.any
	}),
	field: PropTypes.any,
	filterType: PropTypes.any,
	label: PropTypes.any,
	onChange: PropTypes.func,
	values: PropTypes.any
};

export const DateRangeController = ({
	field,
	values,
	onChange,
	extraProps,
	colProps,
}) => {

	const {
		show,
		siwtchLabel
	} = { ...(extraProps?.switchProps || {}) };

	const startV = values[`${field}_start`] || null;
	const endV = values[`${field}_end`] || null;
	const switchV = values[`${field}_switch`] || false;
	const [start, setStart] = useState(startV);
	const [end, setEnd] = useState(endV);
	const [disable, setDisable] = useState(switchV);

	useEffect(() => {
		setStart(startV);
		setEnd(endV);
		setDisable(switchV);
		// eslint-disable-next-line
	}, [startV, endV, switchV]);

	const setValue = ({ value, fieldChanged/* ,values */ }) => {
		value = value || null;
		if (fieldChanged === "start") {
			setStart(value);
			onChange(`${field}_start`, value, extraProps?.changeCallback);
			return;
		}
		setEnd(value);
		onChange(`${field}_end`, value, extraProps?.changeCallback);
	};

	const setSwitch = ({ value }) => {
		setDisable(value);
		onChange(`${field}_switch`, value, extraProps?.changeCallback);
	};

	const inputProps = {
		...basicProps,
		name: `advance_filter_${field}`,
		...getExtraProps(extraProps),
	};

	return <Fragment>
		<KendoDateRange
			{...inputProps}
			showValidationMessages={true}
			startValue={start ? new Date(start.replaceAll("-", "/")) : null}
			endValue={end ? new Date(end.replaceAll("-", "/")) : null}
			startProps={
				{
					...inputProps?.startProps,
					className: hasValue(start) ? "hasValue" : ""
				}
			}
			endProps={
				{
					...inputProps?.endProps,
					className: hasValue(end) ? "hasValue" : ""
				}
			}
			onChange={setValue}
			colProps={colProps}
			disabled={disable}
		/>
		{show && <Grid size={6}>
			<SwitchMui
				disabled={!!start || !!end}
				label={siwtchLabel || ""}
				checked={disable}
				onChange={setSwitch}
			/>
		</Grid>}
	</Fragment>;
};

DateRangeController.propTypes = {
	colProps: PropTypes.any,
	extraProps: PropTypes.shape({
		changeCallback: PropTypes.any,
		switchProps: PropTypes.object
	}),
	field: PropTypes.any,
	onChange: PropTypes.func,
	values: PropTypes.any
};

export const hasInvalids = (id) => {
	const container = document.getElementById(id || "advance-filters-modal-container");
	return !!container?.getElementsByClassName("k-invalid").length;
};

export const statusesArray = () => [
	{ label: t("is-active"), value: true, },
	{ label: t("inactive"), value: false, }
];

export const monthsArray = () => {
	let months = [];
	for (let month = 1; month <= 12; month++) {
		months.push({
			label: monthLabel(month), value: month
		});
	}

	return months;
};

export const validationType = (filterType, field) => {
	switch (filterType) {
		case true:
			return { type: "bolean" };
		case "numeric":
		case "datemonth":
		case "dateyear":
		case "month":
			return { type: "numeric" };
		case "date":
			return { type: filterType };
		case "multiselect":
		case "multiselecttree":
			return { type: "array" };
		case "dropdown":
		case "dropdownnokey":
		case "serverdropdown":
			return { type: field === "status" ? "bolean" : "alphanumeric" };
		default:
			return {
				type: "string",
				valueFormat: ["key"].includes(field)
					? "alphanumericKey"
					: "alphanumeric"
			};
	}
};

export const localOperatorType = (filterType, field, value) => {
	if (["status", "month"].includes(field)) {
		filterType = "dropdown";
	}

	const { type } = validationType(filterType, field);

	if (type === "numeric") {
		value = parseFloat(value);
	}

	const operator = {
		"numeric": "equals",
		"bolean": "equals",
		"string": "contains"
	}[type] ?? "contains";

	return { value, operator };
};