import { hasValue } from './GeneralUtilities';
import { getDomain } from "./domain";
import { cancelLockedTasks, cancelTasks } from "./trigger-notification-socket";
import { currentUserInfo } from './validate-tenant';

export const isAbrhil = (withUser = false) => {
	const isAbrhilUser = currentUserInfo()?.is_superuser;
	const domainAuth = window.location.hostname === getDomain();
	return withUser ? isAbrhilUser : domainAuth;
};

export const authentication = () => {
	return hasValue(localStorage.getItem('access'));
};

const handleRedirect = () => {
	const logoutUrl = currentUserInfo()?.sso_url_logout;
	const isSubdomain = !isAbrhil();
	let redirectUrl = window.location.origin;
	if (isSubdomain && logoutUrl) {
		redirectUrl = logoutUrl;
	}
	window.location.replace(redirectUrl);
}

export const logout = async () => {
	sessionStorage.clear();
	if (localStorage.length > 0) {
		cancelTasks();
		cancelLockedTasks();
		const designType = localStorage.getItem('design_type');
		const theme = localStorage.getItem('theme');
		const user_persist = localStorage.getItem('user_persist');
		let user_role = null;
		if (!isAbrhil()) {
			user_role = localStorage.getItem('roleStorage');
		}
		const tenantSelection = localStorage.getItem('tenant_selection');
		localStorage.clear();
		localStorage.setItem('design_type', designType);
		localStorage.setItem('theme', theme);
		if (user_persist) {
			localStorage.setItem("user_persist", user_persist);
		}
		if (tenantSelection) {
			localStorage.setItem('tenant_selection', tenantSelection);
		}
		if (user_role) {
			localStorage.setItem('roleStorage', user_role);
		}
	}
	// process.env.REACT_APP_REDIRECT_URL
	// Se utiliza el origin del window para redireccionar al login del subdominio correspondiente
	handleRedirect();
};