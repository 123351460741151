import React, { useEffect, useState, useRef, cloneElement } from 'react';
import { useDispatch, useSelector } from "react-redux";
import lang from "i18next";
import { size as _size, orderBy, find } from "lodash";
import { uid } from 'uid';

/* own components */
import { listNoDataRender } from '../Templates/listNoDataRender';
import { payrollTypeApi } from '../../../core/services/payroll';
import { valueOrOption, hasValue } from '../../../core/common/GeneralUtilities';
import { objectValue } from '../../../core/@components/form/utilities';
import { CustomTooltip } from '../Templates/cells';
import { isAbrhil } from '../../../core/common/auth';
import KendoDropdown from './KendoDropDown';
import { loadPayrollTypes } from '../../../store/triggers/payrolls';
import { implementService } from '../../../core/services/implemet-service';
import { resolveError } from '../../../core/common/resolve-error';
import { kendoSizeClasses } from '../sizesUtilities';

const { t } = lang;

const PayrollType = ({
	id,
	value,
	onChange,
	...others
}) => {

	const { size, className } = kendoSizeClasses(others);
	const dispatch = useDispatch();
	const lastTenant = useRef(undefined);
	const data_msg = useRef("");
	const tenant = useSelector(state => state.tenant.current?.id) ?? null;
	const payroll_types = useSelector(state => state.payroll.payroll_types);
	const fieldId = valueOrOption(`payroll_type_${uid()}`, id);
	const [data, setData] = useState([]);

	const initialize = async () => {
		let {
			data: response,
			predetermined,
			message
		} = await getPayrollTypes();
		data_msg.current = message;
		response = valueOrOption(response, []);
		const finded = find(response, ["id", value]);

		if (!_size(response) || (value && !finded?.id)) {
			handleChange({ value: null });
			dispatch(loadPayrollTypes([]));
			return;
		}
		dispatch(loadPayrollTypes(response));

		if (hasValue(value)) {
			return;
		}

		handleChange({ value: predetermined });
	};

	useEffect(() => {
		setData(valueOrOption(payroll_types, []));
	}, [payroll_types]);

	useEffect(() => {
		if (!hasValue(tenant) && isAbrhil()) { dispatch(loadPayrollTypes([])); }
		if (lastTenant.current === tenant) { return; }

		lastTenant.current = tenant;
		initialize();
		//eslint-disable-next-line
	}, [tenant]);

	const handleChange = (e) => {
		if (typeof onChange === "function") {
			let returned = objectValue(e, e.value);
			returned.selected = e.value ? find(data, ["id", e.value]) : null;
			onChange(returned);
			return returned;
		}
		return e;
	};

	return (
		<KendoDropdown
			{...others}
			size={size}
			className={className}
			id={fieldId}
			data={data}
			value={value}
			valueField={"id"}
			textField={"name"}
			format={"key|name|periodicity.name"}
			onChange={handleChange}
			listNoDataRender={element => listNoDataRender(element, data_msg.current)}
			itemRender={payrollTypeItemRender}
			valueRender={payrollTypeItemvalueRender}
		/>
	);
};

export default PayrollType;


export const getPayrollTypes = async () => {
	let results = { data: [], predetermined: null, message: "", error: "" };
	await implementService(
		payrollTypeApi.get({
			tree: true,
			is_active: true,
			include: 'periodicity',
			fields: "id,key,name,description,eventuals_settlements,is_predetermined,periodicity.*"
		}
		),
		response => {
			let predetermined = null;
			if (_size(response)) {
				predetermined = find(response, ["is_predetermined", true]);
			}
			results = {
				data: orderBy(response, ['periodicity.days', 'id'], ['asc', 'asc']),
				predetermined: valueOrOption(predetermined?.id, null),
				message: ""
			};
		},
		error => {
			const msg = error?.response?.data?.detail_code;
			resolveError(error);
			results.error = msg;
			results.message = t(valueOrOption(msg, "payroll-responses:unexpected-error-message"));
		},
		true,
	);

	return results;
};

export const payrollTypeItemRender = (li, item) => {
	item = item?.dataItem ?? item;
	if (!item) { return; }
	return cloneElement(li, li.props, itemTemplate(item));
};

export const payrollTypeItemvalueRender = (li, item) => {
	item = item?.dataItem ?? item;
	const value = item?.id;
	if (!hasValue(value)) {
		return <span className="dd-internal-elem k-input-value-text" />;
	}
	return cloneElement(li, li.props, itemTemplate(item, true));
};

const TooltipContentTemplate = ({ item }) => {

	if (!hasValue(item)) { return ""; }

	const { key, name, periodicity } = valueOrOption(item, {});
	const style = { fontWeight: "bold", paddingRight: "1em" };

	return (
		<table>
			<tbody>
                <tr>
                    <th colSpan={2} className="text-center" style={style}>
                        {""}
                    </th>
                </tr>
				<tr>
					<td style={style}> {t(`key`)} </td>
					<td>{key}</td>
				</tr>
				<tr>
					<td style={style}> {t("name")} </td>
					<td>{name}</td>
				</tr>
				<tr>
					<td style={style}> {t(`periodicity`)} </td>
					<td>{periodicity?.name}</td>
				</tr>
			</tbody>
		</table>
	);
};

const itemTemplate = (item, include_internal_class = false) => {
	const { key, name, periodicity } = valueOrOption(item, {});
	const internal_class = include_internal_class ? "dd-internal-elem" : "";

	return (
		<CustomTooltip
			className={`${internal_class} tooltipWrap`}
			title={<TooltipContentTemplate item={item} />}
		>
			<div className={`${internal_class} periodItem`}>
				<span className={`${internal_class} item-list-label`}>
					<b className={internal_class}>
						{key} - {name}
					</b>
					<span className={internal_class}>
						{periodicity?.name}
					</span>
				</span>
			</div>
		</CustomTooltip>
	);
};