import React from "react";
import FormInput from "../../../../../@components/form/Field";
import PropTypes from "prop-types";
import { CheckAll } from "./CancelMopers";
import { useDialogContext } from "../../../hooks/DialogController";
import { GridWrapper } from "./Tenants";

function ActivateDays() {

  const {
    getValues,
  } = useDialogContext();

  const active_days = getValues('active_days');

  return (
    <GridWrapper>
      <CheckAll name='active_days' />
      {
        active_days?.map((el, index) => (
          <div key={`activate-days-${el.key}-${el.id}`}>
            <CustomItem
              item={el}
              itemIndex={index}
            />
          </div>
        ))
      }
    </GridWrapper>
  );
};

export default ActivateDays;

const CustomItem = ({
  item,
  itemIndex,
}) => {

  const {
    control,
    canWrite,
  } = useDialogContext();

  const valueField = `active_days[${itemIndex}].has_perm`;

  return (
    <div className="cancel-moper-item">
      <FormInput
        fieldInput="switch"
        control={control}
        label={item?.description}
        name={valueField}
        disabled={!canWrite}
      />
    </div>
  );
};

CustomItem.propTypes = {
  item: PropTypes.object,
  itemIndex: PropTypes.number,
};
