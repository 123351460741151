import React, { useEffect, useState, useRef } from "react";
import {
	uniq,
	filter as _Filter,
	includes,
	size,
	findIndex,
	isFunction,
	isArray,
	omit,
} from 'lodash';
import { useTranslation, Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { useForm as useReactForm } from "react-hook-form";
import {
	initialFilter,
	initialPage,
} from "../GridCustomProps";
import { hasValue, isEqualsValues, valueOrOption } from '../../../core/common/GeneralUtilities';
import { showNotificationDialog } from '../../../store/actions';
import { conditional } from '../../../core/@components/grid/CommandCell';

const useController = ({
	data,
	onClose,
	handleSelect,
	selected,
	multi,
	selectBy = "key",
	open,
	init,
	getTableData,
	onOffsetCatch,
	with_permission_user = true,
	includeData,
	setWorkersSelectedData,
	workersSelectedData,
	extraParams,
	persistedSelection,
	disabledSelection,
	selectionString = false,
	maxSelection = undefined,
	selectFist = false,
	hasIndex = null,
	closeOnSelect = true,
	controledInit = true,
	forPayroll = false,
}) => {

	persistedSelection = valueOrOption(persistedSelection, []);
	disabledSelection = valueOrOption(disabledSelection, []);

	const toOmit = conditional(forPayroll, "active", "fake-field");
	const defaultFilter = omit(initialFilter({ with_permission_user, ...extraParams }), [toOmit]);

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const selectedOffset = useRef(0);
	const [page, setPage] = useState(initialPage);
	const [filter, setFilter] = useState(defaultFilter);
	const [selection, setSelection] = useState([]);

	const {
		control,
	} = useReactForm({
		mode: "onChange",
	});

	useEffect(() => {
		if (hasValue(selected) && isArray(selected[0])) {
			open = true;
			selected = selected[0].flat();
		}
		// eslint-disable-next-line
	}, []);

	const valueType = (value) => {
		if (!isArray(value)) {
			return [value];
		}
		return value;
	};

	useEffect(() => {
		setSelection([...persistedSelection, ...valueOrOption(valueType(selected), [])]);
		if (open && controledInit) {
			getTableData(page, filter);
		}
		// eslint-disable-next-line
	}, [selected, controledInit]);

	useEffect(() => {
		if (!init) return;
		getTableData(page, filter);
		// eslint-disable-next-line
	}, [page]);

	useEffect(() => {
		if (!size(data) && page.skip && init) {
			setPage(initialPage);
		}
		// eslint-disable-next-line
	}, [data]);

	const filterChange = (e) => {
		const filters = e.filter;
		setFilter(filters);
		getTableData(page, filters);
	};

	/* Pagination */
	const pageChange = (event) => {
		setPage({
			...page,
			skip: event.page.skip,
			take: event.page.take,
		});
	};

	const parseSelection = (dataItem) => {
		const byValue = hasValue(selectBy);
		const item = byValue ? dataItem[selectBy] : dataItem;
		if (!multi) { return item; }

		if (byValue && (includes(selection, item) || includes(selected, item.toString()))) {
			return uniq(
				_Filter(selection, (value) => {
					return !isEqualsValues(value, item);
				})
			);
		} else if (!byValue && size(_Filter(selection, { id: item.id }))) {
			return uniq(
				_Filter(selection, (value) => {
					return !value.id !== item.id;
				})
			);
		};

		return uniq([...selection, item]);
	};

	const setSelectionIndex = (dataItem) => {
		if (multi) { return; }
		const rowIndex = findIndex(data, ["id", dataItem.id]);
		selectedOffset.current = page.skip + rowIndex;
	};

	const isInRestrictedChanges = (dataItem) => {
		const byValue = hasValue(selectBy);
		const item = byValue ? dataItem[selectBy] : dataItem;

		return persistedSelection.includes(item) || disabledSelection.includes(item);
	};

	const selectRow = ({ dataItem }) => {
		if (isInRestrictedChanges(dataItem)) { return; }

		if (!multi && size(persistedSelection) >= 1) {
			return dispatch(showNotificationDialog({
				maxWidth: "sm",
				title: t("max-selection-title"),
				message: t("max-selection-msg-restricted"),
			}));
		}

		if (includeData) {
			getItemsData(dataItem);
		}

		const newSelections = parseSelection(dataItem);
		if (!validateMaxSelection(newSelections)) { return; }
		setSelectionIndex(dataItem);
		setSelection(newSelections);
	};

	const getItemsData = (dataItem) => {
		const itemInList = workersSelectedData.find(item => item.id === dataItem.id);

		if (!itemInList) {
			setWorkersSelectedData([...workersSelectedData, dataItem]);
		} else {
			const newWorkers = workersSelectedData.filter(item => item.id !== dataItem.id);
			setWorkersSelectedData(newWorkers);
		}
	};

	const addRemoveConstantSelections = (selected_rows) => {
		if (!multi && !isArray(selected_rows)) {
			return selected_rows;
		}
		selected_rows = selected_rows.concat(persistedSelection);
		selected_rows = uniq(selected_rows).sort()
			.filter(item => !disabledSelection.includes(item))
			.filter(item => hasValue(item));

		return selected_rows;
	};

	const finishSelection = (passedSelection = null, isKeyPress = false) => {

		let finalSelection = isArray(passedSelection) || isKeyPress ? passedSelection : selection;
		finalSelection = addRemoveConstantSelections(finalSelection);

		const parsedSelection = selectionString && isArray(finalSelection) ? finalSelection.join(',') : finalSelection;

		if (hasIndex === null) {
			handleSelect(parsedSelection);
		} else {
			handleSelect(parsedSelection, hasIndex);
		}

		if (isFunction(onOffsetCatch) && !multi) {
			onOffsetCatch(selectedOffset.current);
		}

		if (!validateMaxSelection(finalSelection)) { return; }

		if (isFunction(onClose) && closeOnSelect) {
			onClose();
		}
	};

	const validateMaxSelection = (selectionItems) => {
		if (!multi || !hasValue(maxSelection)) { return true; }
		if (size(selectionItems) <= maxSelection) { return true; }

		dispatch(showNotificationDialog({
			maxWidth: "sm",
			title: t("max-selection-title"),
			message: (
				<span className='blue-strongs'>
					<Trans i18nKey={"max-selection-msg"} >
						Only a maximum of <strong>{{ maxSelection }}</strong> elements can be selected at a time.
					</Trans>
				</span>
			),
			description: t("max-selection-description"),
		}));
		return false;
	};

	const selectionLength = () => {
		if (multi) {
			return size(selection);
		}
		return selectBy ? selection : size(Object.keys(selection ?? {}));
	};

	const pageDataMap = () => {
		const byValue = hasValue(selectBy);
		if (!byValue) {
			return data ?? [];
		}
		return (data ?? []).map(item => item[selectBy]);
	};

	const onSelectPage = ({ value }) => {
		const pageData = pageDataMap();
		persistedSelection = valueOrOption(persistedSelection, []);
		disabledSelection = valueOrOption(disabledSelection, []);
		let actualSelections = selection;
		if (value) {
			actualSelections = uniq([...selection, ...pageData]);
			if (includeData) {
				setWorkersSelectedData(workersSelectedData => {
					const isDuplicate = (item) => workersSelectedData.some(existingItem => existingItem.id === item.id);
					const newData = data.filter(item => !isDuplicate(item));
					return [...workersSelectedData, ...newData];
				});
			}
		} else {
			if (includeData) {
				setWorkersSelectedData(prevData => {
					const shouldRemove = (itemToRemove) => {
						return data.some(item => item.id === itemToRemove.id);
					};
					const newData = prevData.filter(item => !shouldRemove(item));

					return newData;
				});
			}
			if (hasValue(selectBy)) {
				actualSelections = _Filter(actualSelections, item => {
					return !pageData.includes(item);
				});
			} else {
				const idsMap = pageData.map(item => item.id);
				actualSelections = _Filter(actualSelections, item => {
					return !idsMap.includes(item.id);
				});
			}
		}

		actualSelections = addRemoveConstantSelections(actualSelections);
		setSelection(actualSelections);
	};

	const refresh = (filters) => getTableData(page, filters);

	const onAdvanceFilter = (newFilters) => {
		setFilter(newFilters);
		setPage(initialPage);
		getTableData(initialPage, newFilters);
	};

	const onDblClick = ({ dataItem, isKeyPress }) => {
		if (multi || size(persistedSelection) >= 1) { return null; }
		setSelectionIndex(dataItem);
		finishSelection(parseSelection(dataItem), isKeyPress);
	};

	useEffect(() => {
		if ((selectFist && hasValue(data)) || size(data) === 1) {
			selectRow({ dataItem: data[0] });
		}
	}, [data]);

	return {
		page,
		filter,
		selection,
		refresh,
		filterChange,
		pageChange,
		selectRow,
		finishSelection,
		selectionLength: selectionLength(),
		onAdvanceFilter,
		onDblClick,
		onSelectPage,
		control,
		persistedSelection,
		disabledSelection,
		setFilter
	};
};

export default useController;

export const getBusinessLabel = (businessLabel = false, multi = false, short = false) => {
	let label = !short ? "employer-registration" : "employer-registration-short";

	if (businessLabel) {
		label = "business-name";
	}

	if (multi) {
		label = businessLabel ? "business-names" : "employers-registrations";
	}

	return label;
};

export const getTitles = (serverApi, multi, { customLabel } = {}, businessLabel = false) => {
	if (hasValue(customLabel)) {
		return customLabel;
	}


	const conditionalLabel = (value1, value2) => {
		return multi ? value1 : value2;
	};

	const labels = {
		branch: conditionalLabel("branches", "branch"),
		"sub-branch": conditionalLabel("sub-branches", "sub-branch"),
		sub_branch: conditionalLabel("sub-branches", "sub-branch"),
		area: conditionalLabel("areas", "area"),
		"sub-area": conditionalLabel("areas", "area"),
		sub_area: conditionalLabel("areas", "area"),
		department: conditionalLabel("departments", "department"),
		"sub-department": conditionalLabel("sub-departments", "sub-department"),
		sub_department: conditionalLabel("sub-departments", "sub-department"),
		"job-position": conditionalLabel("job-positions", "job-position"),
		job_position: conditionalLabel("job-positions", "job-position"),
		"employer-registration": getBusinessLabel(businessLabel, multi),
		employer_registration: getBusinessLabel(businessLabel, multi),
		worker: conditionalLabel("workers", "worker"),
		concept: conditionalLabel("concepts", "concept"),
		course: conditionalLabel("course", "courses"),
		instructor: conditionalLabel("instructor", "instructors")
	};
	return labels[serverApi] ?? "";
};