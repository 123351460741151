import React from 'react';
import Box from '@mui/material/Box';
import Masonry from '@mui/lab/Masonry';
import { useTranslation } from "react-i18next";
import { hasValue, valueOrOption } from '../../../../../common/GeneralUtilities';
import { concidence } from '../../../utilities';
import GridNoRecordsRender from '../../../../../../App/components/Templates/GridNoRecordsRender';
import useMegaMenu from '../../../contextsAndControllers/useMegaMenu';
import { GroupElements } from './GroupElements';
import { LinkElement } from './LinkElement';
import { RootElement } from './RootElement';
import { AnimatePresence, motion } from 'framer-motion';
import { opacityAnimation } from '../../../../form/Permission/Utils';

const Menu = () => {

	const { t } = useTranslation();
	const {
		tab,
		search,
		elements,
		subMenu,
		isSubmenu,
		groupsAtFirstLevel,
	} = useMegaMenu();

	let items = [];
	if (isSubmenu) {
		items = subMenu?.children?.find(item => item.name === tab);
		items = items?.children;
	} else if (groupsAtFirstLevel) {
		items = elements?.find(item => item.name === tab);
		items = items?.children;
	} else {
		items = elements;
	}

	return (
		<>
			<AnimatePresence mode='wait'>
				<Box
					key={`childrens-list-items-${tab}`}
					className={"childrens-list-items"}
					component={motion.div}
					{...opacityAnimation}
					transition={{ duration: 0.3 }}
				>
					<Masonry columns={2} spacing={2}>
						{valueOrOption(items, []).map((item, key) => {
							if (item.type === "collapse") {
								if (isSubmenu || hasValue(tab)) {
									return <GroupElements key={key} item={item} />;
								}
								return (
									<RootElement key={key} item={item} />
								);
							}
							return (hasValue(search) && !concidence(item.title, search)) ? null : (
								<div key={key} className='module-item'>
									<LinkElement item={item} />
								</div>
							);
						}).filter(item => item !== null)}
					</Masonry>
				</Box>
			</AnimatePresence>
			<div className="no-items">
				<GridNoRecordsRender
					init={hasValue(search)}
					initialMsg={t("no-items-to-show")}
				/>
			</div>
		</>
	);
};

export default Menu;